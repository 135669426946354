import { Injectable } from "@angular/core";
import { ApiConstant } from "app/shared/common/api.constants";
import { DataService } from "app/shared/services/data.service";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class TypeAttributeService {
  constructor(
    private dataService: DataService,
  ) { }

  getTypeProject() {
    return this.dataService.get(ApiConstant.GetTypeProject)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getTypeContract() {
    return this.dataService.get(ApiConstant.GetTypeContract)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getTypeReportPidPackage() {
    return this.dataService.get(ApiConstant.GetTypeReportPidPackage)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getAreaProject() {
    return this.dataService.get(ApiConstant.GetAreaProject)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getStatusProject() {
    return this.dataService.get(ApiConstant.GetStatusProcess)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getStatusPidPackageAndContract() {
    return this.dataService.get(ApiConstant.GetStatusPidPackageContract)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getTypePidPackage() {
    return this.dataService.get(ApiConstant.GetTypePidPackage)
      .pipe(map((res: any) => {
        return res;
      }));
  }


}
