import { environment } from 'environments/environment';

export enum TokenEnum {
  ACCESS_TOKEN = 'accessToken',
  REFRESH_TOKEN = 'refreshToken',
}

export enum KeyDataUser {
  ROLE_MAX = 'roleMax',
  DEPARTMENT_TYPE = 'departmentType',
  DEPARTMENT_ID = 'departmentId',
  SELECTED_DEPARTMENT = 'selectedDepartment',
  ACCESS_KEY = 'access_key',
  USERID = 'userId',
  FULLNAME = 'fullName',
  AVATAR = 'avatar'
}

export enum TypeAttactmentFolder {
  THUMUC_FILE_DINHKEM_HOPDONG = 1,           //Thư mục file đính kèm của Hợp đồng
  THUTUC_PHAPLI = 2,                  //Thủ tục pháp lý của dự án
  HOSO_THIETKE = 3,                   //Hồ sơ thiết kế của dự án
  MATBANG = 4,                        // Tài liệu mặt bằng của dự án
  CHUTRUONG_CHIDAO = 5,                // Chủ trương chỉ đạo của dự án
  THUMUC_FILE_DINHKEM_DUAN = 6,           //Thư mục file đính kèm của Dự án
  THUMUC_FILE_DINHKEM_PHIEUDENGHI = 7,     //Thư mục file đính kèm của phiếu đề nghị
  THUMUC_FILE_DINHKEM_GOITHAU = 8,        //Thư mục file đính kèm của gói thầu
  DANH_SACH_CONGVIEC_VIEC_GOI_THAU = 9,    //Danh sách công việc của gói thầu
  HOSO_DUAN = 10                        //Hồ sơ dự án
}

export enum AccessKey {
  USER = "QLND",
  CONTRACT = "QLCNHD",
  MONITORING_LIST = "GSHD",
  MONITORING_CONTRACT = "GSHD",
  MONITORING_USER = "GSHDDCT",
  CATEGORY_CONTRACT = "TCKLHD",
  CATEGORY_PLAN = "KHTCHD",
  SUPERVISION_CONTRACT = "PQGSHD",
  PID_PACKAGE = "QLCNGT",
  LIST_PROJECT = "QLDA",
  POSITION_KEY = "QLCV",
  OWNER_KEY = "QLDVCQ",
  SUPERVISING_KEY = "QLDVGS",
  TYPEATT = "QLLH",
  CONTRACTOR_KEY = "QLNT",
  DEPARTMENT_KEY = "QLPB",
  PACKAGE_KEY = "QLGT",
  CONTRACT_KEY = "QLHD",
  ROLE_KEY = "QLQ",
  FUNCTION_KEY = "QLCN",
  PAYMENT = "QLTT",
  PAYMENT_ITEM = "CASHITEM",
  PAYAD = "PAYAD",
  PAYPODER = "PAYPODER",
  PAYSET = "PAYSET",
  APPOVE_CASH = "APPROVECASH",
  APPOVE_CASH_MAIN = "APPROVECASHMAIN",
  REPORT_LIST = "REPORT_LIST",
  REPORT_PAYMENT_ONE_PROJECT = "REPORT_PAYMENT_ONE_PROJECT",
  REPORT_PAYMENT_MULTI_PROJECT = "REPORT_PAYMENT_MULTI_PROJECT",
  REPORT_PAYMENT_ONE_CONTRACTOR = "REPORT_PAYMENT_ONE_CONTRACTOR",
  REPORT_PAYMENT_MULTI_CONTRACTOR = "REPORT_PAYMENT_MULTI_CONTRACTOR",
  REPORT_PAYMENT_ONE_CONTRACT = "REPORT_PAYMENT_ONE_CONTRACT",
  REPORT_PAYMENT_MULTI_CONTRACT = "REPORT_PAYMENT_MULTI_CONTRACT",
  STORAGE = "STORAGE",
  STORAGE_ACCEPTANCE_TREE = "NTHA",
  ARCHIVALRECORD = "ARCHIVALRECORD",
  THUTUC_PHAPLI = "THUTUC_PHAPLI",
  HOSO_THIETKE = "HOSO_THIETKE",
  MATBANG = "MATBANG",
  CHUTRUONG_CHIDAO = "CHUTRUONG_CHIDAO",
  THUMUC_FILE_DINHKEM_DUAN = "THUMUC_FILE_DINHKEM_DUAN",
  TASK = "TASK",
  ACCEPT_PROCESS_DIFFICULTY = "ACCEPT_PROCESS_DIFFICULTY",
  HOSO_DUAN = "HOSO_DUAN"
}

export enum TypeContract {
  MAIN = 1,
  CHILD = 2,
  SUB_CONTRACTOR = 3,
  PRINCIPLE = 4,
  PRINCIPLE_DETAIL = 5
}

export enum ConversationType {
  TASK_PLAN_PROJECT = 1,
  KKVM = 2,
  PAYMENT = 3,
  FOLDER_CMT = 4
}

export class Pattern {
  public static readonly ONLY_TEXT = "^[aàảãáạăằẳẵắặâầẩẫấậbcdđeèẻẽéẹêềểễếệfghiìỉĩíịjklmnoòỏõóọôồổỗốộơờởỡớợpqrstuùủũúụưừửữứựvwxyỳỷỹýỵz AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬBCDĐEÈẺẼÉẸÊỀỂỄẾỆFGHIÌỈĨÍỊJKLMNOÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢPQRSTUÙỦŨÚỤƯỪỬỮỨỰVWXYỲỶỸÝỴZ]+";
  public static readonly KEY_PROJECT = "^[-_.aàảãáạăằẳẵắặâầẩẫấậbcdđeèẻẽéẹêềểễếệfghiìỉĩíịjklmnoòỏõóọôồổỗốộơờởỡớợpqrstuùủũúụưừửữứựvwxyỳỷỹýỵz AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬBCDĐEÈẺẼÉẸÊỀỂỄẾỆFGHIÌỈĨÍỊJKLMNOÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢPQRSTUÙỦŨÚỤƯỪỬỮỨỰVWXYỲỶỸÝỴZ0123456789]+";
  public static readonly KEY_slash_Contract = "^[-_./aàảãáạăằẳẵắặâầẩẫấậbcdđeèẻẽéẹêềểễếệfghiìỉĩíịjklmnoòỏõóọôồổỗốộơờởỡớợpqrstuùủũúụưừửữứựvwxyỳỷỹýỵz AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬBCDĐEÈẺẼÉẸÊỀỂỄẾỆFGHIÌỈĨÍỊJKLMNOÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢPQRSTUÙỦŨÚỤƯỪỬỮỨỰVWXYỲỶỸÝỴZ0123456789]+";
  public static readonly PHONE = /^(0|84)([-. ]?[0-9]{1}){9,10}[-. ]?$/;
  public static readonly EMAIL = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
}

export class StorageOption {
  public static Cookie = 'Cookie';
  public static LocalStorage = 'LocalStorage';
}

export class Constant {
  public static SessionId = 'SessionId';
  public static AppResource = {
    //#region Common
    ACTION_SUCCESS: 'Success!',
    NOT_FIND_DATA: 'No data! Please retry again.',
    ADD_SUCCESS: 'Create successfully!',
    UPDATE_SUCCESS: 'Update successfully!',
    EXCEPTION_UNKNOWN: 'Unknown exception!',
    NOT_FOUND: 'Not found in System!',
    UNAUTHORIZE: 'Unthorize! No right.',
    WARNING_UPDATING: 'Warning! This function is updating.',
    //#endregion
    //#region Product
    //#endregion
  };

  public static DateFormat = 'DD/MM/YYYY';
}

export class AppStatusCode {
  public static StatusCode200 = 200;
  public static StatusCode201 = 201;
  public static StatusCode400 = 400;
  public static StatusCode401 = 401;
  public static StatusCode422 = 422;
  public static StatusCode500 = 500;
}

export class ACTION_TYPE {
  public static WARNING_MONITORING_CONTRACT = "WARNING_MONITORING_CONTRACT";
  public static MONITORING_CONTRACT = "MONITORING_CONTRACT";
  public static CONTRACT = "CONTRACT";
  public static PROJECT = "PROJECT";
  public static MONITORING_USER = "MONITORING_USER";
  public static BIDPACKAGE = "BIDPACKAGE";
  public static CASH = "CASH";
  public static CATEGORY_CONTRACT = "CATEGORY_CONTRACT";
  public static CATEGORY_PLAN = "CATEGORY_PLAN";
}

export const AppDomain = environment.apiUrl;
export const domainApi = environment.apiUrl.concat('/api/');
export const domailFileContract = environment.apiUrl.concat('/uploads/files/export/contract');
export const domainImage = environment.apiUrl.concat('/uploads/Images/');
export const domainThumb = environment.apiUrl.concat('/uploads/thumbs/');
export const domainFileUpload = environment.apiUrl.concat('/uploads/files/');
export const domainFileTemplate = environment.apiUrl.concat('/templates/import/');
export const domainHinhAnhVideoThiCong = environment.apiUrl.concat('/uploads/multimedia/');
export const domainHinhAnhVideoThiCongOld = environment.apiUrl.concat('/uploads/files');
export const domainLinkPlanOld = environment.apiUrl.concat('/uploads/files/plan/');


// <---------------------------------------------------------QLDA------------------------------------------------------------------------->
export const ListTypeContract = [
  {
    Id: 4,
    Name: 'Hợp đồng nguyên tắc'
  },
  {
    Id: 5,
    Name: 'Hợp đồng chi tiết'
  },
  {
    Id: 1,
    Name: 'Hợp đồng nhà thầu chính - thầu phụ'
  },
  {
    Id: 2,
    Name: 'Phụ lục hợp đồng'
  }
]

export const paymentReportTypeContract = [
  {
    Id: 4,
    Name: 'Hợp đồng nguyên tắc'
  },
  {
    Id: 1,
    Name: 'Hợp đồng nhà thầu chính'
  },
  {
    Id: 5,
    Name: 'Hợp đồng chi tiết'
  },
  {
    Id: 3,
    Name: 'Hợp đồng nhà thầu phụ'
  }
]

export const ContractorBusinessType = [
  {
    Id: 1,
    Name: 'Cá nhân'
  },
  {
    Id: 2,
    Name: 'Doanh nghiệp'
  }
]

export const ContractorType = [
  {
    Id: 1,
    Name: 'Nhà thầu tư vấn'
  },
  {
    Id: 2,
    Name: 'Nhà thầu thi công'
  }
]

export const typeDepartment = [
  {
    Id: 1,
    Name: 'Ban Nghiệp vụ'
  },
  {
    Id: 2,
    Name: 'Ban liên quan'
  }
]

export const TypeContractChild = [
  {
    Id: 1,
    Name: 'Phụ lục thay thế'       // Thay đổi giá trị HĐ (khối lượng, đơn giá) hạ tầng or Thay thế giá trị cao tầng
  },
  {
    Id: 2,
    Name: 'Phụ lục phát sinh tăng, giảm khối lượng công việc'             // Phát sinh công việc ngoài hợp đồng hạ tầng or tăng thêm giá trị của cao tầng
  },
  {
    Id: 3,
    Name: 'Phụ lục thay đổi tiến độ'
  },
  {
    Id: 4,
    Name: 'Phụ lục tổng hợp'
  },
  {
    Id: 5,
    Name: 'Phụ lục thay đổi nội dung hợp đồng + khác'
  }
]

//Trạng thái của Hồ sơ thiết kế trong AttactmentFolder
export const statusProcedure = [
  {
    Id: 1,
    Name: 'Đã triển khai'
  },
  {
    Id: 2,
    Name: 'Đang triển khai'
  },
  {
    Id: 3,
    Name: 'Chưa triển khai'
  }
]

//Trạng thái của hồ sơ mặt bằng
export const trangThaiHoSoMatBang = [
  {
    Id: 1,
    Name: 'Hoàn thành 1 phần'
  },
  {
    Id: 2,
    Name: 'Hoàn thành'
  }
]

//Trạng thái công việc của gói thầu
export const BidpackageJobStatus = [
  {
    Id: 1,
    Name: 'Đang xử lý'
  },
  {
    Id: 2,
    Name: 'Quá hạn'
  },
  {
    Id: 3,
    Name: 'Hoàn thành'
  }
]

export const TypeCashAdvance = [
  {
    Id: 1,
    Name: 'Tạm ứng hợp đồng'
  },
  {
    Id: 2,
    Name: 'Tạm ứng vật liệu'
  }
]

export const TypeAcceptCash = [
  {
    Id: 1,
    Name: 'Chưa duyệt'
  },
  {
    Id: 2,
    Name: 'Đã duyệt'
  },
  {
    Id: 3,
    Name: 'Không duyệt'
  }
]

export const TypePaymentMethod = [
  {
    Id: 1,
    Name: 'Bằng tiền mặt'
  },
  {
    Id: 2,
    Name: 'Chuyển khoản'
  },
  {
    Id: 3,
    Name: 'Khác'
  }
]

export const TypePaymentStatus = [
  {
    Id: 1,
    Name: 'Chưa thanh toán'
  },
  {
    Id: 2,
    Name: 'Đã thanh toán hết'
  },
  {
    Id: 3,
    Name: 'Chưa thanh toán hết'
  },
  {
    Id: 4,
    Name: 'Không thanh toán'
  }
]

export const TypeCash = [
  {
    Id: 1,
    Name: 'Phiếu đề nghị tạm ứng'
  },
  {
    Id: 2,
    Name: 'Phiếu đề nghị thanh toán'
  },
  {
    Id: 3,
    Name: 'Phiếu đề nghị quyết toán'
  }
]

export const ProgressReportType = [
  {
    Id: 1,
    Name: 'Báo cáo tiến độ theo file Mpp'
  },
  {
    Id: 2,
    Name: 'Báo cáo tiến độ theo khối lượng công việc'
  }
]

export const LstTypePlan = [ //kế hoạch thi công
  {
    Id: 1,
    Name: 'Theo tuần'
  },
  {
    Id: 2,
    Name: 'Theo tháng'
  }
]

export const StatusContract = [
  {
    Id: 1,
    Name: 'Chưa triển khai',
  },
  {
    Id: 2,
    Name: 'Đang triển khai',
  },
  {
    Id: 3,
    Name: 'Hoàn thành',
  }
];

export enum TypeMonitoringContract {
  DESIGN_PROFILE = 1,
  CONTRUCTION_DIARY = 2,
  IMAGE_VIDEO = 3,
  DIFFICULTY = 4,
  INVESTOR_OPINION = 5,
  COMPLETED_PROFILE = 6
}

export class ContractStatusCode {
  public static Cancel = 'Huy';
  public static RUNNING = 'SttPBPK2';
  public static WAITING = 'REL';
  public static STOP = 'LKD';
  public static FINISHED = 'SttPBPK5';
}

export enum TypeReportFileImport {
  MPP_FILE = 1,
  EXCEL_FILE = 2
}


export const ListTypeCompleteProfile = [
  {
    Id: 1,
    Name: 'Hồ sơ thiết kế',
  },
  {
    Id: 2,
    Name: 'Hồ sơ pháp lý',
  },
  {
    Id: 3,
    Name: 'Hồ sơ chất lượng - Hồ sơ vật liệu',
  },
  {
    Id: 4,
    Name: 'Hồ sơ vật liệu - Biên bản nghiệm thu',
  },
  {
    Id: 5,
    Name: 'Bản vẽ hoàn công',
  },
  {
    Id: 6,
    Name: 'Nhật ký thi công',
  }
];

export const LstTypeReportContractPerformance = [
  {
    Id: 1,
    Name: 'Chi tiết tiến độ thực hiện một hợp đồng',
  },
  {
    Id: 2,
    Name: 'Tổng hợp tiến độ thực hiện một hợp đồng',
  },
  {
    Id: 3,
    Name: 'Tổng hợp các hợp đồng của một dự án',
  },
  {
    Id: 4,
    Name: 'Tiến độ thực hiện các dự án',
  },
  {
    Id: 5,
    Name: 'Tiến độ thực hiện của một nhà thầu',
  },
]

export const ListOptionStatusContract = [
  {
    Id: 1,
    Name: 'Được duyệt',
  },
  {
    Id: 2,
    Name: 'Thực hiện',
  },
  {
    Id: 3,
    Name: 'Nghiệm thu',
  },
  {
    Id: 4,
    Name: 'Thanh toán',
  },
]

export const ListTypeReportContractAnnex = [
  {
    Id: 1,
    Name: 'Chi tiết hợp đồng hoặc phụ lục',
  },
  {
    Id: 2,
    Name: 'Tổng hợp hợp đồng và phụ lục',
  },
  {
    Id: 3,
    Name: 'Chi tiết 1 dự án',
  },
  {
    Id: 4,
    Name: 'Tổng hợp các dự án',
  },
  {
    Id: 5,
    Name: 'Tổng hợp hợp đồng của nhà thầu',
  },
]

export const ContractChannelType = [
  {
    Id: 1,
    Name: 'Chủ đầu tư - Tổng thầu',
  },
  {
    Id: 2,
    Name: 'Tổng thầu - Nhà thầu phụ',
  }
]

export const ganChart = {
  'vi' :  {
    'january': 'Tháng 1', 'february':'Tháng 2', 'march':'Tháng 3', 'april':'Tháng 4', 'maylong':'Tháng 5',
    'june': 'Tháng 6', 'july':'Tháng 7', 'august':'Tháng 8', 'september':'Tháng 9', 'october':'Tháng 10',
    'november':'Tháng 11', 'december':'Tháng 12','jan': 'Tháng 1', 'feb':'Tháng 2', 'mar':'Tháng 3', 'apr':'Tháng 4', 'may':'Tháng 5',
    'jun': 'Tháng 6', 'jul':'Tháng 7', 'aug':'Tháng 8', 'sep':'Tháng 9', 'oct':'Tháng 10',
    'nov':'Tháng 11', 'dec':'Tháng 12', 'sunday':'Chủ nhật', 'monday':'Thứ 2', 'tuesday':'Thứ 3', 'wednesday':'Thứ 4', 'thursday':'Thứ 5', 'friday':'Thứ 6' , 'saturday':'Thứ 7',
    'sun':'Chủ nhật', 'mon':'Thứ 2', 'tue':'Thứ 3', 'wed':'Thứ 4', 'thu':'Thứ 5', 'fri':'Thứ 6' , 'sat':'Thứ 7',
    'format':'Định dạng', 'hour':'Giờ', 'day': 'Ngày', 'week': 'Tuần', 'month': 'Tháng', 'quarter': '1/4', 'hours': 'Giờ', 'days': 'Ngày', 'weeks': 'Tuần', 'months': 'Tháng', 'quarters':'1/4',
    'hr': 'Giờ', 'dy': 'Ngày', 'wk': 'Tuần', 'mth': 'Tháng', 'qtr': '1/4', 'hrs':'Giờ', 'dys':'Ngày', 'wks': 'Tuần', 'mths': 'Tháng', 'qtrs':'1/4',
    'resource':'Nguồn', 'duration': 'Khoảng thời gian', 'comp': '% Hoàn thành', 'completion': 'Hoàn thành', 'startdate':'Ngày bắt đầu', 'enddate':'Ngày kết thúc', 'moreinfo':'Xem Thêm', 'notes': 'Nội dung'
  }
}