import { BaseModel } from '../base-model';

export class DbAttactmentFolder extends BaseModel {
  Id: number;
  Name: string;
  TargetId: string;
  TargetType: number;
  Url: string;
  Thumb: string;
  CorrectionDate: any;
  StartDate: any;
  EndDate: any;
  EndDateReal: any;
  CompleteStatus: number;
  GroupDesignFile: number;
  Note: string;
  UnitSupport: string;
  RelatedId: number;
  WarningStart: number;
  WarningEnd: number;
  Checked: boolean;
  Ratio: string;
  IsSap: boolean;
  attactments: Array<DbAttactmentItem>;
}

export class DbAttactmentItem extends BaseModel {
  Id: number;
  Name: string;
  Url: string;
  Thumb: string;
  TargetId: number;
  Type: number;
  TypeFile: number;
  DateOfFile: any;
  GpsLatitude: number;
  GpsLongitude: number;
}
