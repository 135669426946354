<div class="modal-header">
    <h4 class="modal-title">
        <span><i class="fa fa-pencil-circle mr-1" aria-hidden="true"></i>
            Thêm <span class="mark-title">{{ title }}</span> từ file Excel
        </span>
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="[modal.close(), f.resetForm()]">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<form name="form" class="form-custom" (ngSubmit)="f.form.valid && attactmentFolders && attactmentFolders.length > 0 && save()" #f="ngForm" novalidate>
    <div class="modal-body">
        <div class="infoPackage">
            <div class="inDetailPackage">
                <div class="Detail_Packed AddForm_Package">
                    <div class="ColForm_AddPackage formPopupDialog">

                        <div class="areaAdd_Package pl-0 mb-3 mt-3" style="min-height: unset;">
                            <div class="labelinfoPk">
                                <span>File công việc <span class="required">(*)</span></span>
                            </div>
                            <div class="valueInfoPk">
                                <input style="font-size: 12px;" #file (change)="upload(file.files)"  accept=".xls,.xlsx" type="file" id="file" name="file" autocomplete="off" spellcheck="false" placeholder="">
                                <a href="{{ domainFileTemplate + 'ke-hoach-thau.xlsx' }}" class="mark-title ml-5" style="color: blue; text-decoration: underline;">Template mẫu</a>
                                <span *ngIf="f.submitted && !attactmentFolders" class="spanValidate" style="display: block; margin-top: 2px;">
                                    <span>Không có danh sách công việc</span>
                                </span>
                                <span *ngIf="f.submitted && attactmentFolders" class="spanValidate" style="display: block; margin-top: 2px;">
                                    <span *ngIf="attactmentFolders.length == 0">Không có danh sách công việc</span>
                                </span>
                            </div>
                        </div>

                        <div class="areaAdd_Package pl-0 pt-0" style="height: 500px; overflow-y: scroll;">
                            <table class="table table-striped tableListProj tbl-in-dialog">
                                <thead>
                                    <tr>
                                        <th scope="col" class="stt-role">
                                            Stt
                                        </th>
                                        <th>
                                            <span>Tên công việc</span>
                                        </th>
                                        <th class="text-center">Ngày bắt đầu</th>
                                        <th class="text-center">Ngày kết thúc</th>
                                        <th>Đơn vị thực hiện</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of attactmentFolders; let i = index" role="row">
                                        <td style=" text-align: center; font-weight: bold;"
                                            class="stt-role">{{ i + 1 }}
                                        </td>
                                        <td>
                                            {{ item.Name }}
                                        </td>
                                        <td class="text-center">{{ item.StartDate | date: 'dd/MM/yyyy'}}</td>
                                        <td class="text-center">{{ item.EndDate | date: 'dd/MM/yyyy'}}</td>
                                        <td>
                                            {{ item.UnitSupport }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
    <div class="modal-footer">
        <button type="submit" class="btn btn-primary mr-2" [disabled]="submitted"><i
                class="ft-check-square mr-1"></i>Thêm mới</button>
        <button type="button" class="btn btn-secondary" (click)="[modal.close(), f.resetForm()]">Đóng</button>
    </div>
</form>