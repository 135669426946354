import { Injectable } from '@angular/core';
import { AppDomain, Constant } from 'app/shared/common/app.constants';
import * as moment from 'moment';
import { StorageService } from 'app/shared/services/storage.service';
import { CheckRole } from "app/viewModels/base-query";

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  public domainImage = AppDomain;

  constructor(private storageService: StorageService) { }

  ConvertUrl(str) {
    str = str.toLowerCase();
    str = str.replace(/á|à|ả|ã|ạ|â|ấ|ầ|ẩ|ẫ|ậ|ă|ắ|ằ|ẳ|ẵ|ặ"/g, 'a');
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
    str = str.replace(/đ/g, 'd');
    str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, ' ');
    str = str.replace(/[^a-zA-Z0-9 ]/g, '');
    str = str.replace(/ + /g, ' ');
    str = str.trim();
    str = str.replace(/ /g, '-');

    return str;
  }

  CheckAccessKey(Str: string, Code: string) {
    let Arr = [];
    Arr = Str.split('-');
    for (let i = 0; i < Arr.length; i++) {
      const ConvertArr = Arr[i].split(':');
      if (Code == ConvertArr[0]) {
        const check = ConvertArr[1].substr(0, 1);
        if (check == '1') {
          return true;
        } else {
          return false;
        }
      }
    }

    return false;
  }

  CheckAccessKeyRole(code: string) {
    let role = new CheckRole;
    let access_key = this.storageService.get('access_key');
    let arrKey = access_key.split('-').map((key) => {
      let objectConvert = key.split(':');
      let obj = {};
      obj["key"] = objectConvert[0];
      obj["value"] = objectConvert[1];
      return obj;
    });

    let keyIndex = arrKey.find(x => x.key == code);
    if (keyIndex) {
      role.View = keyIndex.value.substr(0, 1) == '1' ? true : false;
      role.Create = keyIndex.value.substr(1, 1) == '1' ? true : false;
      role.Update = keyIndex.value.substr(2, 1) == '1' ? true : false;
      role.Delete = keyIndex.value.substr(3, 1) == '1' ? true : false;
      role.Import = keyIndex.value.substr(4, 1) == '1' ? true : false;
      role.Export = keyIndex.value.substr(5, 1) == '1' ? true : false;
      role.Other = keyIndex.value.substr(6, 1) == '1' ? true : false;
      role.Menu = keyIndex.value.substr(7, 1) == '1' ? true : false;
    }

    return role;
  }

  ConvertDateTime(obj: Date) {
    return obj.getFullYear() + '-' + (obj.getMonth() + 1) + '-' + obj.getDate() + ' ' + obj.getHours() + ':' + obj.getMinutes() + ':' + obj.getSeconds();
  }
  ConvertDateQuery(str: string) {
    const string = str.split('T')[0];
    return string.split('-');
  }

  ConvertHourQuery(str: string) {
    const string = str.split('T')[1];
    return string.split(':');
  }

  // Hàm đọc dữ liệu từ dạng cha con html => json truyền vào id của noda cha
  ConvertHtmlToJson(Arr, CurrentNode, Selector, ParentId, lct) {
    let slt = Selector + ' > ol';
    const ol = CurrentNode.getElementsByTagName('ol');
    if (ol.length > 0) {
      slt = slt + ' > li';
      const li = ol[0].querySelectorAll(slt);
      if (li.length > 0) {
        for (let i = 0; i < li.length; i++) {
          const Id = li[i].getAttribute('data-id');
          const Name = li[i].getAttribute('data-name');
          const PrtId = ParentId;

          Arr.push({ CategoryId: Id, Name: Name, CategoryParentId: PrtId, Location: lct });
          lct++;
          this.ConvertHtmlToJson(Arr, li[i], slt, Id, lct);
        }
      }
    }
  }

  ConvertHtmlToJson2(Arr, CurrentNode, Selector, ParentId, lct) {
    const result = [];
    let slt = Selector + ' > ol';
    const ol = CurrentNode.getElementsByTagName('ol');
    if (ol.length > 0) {
      slt = slt + ' > li';
      const li = ol[0].querySelectorAll(slt);
      if (li.length > 0) {
        for (let i = 0; i < li.length; i++) {
          const Id = li[i].getAttribute('data-id');
          const Name = li[i].getAttribute('data-name');
          const PrtId = ParentId;

          lct++;
          result.push({ id: Id, Name: Name, CategoryParentId: PrtId, Location: lct, children: this.ConvertHtmlToJson2(Arr, li[i], slt, Id, lct) });
          Arr.push({ CategoryId: Id, Name: Name, CategoryParentId: PrtId, Location: lct });

        }
      }
    }

    return result;
  }

  ValidationDate(date: string) {

    return moment(date, 'DD/MM/YYYY', true).isValid();
  }

  ValidationDate2(date: string) {

    return moment(date, 'YYYY-MM-DD', true).isValid();
  }

  MomentDate(date: string) {
    if (moment(date, 'YYYY-MM-DD', true).isValid()) {
      return moment(date, 'YYYY-MM-DD', true);
    } else {
      return new Date();
    }
  }

  AddYearDate(date: string) {
    if (moment(date, 'YYYY-MM-DD', true).isValid()) {
      return moment(date, 'YYYY-MM-DD', true).add(15, 'year');
    } else {
      return new Date();
    }
  }

  AddDynamicYearDate(date: string, year: number) {
    if (moment(date, 'DD/MM/YYYY', true).isValid()) {
      return moment(date, 'DD/MM/YYYY', true).add(year, 'year');
    } else {
      return new Date();
    }
  }

  // <--------------------------------------------------------------QLDA--------------------------------------------------------------------->
  ConvertDateTimeStringToObject(datetime) {
    if (datetime) {
      // let date = new Date(datetime);
      // return { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
      let date = datetime.split("T");
      return date[0];
    }
    else return undefined;
  }

  ConvertDateTimeStringToObjectTime(datetime) {
    if (datetime) {
      let date = new Date(datetime);
      return { hour: date.getHours(), minute: date.getMinutes(), second: date.getSeconds() };
    }
    else return undefined;
  }

  CreateArrYear() {
    let currYear = (new Date).getFullYear();
    let years = [];
    for (let i = currYear; i >= currYear - 50; i--) {
      years.push({ Year: i });
    }

    return years;
  }

  checkMinMaxDate(dateTarget) {
    if (!dateTarget) return undefined;
    let step1 = dateTarget.split("T");
    let convert = step1[0].split("-");
    let obj = {
      year: Number(convert[0]),
      month: Number(convert[1]),
      day: Number(convert[2])
    };

    return obj;
  }

  formatNumber(number) {
    if (number.value == undefined) return '';
    // this puts commas into the number eg 1000 goes to 1,000,
    // i pulled this from stack overflow, i have no idea how it works
    return Math.floor(number.value)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
  }

  roundConstructionVolume(number, index: number) {
    if (number.value == undefined) return '';
    return (Math.round(number.value * index) / index).toString();
  }

  formatNumberWithSuffix(suffix: string, number) {
    return this.formatNumber(number) != '' ? this.roundConstructionVolume(number, 10) + suffix : '';
  }

  formatDateArgrid(date) {
    if (!date.value) return '';
    return moment(date.value).format('DD/MM/yyyy');
  }

  ConvertNumberToRoman(num) {
    let numerals = { M: 1000, CM: 900, D: 500, CD: 400, C: 100, XC: 90, L: 50, XL: 40, X: 10, IX: 9, V: 5, IV: 4, I: 1 };
    let newNumeral = "";

    for (let i in numerals) {
      while (num >= numerals[i]) {
        newNumeral += i;
        num -= numerals[i];
      }
    }

    return newNumeral;
  }

  typeContractArcgrid(typeContract, contractChannel) {
    let type = "";
    switch (typeContract) {
      case 1:
        type = contractChannel == 1 ? "Hợp đồng thầu chính" : "Hợp đồng thầu phụ";
        break;
      case 2:
        type = "Phụ lục hợp đồng";
        break;
      case 4:
        type = "Hợp đồng nguyên tắc";
        break;
      case 5:
        type = "Hợp đồng chi tiết";
        break;
      default:
        break;
    }

    return type;
  }
}
