import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'status-import-file-template-cell-render',
  template: `
    <i class ="fa fa-check-circle text-success icon-size" *ngIf="params.value == 1"></i>
    <i class ="fa fa-exclamation-triangle text-warning icon-warning icon-size" *ngIf="params.value != 1" [title]="params.data?.Error"></i>
    `
})

export class StatusImportFileTemplateCellRender implements ICellRendererAngularComp {
  params?: any;

  agInit(params: ICellRendererParams): void {
    console.log(params);
    this.params = params;
  }

  refresh(params: ICellRendererParams): boolean {
    return true;
  }
}
