<div class="AlliteminfoPk inInfoPackage displayflex">
    <div class="iteminfoPk">
        <div class="valueInfoPk" style="float: left;">
            <ng-select [items]="listPlans" class="select ngSelectPopup ngSelectinput" bindLabel="PlanTitle"
                bindValue="PlanNumber" [multiple]="false"
                [(ngModel)]="planId" (change)="changePlan()"
                [ngModelOptions]="{standalone: true}"
                placeholder="Chọn đợt kế hoạch"
                notFoundText="Không có dữ liệu."
                [loading]="isLoadingPlan">
            </ng-select>
        </div>
        <div class="btnAction_Project" style="display: inline-block; margin-top: 4px; width: auto; margin-left: 5px;" *ngIf="planId">
            <button [disabled]="exporting" type="button" (click)="exportRootFile()" class="btn btn-outline-primary"><i class="ft-download" aria-hidden="true"></i> Xuất file gốc</button>
        </div>
    </div>
    <div class="iteminfoPk" style="float: left;" *ngIf="planId">
        <div class="valueInfoPk" style="float: left;">
            <input style="font-size: 12px; padding-top: 12px;" #file type="file"
                accept="image/*,video/*,.xls,.xlsx,.pdf,.rar" class="select ngSelectPopup ngSelectinput"
                (change)="files = file.files"
                id="file" name="file" autocomplete="off" spellcheck="false" placeholder="">
        </div>
        <div class="btnAction_Project" style="display: inline-block; margin-top: 4px; width: auto; margin-left: 5px;">
            <button [disabled]="importing" type="button" (click)="upload()" class="btn btn-outline-primary"><i class="ft-upload" aria-hidden="true"></i> Nhập file</button>
        </div>
    </div>
    <div class="iteminfoPk" style="float: left;" *ngIf="planId">
        <div class="btnAction_Project" style="display: inline-block; margin-top: 4px; width: auto">
            <button [disabled]="submitted" type="button" (click)="saveReal()" class="btn btn-primary"><i class="ft-save" aria-hidden="true"></i> Lưu kế hoạch</button>
            <!-- <button [disabled]="exporting" type="button" class="btn btn-outline-primary ml-1" (click)="export()">
                <i class="ft-download"></i> Xuất báo cáo
            </button> -->
        </div>
    </div>
    <p *ngIf="type == 2 && rowData && rowData.length && (rowData[0].IdPo || rowData[0].IdPoNegative)" class="w-100 mt-2 text-po">
        Mã PO tra cứu SAP.
      <span *ngIf="rowData[0].IdPo">
        PO khối lượng dương:
        <span class="text-info mr-3">
          {{ rowData[0].IdPo }}
        </span>
      </span>
      <span *ngIf="rowData[0].IdPoNegative">
        PO khối lượng âm:
        <span class="text-info">
          {{ rowData[0].IdPoNegative }}
        </span>
      </span>
    </p>
</div>
<div class="mt-1">
    <ag-grid-angular
        style="width: 100%; height: 500px;"
        class="ag-theme-alpine"
        [rowData]="rowData"
        [defaultColDef]="defaultColDef"
        [columnDefs]="columnDefs"
        [gridOptions]="gridOptions"
        [pinnedBottomRowData]="pinnedBottomRowData"
        [overlayLoadingTemplate]="templateLoading"
        [overlayNoRowsTemplate]="noRowsTemplate">
    </ag-grid-angular>
</div>
<div class="areaAdd_Package pl-0">

</div>
