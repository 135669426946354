import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'delete-cell-ar-grid',
  template: `
    <a href="javascript:void(0)" class="DeleteProIcon" (click)="onClick($event)">
    <i class="fa fa-trash" aria-hidden="true"></i></a>
    `
})

export class DeleteCellRenderer implements ICellRendererAngularComp {
  params?: any;
  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  refresh(params: ICellRendererParams): boolean {
    return true;
  }

  onClick($event: any) {
    if (this.params?.onDeleteRow instanceof Function) {
      this.params?.onDeleteRow(this.params.node.data);
    }
  }
}
