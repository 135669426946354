import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Paging, QueryFilter } from "app/viewModels/base-query";
import { Chat } from "app/viewModels/chat-model";
import { ConversationService } from "app/services/conversation.service";
import { DbConversation } from 'app/viewModels/conversation/db-conversation';
import { domainImage, KeyDataUser, ConversationType } from "app/shared/common/app.constants";
import { query } from '@angular/animations';
import { ToastrService } from 'ngx-toastr';
import { StorageService } from 'app/shared/services/storage.service';
import { UserService } from "app/services/user.service";
import { PhongbanService } from "app/services/phongban.service";
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
    selector: 'app-shared-conversation',
    templateUrl: './conversation.component.html',
    styleUrls: []
})
export class ConversationComponent implements OnInit {
    @ViewChild('addMsg') addMsg: ElementRef;
    @Input() targetId;
    @Input() targetType: number;
    @Input() title;
    @Input() receiveNotiUser = [];
    domainImage = domainImage;
    curUserId = this.storageService.get(KeyDataUser.USERID);

    newMessage;
    submitted;

    chats = [];

    userMappings = [];
    departments = [];

    currentMessage = new BehaviorSubject(null);

    constructor(
        public modal: NgbActiveModal,
        public conversationService: ConversationService,
        private toast: ToastrService,
        private storageService: StorageService,
        private userService: UserService,
        private phongbanService: PhongbanService,
        private readonly angularFireMessaging: AngularFireMessaging
    ) { }

    ngOnInit(): void {
        this.getMessages();
        this.getDepartments();

        this.angularFireMessaging.messages.subscribe(
            (payload) => {
              this.currentMessage.next(payload);
              this.getMessages();
        });
    }

    getMessages() {
        let paging = new Paging();
        paging.page_size = 0;
        paging.query = this.genQuery(this.targetId, this.targetType);
        paging.order_by = "Id Asc";

        this.conversationService.getByPage(paging).subscribe((res) => {
            if (res["meta"]["error_code"] == 200) {
                this.chats = res["data"];
                this.addMsg.nativeElement.focus();
            }
        });
    }

    onAddMessage() {
        if(!this.newMessage || this.newMessage == "") return;

        let data = new DbConversation;
        data.Contents = this.newMessage;
        data.TypeConversation = this.targetType;
        data.FullName = this.storageService.get(KeyDataUser.FULLNAME);
        data.Avatar = this.storageService.get(KeyDataUser.AVATAR) != "null" && this.storageService.get(KeyDataUser.AVATAR) != 'null' ? this.storageService.get(KeyDataUser.AVATAR) : undefined;
        data.receivers = [];

        if(this.receiveNotiUser) {
            this.receiveNotiUser.forEach(receiveNotiUserId => {
                let receiveNotiUser = this.userMappings.find(x => x.Id == receiveNotiUserId);
                if(receiveNotiUser)
                    data.receivers.push(receiveNotiUser);
            });
        }

        switch(this.targetType) {
            case ConversationType.TASK_PLAN_PROJECT:
                data.TargetTaskPlanId = this.targetId;
                break;
            case ConversationType.KKVM:
                data.TargetTaskPlanId = this.targetId;
                break;
            case ConversationType.PAYMENT:
                data.TargetId = this.targetId;
                break;
            case ConversationType.FOLDER_CMT:
                data.TargetId = this.targetId;
                break;
            default: break;
        }
        
        this.submitted = true;
        this.conversationService.create(data).subscribe((res) => {
            this.submitted = false;
            if (res["meta"]["error_code"] == 200) {
                this.getMessages();
                this.newMessage = undefined;
                this.receiveNotiUser = [];
            }
            else this.toast.error(res["meta"]["error_message"]);
        });
    }

    genQuery(targetId, targetType: number) {
        let query = "1=1";
        switch (targetType) {
            case ConversationType.TASK_PLAN_PROJECT:
                query = `TypeConversation=${targetType} AND TargetTaskPlanId.Equals("${this.targetId}")`;
                break;
            case ConversationType.KKVM:
                query = `TypeConversation=${targetType} AND TargetTaskPlanId.Equals("${this.targetId}")`;
                break;
            case ConversationType.PAYMENT:
                query = `TypeConversation=${targetType} AND TargetId=${this.targetId}`;
                break;
            case ConversationType.FOLDER_CMT:
                query = `TypeConversation=${targetType} AND TargetId=${this.targetId}`;
                break;
            default:
                break;
        }

        return query;
    }

    getUserMappings() {
        let paging = new Paging();
        paging.page_size = 0;
        paging.select = "Id,FullName,DepartmentId";
        paging.order_by = "FullName Asc";
        this.userService.getUserJoinProject(paging).subscribe((res) => {
            if (res["meta"]["error_code"] == 200) {
                this.userMappings = res["data"].map((userMapping) => {
                    let department = this.departments.find(x => x.Id == userMapping.DepartmentId);
                    userMapping.FullName = department ? department.Code + " - " + userMapping.FullName : userMapping.FullName;
                    return userMapping;
                });
            }
        });
    }

    getDepartments() {
        let paging = new Paging();
        paging.page_size = 0;
        paging.select = "Id,Name,Code";
        paging.order_by = "Name Asc";
        this.phongbanService.getByPage(paging).subscribe((res) => {
            if (res["meta"]["error_code"] == 200) {
                this.departments = res["data"];
                this.getUserMappings();
            }
        });
    }
}
