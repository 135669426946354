import { Paging } from './../viewModels/base-query';
import { Injectable } from "@angular/core";
import { ApiConstant } from "app/shared/common/api.constants";
import { TypeMonitoringContract } from "app/shared/common/app.constants";
import { DataService } from "app/shared/services/data.service";
import { DbPidPackage } from "app/viewModels/bidpackage/db-pidpackage";
import { FilterPidPackage } from "app/viewModels/bidpackage/filter-pidpackage";
import { throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { DbMonitoringContract } from 'app/viewModels/monitoring-contract/db-monitoring-contract';

@Injectable({
  providedIn: "root",
})
export class MonitoringContractService {
  constructor(private dataService: DataService) {}

  getByPageWithTypeAndContractId(
    parrams: FilterPidPackage,
    type: TypeMonitoringContract,
    contractId: number
  ) {
    let stringQuery = `${!parrams.projetId || "ProjectId=" + parrams.projetId}${
      parrams.contractorId > 0 ? " AND BidderId=" + parrams.contractorId : ""
    }${
      parrams.departmentId > 0
        ? " AND DepartmentCreateId=" + parrams.departmentId
        : ""
    }${
      parrams.processStatus > 0
        ? " AND StatusBidPackageId=" + parrams.processStatus
        : ""
    }${
      parrams.typePidPackageId > 0
        ? " AND TypeBidPackageId=" + parrams.typePidPackageId
        : ""
    }${
      parrams.typeReportPackageId > 0
        ? " AND TypeReportPackageId=" + parrams.typeReportPackageId
        : ""
    }`;

    if (parrams.name) {
      stringQuery += ` AND (Code.Contains("${parrams.name}") OR Name.Contains("${parrams.name}"))`;
    }

    return this.dataService
      .get(
        ApiConstant.GetBidPackageByPage.concat(`?page=`, "" + parrams.page)
          .concat(`&page_size=`, "" + parrams.page_size)
          .concat(`&query=`, decodeURI(stringQuery))
          .concat(`&select=`, parrams.select)
          .concat("&order_by=", parrams.order_by)
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((err) => throwError(err))
      );
  }

  //get danh sách kế hoạch tuần
  getListPlan(contractId: number) {
    return this.dataService.get(ApiConstant.ApiUrlMonitoringHistory + "/listTitlePlan/" + contractId)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  //Get kế hoạch thi công theo đợt
  getVolumeByPlanId(contractId: number, planId: string) {
    return this.dataService.get(ApiConstant.ApiUrlMonitoringHistory + "/getByPlanNLevel/" + contractId + "/" + planId)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  //Lưu khối lượng thực hiện, khối lượng công việc
  saveVolume(contractId: number, type: number, data: any) {
    return this.dataService.put(ApiConstant.ApiUrlMonitoringHistory + "/valueReal/" + contractId + "/" + type, data)
      .pipe(
        map(res => {
          return res;
        }
      ))
  }

  //get danh sách giám sát theo type và hợp đồng
  getMonitoringContractByPageWithTypeAndContractId(contractId: number, type: TypeMonitoringContract, paging: Paging) {
    return this.dataService
      .get(ApiConstant.GetMonitoringContractByPage.concat(`?page=`, "" + paging.page)
          .concat(`&page_size=`, "" + paging.page_size)
          .concat(`&query=`, "" + paging.query)
          .concat(`&dateUpdated=`, "" + (paging.dateStart || ""))
          .concat(`&contractId=`, "" + contractId)
          .concat(`&typeMonitoring=`, "" + type)
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((err) => throwError(err))
      );
  }

  //thêm mới giám sát theo type và hợp đồng
  createMonitoringContractByPageWithTypeAndContractId(reqData: any) {
    return this.dataService
      .post(ApiConstant.CreateMonitoringContract, reqData)
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((err) => throwError(err))
      );
  }

  updateMonitoringContractByPageWithTypeAndContractId(id: string, reqData: any) {
    return this.dataService
      .put(ApiConstant.CreateMonitoringContract.concat(`/updateSavePath/`, id), reqData)
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((err) => throwError(err))
      );
  }

  deleteMonitoringContractByPageWithTypeAndContractId(id: string) {
    return this.dataService
      .delete(ApiConstant.CreateMonitoringContract.concat(`/deleteById/`), id)
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((err) => throwError(err))
      );
  }

  updateStatusDifficulty(id, data: any) {
    return this.dataService
      .put(ApiConstant.ApiMonitoringContract.concat(`/updateStatusDifficulty/`, id), data)
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((err) => throwError(err))
      );
  }
}
