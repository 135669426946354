import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from "app/services/common.service";
import { HttpClient, HttpEventType, HttpRequest, HttpHeaders } from '@angular/common/http';
import { domainImage, domainApi, TokenEnum, domainFileUpload } from "app/shared/common/app.constants";
import { ConfirmComponent } from 'app/shared/components/confirm/confirm.component';
import { DownloadService } from "app/shared/services/download.service";

@Component({
    selector: 'app-attactment',
    templateUrl: './attactment.component.html',
    styleUrls: []
})
export class AttactmentCommonComponent implements OnInit {
    @Input() data: any;
    @Input() isView: boolean;
    @Input() isRequired: boolean;
    @Input() isImgAndVideoUpload: boolean;
    @Output() eventEmitter: EventEmitter<any> = new EventEmitter();
    @ViewChild('file') file: ElementRef;
    domainFileUpload = domainFileUpload;

    // public files = [];
    // public formDataOut = new FormData();
    @Input() files: any;
    progress: number;

    constructor(public modal: NgbActiveModal, private http: HttpClient, private modalService: NgbModal, private downloadService: DownloadService, private commonService: CommonService) { }

    ngOnInit(): void {
    }

    remove(i: number) {
        const dialogConfirm = this.modalService.open(ConfirmComponent, { size: 'md', backdrop: 'static' });
        dialogConfirm.componentInstance.title = "Xác nhận xóa file đính kèm?";
        dialogConfirm.componentInstance.isConfirm.subscribe((isConfirm) => {
            this.data.splice(i, 1);
            dialogConfirm.close();
            this.eventEmitter.emit(this.data);
        });
    }

    upload(files) {
        if (files.length === 0) {
            return;
        }

        const formData = new FormData();

        for (const file of files) {
            formData.append(file.name, file);
            //this.formDataOut.append(file.name, file);
            if (this.isImgAndVideoUpload) {
                if (!this.data) this.data = [];
                this.data.push({
                    Name: file.name,
                    DateOfFile: this.commonService.ConvertDateTime(file.lastModifiedDate)
                });
            }
        }
        //console.log(files);

        if (this.isImgAndVideoUpload) {
            this.files = files;
            //console.log(this.files);

            // const uploadReq = new HttpRequest('POST', domainApi + 'upload/uploadImgAndVideo', formData, {
            //     headers: new HttpHeaders({
            //         'Authorization': 'bearer ' + localStorage.getItem(TokenEnum.ACCESS_TOKEN)
            //     }),
            //     reportProgress: true,
            // });

            // this.http.request(uploadReq).subscribe(event => {
            //     if (event.type === HttpEventType.UploadProgress) {
            //     } else if (event.type === HttpEventType.Response) {
            //         if(!this.data) this.data = [];
            //         let index = 0;
            //         event.body['data'].forEach(file => {
            //             let obj = {};
            //             obj["Name"] =  file.Name;
            //             obj["Url"] =  file.Url;
            //             obj["TypeFile"] =  file.TypeFile;
            //             obj["DateOfFile"] =  this.commonService.ConvertDateTime(files[index].lastModifiedDate);
            //             obj["GpsLatitude"] =  file.GpsLatitude;
            //             obj["GpsLongitude"] =  file.GpsLongitude;

            //             this.data.push(obj);
            //             index++;
            //         });

            //         this.eventEmitter.emit(this.data);
            //     }
            // });
        }
        else {

            const uploadReq = new HttpRequest('POST', domainApi + 'upload/uploadMultiFile', formData, {
                headers: new HttpHeaders({
                    'Authorization': 'bearer ' + localStorage.getItem(TokenEnum.ACCESS_TOKEN)
                }),
                reportProgress: true,
            });

            this.http.request(uploadReq).subscribe(event => {
                if (event.type === HttpEventType.UploadProgress) {
                    this.progress = Math.round(100 * event.loaded / event.total);
                } else if (event.type === HttpEventType.Response) {
                    this.progress = undefined;
                    if(event.body['meta']['error_code'] == 200) {
                        if (!this.data) this.data = [];
                        event.body['data'].forEach(file => {
                            let obj = {};
                            obj["Name"] = file;
                            obj["Url"] = file;
    
                            this.data.push(obj);
                        });
    
                        this.eventEmitter.emit(this.data);
                    }
                   else {
                        alert(event.body['meta']['error_message']);
                   }
                }
            }, err => {
                alert("Upload file không thành công!");
            });
        }
    }

    getValue() {
        return this.data;
    }
    clearValue() {
        this.file.nativeElement.value = "";
    }

    downloadFileViaUrl(url, fileName) {
        this.downloadService.downloadFileViaUrl(url, fileName, domainFileUpload);
    }

    getFiles() {
        // console.log(this.formDataOut);
        // console.log(this.files);
        return this.files;
    }
}
