<nav class="navbar navbar-expand-lg navbar-light header-navbar {{transparentBGClass}}"
  [ngClass]="{'navbar-brand-center': menuPosition === 'Top', 'navbar-static': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Static' , 'navbar-sticky': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Fixed', 'fixed-top': isSmallScreen && menuPosition === 'Top'}">
  <div class="container-fluid navbar-wrapper header_wapper">
    <div class="navbar-header d-flex">
      <div class="navbar-toggle menu-toggle d-xl-none d-block float-left align-items-center justify-content-center"
        data-toggle="collapse" (click)="toggleSidebar()">
        <i class="ft-menu font-medium-3"></i>
      </div>
      <ul class="navbar-nav" (clickOutside)="toggleSearchOpenClass(false)">
        <li class="nav-item p-0" *ngIf="!isHomePage">
          <button type="button" class="btn p-0" (click)="returnPage()">
            <i class="fa fa-angle-left" style="font-size: 30px"></i>
          </button>
        </li>
        <li class="nav-item px-3 title-page">
          <h3 class="title-header-default-layout text-truncate mb-0">{{ titleRoute}}</h3>
        </li>

        <!-- <li class="nav-item nav-search">
          <a class="nav-link nav-link-search" id="navbar-search" href="javascript:" (click)="toggleSearchOpenClass(true)"><i
              class="ft-search font-medium-3"></i></a>
          <div class="search-input {{searchOpenClass}}">
            <div class="search-input-icon">
              <i class="ft-search font-medium-3"></i>
            </div>
            <input [formControl]="control" #search class="input" type="text" placeholder="Explore IOIT..." tabindex="0"
              autofocus data-search="template-search" [appAutocomplete]="autocomplete" (keyup.enter)="onEnter()" (keydown.esc)="onEscEvent()" (keyup)="onSearchKey($event)" />
            <div class="search-input-close" (click)="toggleSearchOpenClass(false)">
              <i class="ft-x font-medium-3"></i>
            </div>

            <app-autocomplete #autocomplete="appAutocomplete">
              <ng-template appAutocompleteContent >
                <ng-container *ngIf="(listItems | filter: control.value) as result">
                  <app-option #searchResults *ngFor="let option of result" [url]="option.url" [value]="option.name" (click)="redirectTo(option.url);" (mouseenter)="removeActiveClass()"  >
                    <div class="d-flex align-items-center justify-content-between w-100">
                      <div class="d-flex align-items-center justify-content-start">
                        <i class="{{option.icon}} mr-2"></i>
                        <span>{{ option.name }}</span>
                      </div>
                    </div>
                  </app-option>
                  <app-option class="no-result" *ngIf="!result.length">No results found.</app-option>
                </ng-container>
              </ng-template>
            </app-autocomplete>
          </div>
        </li> -->
      </ul>
      <div class="navbar-brand-center">
        <div class="navbar-header">
          <ul class="navbar-nav">
            <li class="nav-item">
              <div class="logo"><a class="logo-text" [routerLink]="['/']" *ngIf="config?.layout.menuPosition === 'Top'">
                  <div class="logo-img"><img class="logo-img" alt="Apex logo" [src]="logoUrl"></div><span
                    class="text">APEX</span>
                </a></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="navbar-container">
      <div class="collapse navbar-collapse d-block" id="navbarSupportedContent">
        <ul class="navbar-nav">
          <li class="dropdown nav-item mr-2" placement="bottom-left" display="static" ngbDropdown  >
            <a  class="nav-link dropdown-toggle user-dropdown d-flex align-items-end" id="dropdownBasic-2" role="button"
              ngbDropdownToggle title="Tài khoản">
              <!-- <img class="logo_header" src="assets/img/logos/logo_header.png" alt="logo TNS" height="27" /> -->
              <img class="logo_header logo_nav" src="{{avatar ? AppDomain + '/uploads/Images/' + avatar : 'assets/img/avatar.png'}}" alt="avatar" height="40" width="40"/>
          </a>
            <div class="dropdown-menu text-left dropdown-menu-right m-0 p-2" aria-labelledby="dropdownBasic-2"
              ngbDropdownMenu>
              <!-- <a class="dropdown-item" href="javascript:;">
                <div class="d-flex align-items-center">
                  <i class="ft-message-square mr-2"></i><span>Chat</span>
                </div>
              </a> -->
              <a class="dropdown-item" routerLink="/account/profile" ngbDropdownItem>
                <div class="d-flex align-items-center">
                  <i class="ft-edit mr-2"></i><span>Thông tin tài khoản</span>
                </div>
              </a>
              <a class="dropdown-item" routerLink="/account/change-password" ngbDropdownItem>
                <div class="d-flex align-items-center">
                  <i class="ft-lock mr-2"></i><span>Thay đổi mật khẩu</span>
                </div>
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" routerLink="/auth/login" ngbDropdownItem>
                <div class="d-flex align-items-center" (click)= "logOut()">
                  <i class="ft-power mr-2"></i><span>Đăng xuất</span>
                </div>
              </a>
            </div>
          </li>
          <li class="nav-item mr-2 d-none d-lg-block">
            <a class="nav-link apptogglefullscreen" id="navbar-fullscreen" href="javascript:;" appToggleFullscreen
              (click)="ToggleClass()"><i class=" {{toggleClass}} font-medium-3"></i></a>
          </li>
          <li class="dropdown nav-item" placement="bottom-left" display="static" ngbDropdown >
            <a class="nav-link dropdown-toggle dropdown-notification p-0 mt-2" id="drp-notification" href="javascript:;"
              ngbDropdownToggle><i class="ft-bell font-medium-3"></i>
                <span
                class="notification badge badge-pill badge-danger" 
                [ngClass]="{ 'badge-large': badge >= 100 }">
                {{ badge >= 100 ? '99' : badge}}
                <i *ngIf="badge >= 100" class="ft-plus"></i>
                </span>
              </a>
            <ul ngbDropdownMenu
              class="dropdown-menu dropdown-menu-right p-0 dfdkfd">

              <li class="dropdown-menu-header">
                <div class="dropdown-header d-flex justify-content-between m-0 px-3 py-2 white bg-primary">
                  <div class="w-100 d-flex justify-content-center" style="color: #ffd900">
                    <i class="ft-bell font-medium-3 d-flex align-items-center mr-2"></i>
                    <span class="noti-title">Thông báo</span>
                  </div>
                </div>
              </li>
              <li class="scrollable-container">
                <div
                infinite-scroll
                [infiniteScrollDistance]="scrollDistance"
                [infiniteScrollUpDistance]="scrollUpDistance"
                [infiniteScrollThrottle]="throttle"
                (scrolled)="onScrollDown(2)"
                [scrollWindow]="false"
                style="width: 500px;height: 350px; overflow-y: scroll;" >
                  <div *ngFor="let item of listNotification; let i = index" role="row" ng-class-odd='odd' ng-class-even='even' style="padding: 3px 0px; border-bottom: 1px solid #ccc;">
                    <a [ngStyle]="{'background-color': item.Status == 1 ? '#c8d3d8' : '' }" class="dropdown-item detail-dasboradl" (click)="[getUrlDetailNoti(item),$event.stopPropagation()]">
                      <div class="md-avatar">
                        <img *ngIf="item.user?.Avata == undefined" style="width: 33px; height: 33px; margin-left: 6px;" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTLMXEtafCkq2nYjPZIUEslsXoVXNBzURoDLeblurcyeQWK0_ly8g" />
                        <img *ngIf="item.user?.Avata != undefined" src="{{ domainImage }}{{ item.user?.Avata }}" style="width:33px; height:33px; border-radius: 50%;margin-left: 6px;" />
                      </div>
                      <div class="content-item">
                        <p class="namecontract" style="line-height: 1;"*ngIf="item.Type != 0">{{item.Title}}</p>
                        <a href="javascript:void(0)" *ngIf="item.Type == 0">{{item.Title}}</a>
                        <div class="noidung" [innerHTML]="item.Content"></div>
                        <p style="font-weight:400; font-size: 12px; font-style: italic;margin-bottom: 0.5rem;">{{ item.UserPushName ? (item.UserPushName + ' - ') : '' }}{{item.CreatedAt | date:'dd/MM/yyyy HH:mm'}}
                          <span *ngIf="item.Status == 1" style="cursor: pointer; color: blue; text-decoration: underline; float: right;">Thông báo mới</span>
                        </p>
                      </div>
                    </a>
                  </div>
                  <p *ngIf="isLoadingMore" class="text-warning text-center p-2">Đang tải....</p>
                </div>
              </li>
              <li class="dropdown-menu-footer text-center footer-dasboard">
                <span (click)="deleteNofication(2)" style="font-weight: 500;color: rgb(79, 79, 243); cursor: pointer; text-decoration: underline;">Đánh dấu đã đọc tất cả thông báo</span>
              </li>
            </ul>
          </li>
          <li class="dropdown nav-item" placement="bottom-left" display="static" ngbDropdown >
            <a class="nav-link dropdown-toggle dropdown-notification p-0 mt-2" id="drp-notification" href="javascript:;"
              ngbDropdownToggle><i class="ft-alert-triangle font-medium-3"></i>
                <span
                class="notification badge badge-pill badge-danger" 
                [ngClass]="{ 'badge-large': badge_warning >= 100 }">
                {{ badge_warning >= 100 ? '99' : badge_warning}}
                <i *ngIf="badge_warning >= 100" class="ft-plus"></i>
                </span>
              </a>
                <ul ngbDropdownMenu
                class="dropdown-menu dropdown-menu-right p-0 dfdkfd">
  
                <li class="dropdown-menu-header">
                  <div class="dropdown-header d-flex justify-content-between m-0 px-3 py-2 white bg-primary">
                    <div class="w-100 d-flex justify-content-center" style="color: #ffd900">
                      <i class="ft-alert-triangle font-medium-3 d-flex align-items-center mr-2"></i>
                      <span class="noti-title">Cảnh báo</span>
                    </div>
                  </div>
                </li>
                <li class="scrollable-container">
                  <div
                  infinite-scroll
                  [infiniteScrollDistance]="scrollDistance"
                  [infiniteScrollUpDistance]="scrollUpDistance"
                  [infiniteScrollThrottle]="throttle"
                  (scrolled)="onScrollDown(3)"
                  [scrollWindow]="false"
                  style="width: 500px;height: 350px; overflow-y: scroll;" >
                    <div *ngFor="let item of list_warning_noti; let i = index" role="row" ng-class-odd='odd' ng-class-even='even' style="padding: 3px 0px; border-bottom: 1px solid #ccc;">
                      <a [ngStyle]="{'background-color': item.Status == 1 ? '#c8d3d8' : '' }" class="dropdown-item detail-dasboradl" (click)="[getUrlDetailNoti(item),$event.stopPropagation()]">
                        <div class="md-avatar">
                          <img *ngIf="item.user?.Avata == undefined" style="width: 33px; height: 33px; margin-left: 6px;" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTLMXEtafCkq2nYjPZIUEslsXoVXNBzURoDLeblurcyeQWK0_ly8g" />
                          <img *ngIf="item.user?.Avata != undefined" src="{{ domainImage }}{{ item.user?.Avata }}" style="width:33px; height:33px; border-radius: 50%;margin-left: 6px;" />
                        </div>
                        <div class="content-item">
                          <p class="namecontract" style="line-height: 1;"*ngIf="item.Type != 0">{{item.Title}}</p>
                          <a href="javascript:void(0)" *ngIf="item.Type == 0">{{item.Title}}</a>
                          <div class="noidung" [innerHTML]="item.Content"></div>
                          <p style="font-weight:400; font-size: 12px; font-style: italic;margin-bottom: 0.5rem;">{{ item.UserPushName ? (item.UserPushName + ' - ') : '' }}{{item.CreatedAt | date:'dd/MM/yyyy HH:mm'}}
                            <span *ngIf="item.Status == 1" style="cursor: pointer; color: blue; text-decoration: underline; float: right;">Cảnh báo mới</span>
                          </p>
                        </div>
                      </a>
                    </div>
                    <small *ngIf="isLoadingMoreWarning" class="text-warning text-center p-2">Đang tải....</small>
                  </div>
                </li>
                <li class="dropdown-menu-footer text-center footer-dasboard">
                  <span (click)="deleteNofication(3)" style="font-weight: 500;color: rgb(79, 79, 243); cursor: pointer; text-decoration: underline;">Đánh dấu đã đọc tất cả thông báo</span>
                </li>
              </ul>
          </li>
          <li class="nav-item mr-3" >
            <a href="/" class="nav-link dropdown-toggle user-dropdown d-flex align-items-end" title="Trang chủ">
              <img class="logo_header" src="assets/img/logos/logo_header.png" alt="logo TNS" height="27" />
            </a>
          </li>
          <!-- <li class="nav-item d-none d-lg-block mr-2 mt-1">
            <a class="nav-link notification-sidebar-toggle" (click)="toggleNotificationSidebar();"><i
                class="ft-align-right font-medium-3"></i></a>
          </li> -->
        </ul>
      </div>
    </div>
  </div>





</nav>
