<div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="[modal.close(true), f.resetForm()]">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<form name="form" class="form-custom formPopupAddProj dialogPhongBan" #f="ngForm" novalidate>
    <div class="modal-body">
        <div class="infoPackage">
            <div class="inDetailPackage">
                <div class="Detail_Packed AddForm_Package">
                    <div class="ColForm_AddPackage formPopupDialog">
                        <!-- <agm-map [latitude]="lat" [longitude]="lng">
                            <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
                        </agm-map> -->

                        <agm-map
                            style="height: 400px;"
                            [latitude]="lat"
                            [longitude]="lng"
                            [zoom]="16"
                            >
                            <agm-marker
                                [latitude]="lat"
                                [longitude]="lng"
                            >
                            </agm-marker>
                        </agm-map>
                    </div>
                </div>
            </div>
        </div>

    </div>
</form>