import { AppInterceptor } from './shared/interceptors/app.interceptor';
import { Injector, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface
} from 'ngx-perfect-scrollbar';

import { AppRoutingModule } from './routes/app-routing.module';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';
import { ContentLayoutComponent } from './layouts/content-layout/content-layout.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { WINDOW_PROVIDERS } from './shared/services/window.service';
import { CookieService } from 'ngx-cookie-service';
import { CacheService } from './shared/services/cache.service';
import { DataService } from './shared/services/data.service';
import { StorageService } from './shared/services/storage.service';
import { PreviousRouteService } from 'app/shared/services/previous-route.service';
import { UtilsService } from './shared/services/utils.service';
import { AuthService } from './services/auth.service';
import { FormsModule } from '@angular/forms';
import { LoaderService } from './shared/services/loading.service';
import { NotifyMessageService } from './shared/services/notify-message.service';
import { environment } from 'environments/environment';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { FirestoreService } from './shared/services/firestore.service';
import { AgGridModule } from 'ag-grid-angular';
import { TreeviewModule } from 'ngx-treeview';
import { AgmCoreModule } from '@agm/core';
import { SessionStorageProvider } from './shared/providers/session-store.provider';
import { AuthGuard } from './shared/services/auth.guard';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false
};

@NgModule({
  declarations: [
    AppComponent,
    MainLayoutComponent,
    ContentLayoutComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
    AngularFirestoreModule,
    FormsModule,
    PerfectScrollbarModule,
    SharedModule,
    AgGridModule,
    TreeviewModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyB9uspDoqvt9GUsGYI6brObMEhMUbwc_O8'
    })
  ],
  providers: [
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
    WINDOW_PROVIDERS,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppInterceptor,
      multi: true
    },
    DataService,
    SessionStorageProvider,
    CacheService,
    CookieService,
    StorageService,
    PreviousRouteService,
    CookieService,
    UtilsService,
    AuthService,
    AuthGuard,
    //AuthGuard,
    NgxSpinnerService,
    LoaderService,
    NotifyMessageService,
    FirestoreService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private injector: Injector) {
  }

  // ngDoBootstrap() {
  //   const auth = createCustomElement(LoginComponent, { injector: this.injector });
  //   customElements.define('login', auth);
  // }
}
