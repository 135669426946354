<div class="areaAdd_Package pl-0" style="min-height: unset;">
    <div class="labelinfoPk" style="width: 16.2%;">
        <span>File đính kèm <span *ngIf="isRequired" class="required">(*)</span></span>
    </div>
    <div class="valueInfoPk" style="width: 83.8%;" *ngIf="!isImgAndVideoUpload">
        <input style="font-size: 12px; width: 80%;" #file (change)="upload(file.files)" multiple type="file"
        accept="image/*,video/*,.xls,.xlsx,.pdf,.rar"
        id="file" name="file" autocomplete="off" spellcheck="false" [disabled]="isView" placeholder="">
        <span *ngIf="progress" style="color: blue;"><i class="fa fa-spin fa-circle-o-notch"></i> {{ progress }}%</span>
    </div>
    <div class="valueInfoPk" style="width: 83.8%;" *ngIf="isImgAndVideoUpload">
        <input style="font-size: 12px; width: 80%;" #file (change)="upload(file.files)" multiple type="file"
        accept="image/*,video/*"
        id="file" name="file" autocomplete="off" spellcheck="false" [disabled]="isView" placeholder="">
        <span *ngIf="progress" style="color: blue;"><i class="fa fa-spin fa-circle-o-notch"></i> {{ progress }}%</span>
    </div>
</div>
<div class="areaAdd_Package pl-0" style="min-height: unset;">
    <div class="labelinfoPk">
    </div>
    <div class="valueInfoPk">
        <div class="list-attactment">
            <span *ngFor="let item of data; let i = index" title="{{ item.Name }}">
                <!-- <a href="javascript:void(0)" (click)="downloadFileViaUrl(item.Url, item.Name)">{{ item.Name }}</a> -->
                <a *ngIf="!isImgAndVideoUpload" href="{{ domainFileUpload + item.Url }}" target="_blank">{{ item.Name }}</a>
                <!-- <a *ngIf="!isImgAndVideoUpload">{{ item.Name }}</a> -->
                <a *ngIf="isImgAndVideoUpload">{{ item.Name }}</a>

                <i *ngIf="!isView" class="fa fa-times"(click)="remove(i)"></i>
            </span>
        </div>
    </div>
</div>