<div class="modal-body text-center p-3 modalDelete">
    <div class="TitleConfirm">
        <span>Xác nhận</span>
    </div>
    <div class="boxtext_delete_confirm">
        <div class="title">
            <p>{{item}}</p>
            <span>{{titleItem}}</span>
        </div>
        <p class="mb-2">{{ title }}</p>
        <div class="message">
            <p class="mb-2">{{ message }}</p>
            <span class="mb-2">{{ messageItem }}</span>
        </div>
        <p class="mb-2 p-error">{{messageError}}</p>
    </div>
    <div class="actionDelete_confirm">
        <button type="button" class="btn btn-primary btn-sm mr-1" (click)="confirm()" [disabled]="isDisabled">Đồng ý</button>
        <button type="button" class="btn btn-danger btn-sm" (click)="decline()" >Đóng</button>
    </div>
</div>