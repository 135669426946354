import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { ProjectService } from "app/services/project.service";
import { HttpClient, HttpEventType, HttpRequest, HttpHeaders } from '@angular/common/http';
import { domainImage, domainApi, AccessKey, domainFileUpload } from "app/shared/common/app.constants";
import { ConfirmComponent } from 'app/shared/components/confirm/confirm.component';
import { DownloadService } from "app/shared/services/download.service";
import { Paging, QueryFilter } from "app/viewModels/base-query";

@Component({
    selector: 'ng-autocomplete-customs',
    templateUrl: './ng-autocomplete-customs.component.html',
    styleUrls: []
})
export class NgAutocompleteCustomsComponent implements OnInit {
  @Output() searchEvtEmitter: EventEmitter<any> = new EventEmitter();
  @Output() changeTxtSearchEvtEmitter: EventEmitter<any> = new EventEmitter();
  @Input() type: AccessKey;
  @Input() departmentId: number;
  data = [];
  title;
  isLoading;

  constructor(private projectService: ProjectService, private downloadService: DownloadService,) { }

  ngOnInit(): void {
      this.title = 'Tìm kiếm theo tên dự án...';
  }

  inputChanged(evt) {
      this.changeTxtSearchEvtEmitter.emit(evt);
      this.getDataSearch(evt);
  }

  getDataSearch(txtSearch) {
      if(!txtSearch) {
          this.data = [];
          return;
      }

      const paging = new Paging;
      paging.page_size = 100;
      paging.query = `Code.Contains("${txtSearch}") OR Name.Contains("${txtSearch}")`;
      paging.select = "Id,Code,Name";
      paging.order_by = "Name Asc";
      
      this.isLoading = true;
      switch (this.type) {
          case AccessKey.LIST_PROJECT:
              this.projectService.getByPage(paging, this.departmentId).subscribe((res) => {
                  if (res["meta"]["error_code"] == 200) {
                      this.data = res["data"].map((item) => {
                          item.KeySearch = item.Code + " " + item.Name;
                          return item;
                      });
                      this.isLoading = false;
                  }
              });
              break;
          default:
              this.isLoading = false;
              break;
      }
  }

  selectEvent(evt) {
      this.changeTxtSearchEvtEmitter.emit(evt.Name);
      this.searchEvtEmitter.emit(true);
  }

  customFilter(countries: any[], query: string) {
      return countries.filter(x => x.KeySearch.toLowerCase().includes(query.toLowerCase()));
  }

  clearTxtSearch(evt) {
      this.inputChanged(evt);
      this.searchEvtEmitter.emit(true);
  }
}
