import { Component, Output, EventEmitter, OnDestroy, OnInit, AfterViewInit, ChangeDetectorRef, ViewChild, ElementRef, ViewChildren, QueryList, HostListener } from '@angular/core';
import { LayoutService } from '../../../shared/services/layout.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ConfigService } from '../../../shared/services/config.service';
import { FormControl } from '@angular/forms';
import { LISTITEMS } from '../../../shared/data/template-search';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { CookieProvider } from 'app/shared/providers/cookie.provider';
import { ACTION_TYPE, AppDomain, domainApi, StorageOption, TokenEnum } from 'app/shared/common/app.constants';
import { StorageService } from 'app/shared/services/storage.service';
import { Paging } from 'app/viewModels/base-query';
import { FirestoreService } from 'app/shared/services/firestore.service';
import { HttpClient } from '@angular/common/http';
import { UserChangePass } from 'app/viewModels/user/user-change-pass';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { AuthService } from 'app/services/auth.service';
import { MessagingService } from 'app/shared/services/messaging.service';
import { PreviousRouteService } from 'app/shared/services/previous-route.service';
import { PhongbanService } from 'app/services/phongban.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, AfterViewInit, OnDestroy {
  currentLang = 'en';
  selectedLanguageText = 'English';
  selectedLanguageFlag = './assets/img/flags/us.png';
  toggleClass = 'ft-maximize';
  placement = 'bottom-right';
  logoUrl = 'assets/img/logo.png';
  menuPosition = 'Side';
  isSmallScreen = false;
  protected innerWidth: any;
  searchOpenClass = '';
  transparentBGClass = '';
  hideSidebar = true;
  public isCollapsed = true;
  layoutSub: Subscription;
  configSub: Subscription;
  fullName: string;
  avatar: string;
  status: boolean;
  isTogged: boolean;
  currentMessage = new BehaviorSubject(null);
  badge: number = 0;
  badge_warning: number = 0;
  returnUrl: string;
  titleRoute: string;
  listNotification = [];
  userChangePass: UserChangePass = new UserChangePass();
  AppDomain = AppDomain;
  isHomePage: boolean = false;
  isLoadingMore = false;
  isLoadingMoreWarning = false;
  isLoadMore = true;
  countItemNext = 0;
  countItemNextWarning = 0;
  throttle = 500;
  scrollDistance = 1;
  scrollUpDistance = 2;

  @ViewChild('search') searchElement: ElementRef;
  @ViewChildren('searchResults') searchResults: QueryList<any>;

  @Output()
  toggleHideSidebar = new EventEmitter<Object>();

  @Output()
  seachTextEmpty = new EventEmitter<boolean>();

  listItems = [];
  control = new FormControl();

  public config: any = {};
  public paging: Paging;
  public message: any;

  paging_warning_noti: Paging;
  list_warning_noti = [];


  constructor(private layoutService: LayoutService,
    private router: Router,
    private configService: ConfigService,
    private cdr: ChangeDetectorRef,
    private cookieService: CookieProvider,
    private storeService: StorageService,
    public firestore: FirestoreService,
    private http: HttpClient,
    public titleService: Title,
    private location: Location,
    private modalService: NgbModal,
    private routerActive: ActivatedRoute,
    private readonly angularFireMessaging: AngularFireMessaging,
    private readonly authService: AuthService,
    private phongbanService: PhongbanService,
    private messagingService: MessagingService,
    private previousRouteService: PreviousRouteService,
     ) {
    this.config = this.configService.templateConf;
    this.innerWidth = window.innerWidth;

    this.layoutSub = layoutService.toggleSidebar$.subscribe(
      isShow => {
        this.hideSidebar = !isShow;
      });

    this.paging = new Paging();
    this.paging.page = 1;
    this.paging.page_size = 10;
    this.paging.query = "Type=2";
    this.paging.order_by = "";
    this.paging.item_count = 0;
    this.countItemNext = this.paging.page * this.paging.page_size;

    this.userChangePass.UserId = parseInt(localStorage.getItem('userId'));
    this.userChangePass.UserName = localStorage.getItem('userName');
    this.userChangePass.Avatar = localStorage.getItem('avata');
    this.userChangePass.FullName = localStorage.getItem('fullName');
    this.status = localStorage.getItem('status') === '1' ? true : false;
    this.avatar = localStorage.getItem('avatar') != 'null' ? localStorage.getItem('avatar') : undefined;

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url == '/' || event.url === '/dashboard') {
          this.isHomePage = true;
        } else {
          this.isHomePage = false;
        }
      }
    });

    this.router.events.subscribe(route => {
      if (route instanceof NavigationEnd) {
        this.titleRoute = titleService.getTitle();
        this.layoutService.clear();
      }
    });

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    // setInterval(() => {
    //   this.paging.page = 1;
    //   this.paging.page_size = 100;

    //   this.GetListNotification();
    // }, 2000);

    this.paging_warning_noti = new Paging();
    this.paging_warning_noti.page = 1;
    this.paging_warning_noti.page_size = 20;
    this.paging_warning_noti.query = "Type=3";
    this.paging_warning_noti.order_by = "";
    this.paging_warning_noti.item_count = 0;

    this.countItemNextWarning = this.paging_warning_noti.page * this.paging_warning_noti.page_size;
    this.storeService.initialize(StorageOption.LocalStorage);
  }

  ngOnInit() {
    this.listItems = LISTITEMS;
    //this.layoutService.toggleNotiSidebar$

    if (this.innerWidth < 1200) {
      this.isSmallScreen = true;
    } else {
      this.isSmallScreen = false;
    }

    this.messagingService.requestPermission();
    this.messagingService.receiveMessage();
    this.message = this.messagingService.currentMessage;

    //firebase
    this.GetListNotification();
    this.getListWarningNoti();
    this.angularFireMessaging.messages.subscribe(
      (payload) => {
        this.listNotification = [];
        this.currentMessage.next(payload);
        if (payload["data"] != undefined) {
          this.badge = payload["data"]["badge"];
          this.GetListNotification();
          this.getListWarningNoti();
        }
        else {
          this.GetListNotification();
          this.getListWarningNoti();
        }
      });

    //this.getBadge();
    // this.sharedDataService.selectedDepartment$.subscribe(department => {
    //   this.selectedDepartment = department;
    // });
    
  }

  ngAfterViewInit() {

    this.configSub = this.configService.templateConf$.subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
      }
      this.loadLayout();
      this.cdr.markForCheck();

    })
  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
    if (this.configSub) {
      this.configSub.unsubscribe();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = event.target.innerWidth;
    if (this.innerWidth < 1200) {
      this.isSmallScreen = true;
    } else {
      this.isSmallScreen = false;
    }
  }

  onScrollDown(type) {
    if (this.isLoadMore && this.listNotification.length && type == 2) {
      this.isLoadingMore = true;
      this.paging.page = this.paging.page + 1;

      this.GetListNotification();
    }
    else if (this.isLoadingMoreWarning && this.list_warning_noti.length && type == 3) {
      this.isLoadingMoreWarning = true;
      this.paging_warning_noti.page = this.paging_warning_noti.page + 1;

      this.getListWarningNoti();
    }
  }

  returnPage() {
    let isShow = this.layoutService.getIsShow();

    if (isShow) {
      const modalRef = this.modalService.open(ConfirmDialogComponent, {
        centered: true
      });
      modalRef.componentInstance.titleMessage = 'Bạn chưa hoàn tất thông tin. Bạn muốn rời mà không hoàn tất không?';
      modalRef.componentInstance.isConfirm.subscribe((isConfirm) => {
        if (isConfirm) {
          this.location.back();
          modalRef.dismiss();
        }
      })
    } else {
      this.routerActive.children.forEach(child => {
        child.params.subscribe(params => {
          const idDetail = params['id'];

          if (idDetail && parseInt(idDetail) > 0) {
            this.location.back();
          } else {
            let urlCurrent = this.router.url;
            let urlCurrentSplit = urlCurrent.split("/");
            if (urlCurrentSplit.length >= 4) {
              if(urlCurrentSplit[1] == "pay" && urlCurrentSplit[2] == "detail") {
                this.router.navigate([this.previousRouteService.get()]);
              }
              else
                this.location.back();
            } else {
              this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>{
                this.router.navigate([`/dashboard`]);
              });
            }
          }
        })
      })
    }
  }

  GetListNotification() {
      this.http.get(domainApi + 'action/user/' + this.userChangePass.UserId + '?page=' + this.paging.page + '&page_size=' + this.paging.page_size + '&query=' + this.paging.query + '&order_by=' + this.paging.order_by + '&type_action=2').subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          res["data"].forEach(element => {
            this.listNotification.push(element);
          });
          
          this.badge = res["metadata"] || 0;

          this.paging.item_count = res["data"]?.length > 0 ? res["data"][0].TotalNotification : 0;
          this.isLoadingMore = false;

          const indexNow = this.paging.page * this.paging.page_size;

          if (indexNow >= this.paging.item_count) {
            this.isLoadMore = false;
          } else {
            this.isLoadMore = true;
          }

          if ((indexNow + this.paging.page_size) > this.paging.item_count) {
            this.countItemNext = this.paging.item_count - indexNow;
          } else {
            this.paging.item_count = this.paging.page_size;
          }
        }
      },
      (err) => {
        console.log('Error: connect to API');
      }
    );
  }

  getUrlDetailNoti(item) {
    if(item.ProjectId && item.ProjectId > 0) {
      localStorage.setItem("ProjectIdDetail", item.ProjectId);
    }

    let urlRedirct = '#';
    let parrams;

    if (item.ActionType ==  ACTION_TYPE.PROJECT) {
      urlRedirct =  '/project/' + (item?.TargetAction == "DELETED" ? 'list' : 'detail/' + item.ProjectId);
    } 
    else if (item.ActionType ==  ACTION_TYPE.CONTRACT) {
      urlRedirct =  '/contract/' + (item?.TargetAction == "DELETED" ? 'list/' + item.ProjectId : 'addDetailContract/' + item.ProjectId + '/' + item.TargetId);
    }
    else if (item.ActionType ==  ACTION_TYPE.BIDPACKAGE) {
      urlRedirct =  '/pid-package/' + (item?.TargetAction == "DELETED" ? ('list/' + item.ProjectId) : ('detail/' + item.ProjectId + '?id=' + item.TargetId));

      if (item?.TargetAction != "DELETED") { parrams = {id : item.TargetId} };
    }
    else if (item.ActionType ==  ACTION_TYPE.MONITORING_CONTRACT || item.ActionType ==  ACTION_TYPE.WARNING_MONITORING_CONTRACT) {
      if (item.TargetId && parseInt(item.TargetId) > 0) {
        urlRedirct =  '/monitoring-contract/detail/' + item.ProjectId + '?contractId=' + item.TargetId + '&type=2';
        parrams = {contractId : item.TargetId, type: 2};
      }
      else {
        urlRedirct =  '/monitoring-contract/list/' + item.ProjectId;
      }
    }
    else if (item.ActionType ==  ACTION_TYPE.MONITORING_USER) {
      if (item?.TargetAction == "CREATE") {
        urlRedirct =  '/monitoring-contract/detail/' + item.ProjectId + '?contractId=' + item.TargetId + '&type=2';
        parrams = {contractId : item.TargetId, type: 2};
      } else {
        urlRedirct =  '/contract/decentralization-of-supervision/' + item.ProjectId;
      }
    }
    else if(item.ActionType ==  ACTION_TYPE.CASH) {
      if(item.TargetAction == "DELETED") {
        if(item.Time == 1) {
          urlRedirct =  '/pay/pay-advance';
        }
        else if(item.Time == 2) {
          urlRedirct =  '/pay/pay-order';
        }
        else if(item.Time == 3) {
          urlRedirct =  '/pay/pay-settlement';
        } 
        else {
          urlRedirct =  '/pay/pay-advance';
        }
      }
      else {
        urlRedirct =  '/pay/detail/' + item.TargetId;
      }
    }
    else if (item.ActionType ==  ACTION_TYPE.CATEGORY_CONTRACT) {
      urlRedirct =  '/contract/category-contract/' + item.ProjectId;

      const dataContract = item.LogData ? JSON.parse(item.LogData) : null;

      if (dataContract && dataContract.ContractChannel) {
        parrams = {contractId : item.TargetId, channelId: dataContract.ContractChannel};
      }
    }
    else if (item.ActionType ==  ACTION_TYPE.CATEGORY_PLAN) {
      urlRedirct =  '/plan/category-plan/' + item.ProjectId;

      const dataContract = item.LogData ? JSON.parse(item.LogData) : null;

      if (dataContract && dataContract.ContractChannel) {
        parrams = {contractId : item.TargetId, channelId: dataContract.ContractChannel};
      }
    }
    else {
      urlRedirct = '#';
    }

    this.redirectTo(urlRedirct, parrams);

    this.http.post(domainApi + 'action/readNotificationById/' + item.NotificationId, null).subscribe(
      (res) => {
          if (res['meta']['error_code'] == 200) {
            if (item.Option == 2) {
              let index = this.listNotification.findIndex(x => x.NotificationId == item.NotificationId);
              if (item.Status != 10) {
                this.badge = this.badge > 0 ? (this.badge - 1) : 0;
              }
  
              this.listNotification[index].Status = 10;
            }
            else if (item.Option == 3) {
              let index = this.list_warning_noti.findIndex(x => x.NotificationId == item.NotificationId);
              if (item.Status != 10) {
                this.badge_warning = this.badge_warning > 0 ? (this.badge_warning - 1) : 0;
              }
  
              this.list_warning_noti[index].Status = 10;
            }
          }
      },
      (err) => {
          console.log('Error: connect to API');
      });
  }

  ReadNofication(item) {
    this.http.post(domainApi + 'action/readNotificationById/' + item.NotificationId, {}).subscribe(
      (res) => {
          if (res['meta']['error_code'] == 200) {
            let index = this.listNotification.findIndex(x => x.NotificationId == item.NotificationId);
            this.listNotification[index].Status = 10;
            this.badge = this.badge > 0 ? (this.badge - 1) : 0
          }
      },
      (err) => {
          console.log('Error: connect to API');
      });
}

RouterLink(item) {

  if (item.TargetId != undefined) {
    this.returnUrl = '';
    if (item.TargetType == 'CUSTOMER') {
        this.returnUrl = '/customer?cus=' + item.TargetId;
    } else if (item.TargetType == 'CONTRACT') {
        this.returnUrl = '/contracta?con=' + item.TargetId;
    } else if (item.TargetType == 'EMPLOYEE') {
                  this.returnUrl = '/employee?emp=' + item.TargetId;
    } else {
                  this.returnUrl = '/dashboard';
    }

    this.router.navigateByUrl(this.returnUrl);
  }
}
  getBadge() {
    this.firestore.getBadge(this.userChangePass.UserId).subscribe((res) => {
      this.paging.page = 1;
      this.listNotification = [];
      this.badge = res.payload.data() ? res.payload.data()["countNotification"] : 0;
      //this.GetListNotification();
    },
      (err) => {
        console.log("Error: connect to API:" + err);
      });
  }

  UpdateStatus(item) {
      item.Status = 2;
      this.paging.query += ' AND Type=4';
      this.http.get(domainApi + 'action/user/' + this.userChangePass.UserId + '?page=' + this.paging.page + '&page_size=' + this.paging.page_size + '&query=' + this.paging.query + '&order_by=' + this.paging.order_by).subscribe(
          (res) => {
              if (res['meta']['error_code'] == 200) {
                  this.listNotification = res['data'];
                  // this.badge = res["metadata"];
                  this.paging.item_count = res['metadata'];

              }
          },
          (err) => {
              console.log('Error: connect to API');
          }
      );
  }

  deleteNofication(type) {
    this.http.post(domainApi + 'action/readAllNotification', {
      typeNotifycation : type
    }).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          if (type == 2) {
            this.paging.item_count = 0;
            this.listNotification.map(s => s.Status = 10);
            this.badge = 0;
          }
          else if (type == 3) {
            this.paging_warning_noti.item_count = 0;
            this.list_warning_noti.map(s => s.Status = 10);
            this.badge_warning = 0;
          }
        }
      },
      (err) => {
        console.log("Error: connect to API");
      });
  }

  loadLayout() {

    if (this.config.layout.menuPosition && this.config.layout.menuPosition.toString().trim() != '') {
      this.menuPosition = this.config.layout.menuPosition;
    }

    if (this.config.layout.variant === 'Light') {
      this.logoUrl = 'assets/img/logo-dark.png';
    } else {
      this.logoUrl = 'assets/img/logo.png';
    }

    if (this.config.layout.variant === 'Transparent') {
      this.transparentBGClass = this.config.layout.sidebar.backgroundColor;
    } else {
      this.transparentBGClass = '';
    }

  }

  onSearchKey(event: any) {
    if (this.searchResults && this.searchResults.length > 0) {
      this.searchResults.first.host.nativeElement.classList.add('first-active-item');
    }

    if (event.target.value === '') {
      this.seachTextEmpty.emit(true);
    } else {
      this.seachTextEmpty.emit(false);
    }
  }

  removeActiveClass() {
    if (this.searchResults && this.searchResults.length > 0) {
      this.searchResults.first.host.nativeElement.classList.remove('first-active-item');
    }
  }

  onEscEvent() {
    this.control.setValue('');
    this.searchOpenClass = '';
    this.seachTextEmpty.emit(true);
  }

  onEnter() {
    if (this.searchResults && this.searchResults.length > 0) {
      const url = this.searchResults.first.url;
      if (url && url != '') {
        this.control.setValue('');
        this.searchOpenClass = '';
        this.router.navigate([url]);
        this.seachTextEmpty.emit(true);
      }
    }
  }

  redirectTo(uri, parrams) {
    this.router.navigateByUrl('/').then(() => {
      this.router.navigate([`${uri.split('?')[0]}`], { queryParams: parrams || {}});
    });
    this.seachTextEmpty.emit(true);
  }

  ToggleClass() {
    if (this.toggleClass === 'ft-maximize') {
      this.toggleClass = 'ft-minimize';
    } else {
      this.toggleClass = 'ft-maximize';
    }
  }

  toggleSearchOpenClass(display) {
    this.control.setValue('');
    if (display) {
      this.searchOpenClass = 'open';
      setTimeout(() => {
        this.searchElement.nativeElement.focus();
      }, 0);
    } else {
      this.searchOpenClass = '';
    }
    this.seachTextEmpty.emit(true);



  }
  toggleNotificationSidebar() {
    this.layoutService.toggleNotificationSidebar(true);
  }

  toggleSidebar() {
    this.layoutService.toggleSidebarSmallScreen(this.hideSidebar);
  }

  logOut() {
    //this.cookieService.delete(TokenEnum.ACCESS_TOKEN);
    this.authService.logout();
    this.router.navigate(['/auth/login']);
  }

  getListWarningNoti() {
    this.http.get(domainApi + 'action/user/' + this.userChangePass.UserId + '?page=' + this.paging_warning_noti.page + '&page_size=' + this.paging_warning_noti.page_size + '&query=' + this.paging_warning_noti.query + '&order_by=' + this.paging_warning_noti.order_by  + '&type_action=3').subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          res["data"].forEach(element => {
            this.list_warning_noti.push(element);
          });
          
          this.badge_warning = res["metadata"] || 0;

          this.paging_warning_noti.item_count = res["data"]?.length > 0 ? res["data"][0].TotalNotification : 0;
          this.isLoadingMoreWarning = false;

          const indexNow = this.paging_warning_noti.page * this.paging_warning_noti.page_size;

          if (indexNow >= this.paging_warning_noti.item_count) {
            this.isLoadingMoreWarning = false;
          } else {
            this.isLoadingMoreWarning = true;
          }

          if ((indexNow + this.paging_warning_noti.page_size) > this.paging_warning_noti.item_count) {
            this.countItemNextWarning = this.paging_warning_noti.item_count - indexNow;
          } else {
            this.paging_warning_noti.item_count = this.paging_warning_noti.page_size;
          }
        }
      },
      (err) => {
        console.log('Error: connect to API');
      }
    );
  }
}

