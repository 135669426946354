import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { map } from 'jquery';
import { ReplaySubject, Subject } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  _events = new ReplaySubject<string>();

  constructor(private readonly router: Router) {
    // router.events.pipe(
    //   filter(e => e instanceof NavigationEnd),
    //   map(e => e as NavigationEnd),
    //   map((e: NavigationEnd) => e.urlAfterRedirects),
    //   tap((url) => {
    //     console.log(url);
    //   })
    // ).subscribe();
  }

  private _isShowWarning: boolean = false;
  private titleComponent: string = "";
  private toggleSidebar = new Subject<boolean>(); // small screen
  private overlaySidebarToggle = new Subject<boolean>();
  private toggleNotiSidebar = new Subject<boolean>();

  subject = new Subject();

  // Observable
  toggleSidebar$ = this.toggleSidebar.asObservable();
  overlaySidebarToggle$ = this.overlaySidebarToggle.asObservable();
  toggleNotiSidebar$ = this.toggleNotiSidebar.asObservable();

  toggleSidebarSmallScreen(toggle: boolean) {
    this.toggleSidebar.next(toggle);
  }

  overlaySidebartoggle(toggle: boolean) {
    this.overlaySidebarToggle.next(toggle);
  }

  toggleNotificationSidebar(toggle: boolean) {
    this.toggleNotiSidebar.next(toggle);
  }

  showWarning(isShow: boolean) {
    this._isShowWarning = isShow;

    this.subject.next(this._isShowWarning);
  }

  getIsShow() {
    return this._isShowWarning;
  }

  setTitleComponent(titleComponent) {
    this.titleComponent = titleComponent;
  }

  getTitleComponent() {
    return this.titleComponent;
  }

  clear() {
    this._isShowWarning = false;
    this.titleComponent = undefined;
    this.subject.next(this._isShowWarning);
  }

}
