import { DbProject } from '../viewModels/project/db-project';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiConstant } from 'app/shared/common/api.constants';
import { DataService } from 'app/shared/services/data.service';
import { Paging } from 'app/viewModels/base-query';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  public isEdit = false;
  public subject = new Subject<any>();
  private itemProductSource = new BehaviorSubject(this.isEdit);

  constructor(
    private dataService: DataService,
  ) { }

  getByPage(pageOption: Paging, unitManager?: number) {
    return this.dataService.get(ApiConstant.GetProjectByPage
      .concat(`?page=`, '' + pageOption.page)
      .concat(`&page_size=`, '' +  pageOption.page_size)
      .concat(`&query=`, pageOption.query)
      .concat(`&select=`, pageOption.select)
      .concat('&order_by=', pageOption.order_by)
      .concat(unitManager ? '&unitProjectManagerId=' + unitManager : ''))
      .pipe(map((res: any) => {
        return res;
      }));
  }

  update(id: number, itemProduct: DbProject) {
    return this.dataService.put(ApiConstant.ApiUrlProject.concat('/' + id), itemProduct)
      .pipe(
        map(res => {
          return res;
        }
      ))
  }

  create(itemProduct: DbProject) {
    return this.dataService.post(ApiConstant.ApiUrlProject, itemProduct)
      .pipe(
        map(res => {
          return res;
        }
      ))
  }

  getById(id: number): Observable<any> {
    return this.dataService.get(ApiConstant.ApiUrlProject.concat('/' + id))
    .pipe(
        map(res => {
          return res;
        }
      ))
  }

  delete(id: number): Observable<any> {
    return this.dataService.delete(ApiConstant.ApiUrlProject + '/', id.toString())
    .pipe(
        map(res => {
          return res;
        }
    ))
  }
 
  deletes(data: Array<number>): Observable<any> {
    return this.dataService.post(ApiConstant.ApiUrlProject, data)
    .pipe(
        map(res => {
          return res;
        }
    ))
  }

  getProjectByAccount(select: string): Observable<any> {
    return this.dataService.get(ApiConstant.ApiUrlProject.concat('/GetProjectByAccount' + (select && select != "" ? '?select=' + select : '')))
    .pipe(
        map(res => {
          return res;
        }
      ))
  }

  getFolderProject() {
    return this.dataService.get(ApiConstant.getFolderProject)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getFolderContract(id : number) {
    return this.dataService.get(ApiConstant.getFolderContract+"/"+id)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getProjectMerge() {
    return this.dataService.get(ApiConstant.getProjectMerge)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getContractMerge(id : number) {
    return this.dataService.get(ApiConstant.getContractMerge+"/"+id)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getUserMapping(id: number): Observable<any> {
    return this.dataService.get(ApiConstant.ApiUrlProject.concat('/GetUserMapping/' + id))
    .pipe(
        map(res => {
          return res;
        }
      ))
  }

  updateUserMapping(uid: number, data: any) {
    return this.dataService.put(ApiConstant.ApiUrlProject.concat('/UpdateUserMapping/' + uid), data)
      .pipe(
        map(res => {
          return res;
        }
      ))
  }
  updateUser(userId: number, data: any) {
    return this.dataService.put(ApiConstant.ApiUrlProject.concat('/UpdateUserMappingDepart/' + userId + '/1'), data)
      .pipe(
        map(res => {
          return res;
        }
      ))
  }
  uncheckCell(userId: number, data: any) {
    return this.dataService.put(ApiConstant.ApiUrlProject.concat('/UpdateUserMappingDepart/' + userId + '/0'), data)
      .pipe(
        map(res => {
          return res;
        }
      ))
  }
}
