<div class="ng-autocomplete input-mapping ">
    <ng-autocomplete 
        ngbNavContent 
        [data]="data"
        [searchKeyword]="'Name'"
        [placeholder]="title"
        (selected)='selectEvent($event)'
        (inputChanged)='inputChanged($event)'
        (keyup.enter)='searchEvtEmitter.emit(true)'
        [customFilter]="customFilter"
        (inputCleared)="clearTxtSearch($event)"
        [isLoading]="isLoading">                                 
    </ng-autocomplete>
    
</div>