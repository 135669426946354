import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { HttpClient, HttpEventType, HttpRequest, HttpHeaders } from '@angular/common/http';
import { domainImage, domainApi, TokenEnum, domainFileUpload } from "app/shared/common/app.constants";
import { ConfirmComponent } from 'app/shared/components/confirm/confirm.component';
import { DownloadService } from "app/shared/services/download.service";
import { ColDef, ColGroupDef, ColumnApi, GridApi, GridReadyEvent, GridOptions } from 'ag-grid-community'
import { CustomPinnedRowRenderer } from 'app/shared/components/custom-pined-row/custom-pined-row-renderer.component';
import { CommonService } from 'app/services/common.service';
import { MonitoringContractService } from "app/services/monitoring-contract.service";
import { ICellRendererParams } from "ag-grid-community";
import { NameCategoryMonitoringComponent } from 'app/shared/components/name-category-monitoring/name-category-monitoring.component';
import { ToastrService } from 'ngx-toastr';
import { RequestOptions, ResponseContentType, Headers } from '@angular/http';
import { InterceptorService } from 'ng2-interceptors';
import { StorageService } from 'app/shared/services/storage.service';
import { getFileNameFromResponseContentDisposition, saveFile } from 'app/shared/services/file-download-helper';
import { NumericEditor } from 'app/shared/components/ag-grid/numeric-editor.component';

@Component({
    selector: 'app-construction-volume',
    templateUrl: './construction-volume.component.html',
    styleUrls: []
})

export class ConstructionVolumeComponent implements OnInit {
    @ViewChild('file') file: ElementRef;
    @Input() contractId: number;
    @Input() type: number;

    listPlans = [];
    planId: string;

    templateLoading = `<span class="text-sm text-warning text-center">Đang tải dữ liệu...</span>`;
    noRowsTemplate = `<span class="text-sm text-danger text-center">không có dữ liệu</span>`;
    pinnedBottomRowData;
    exporting;
    importing;
    isLoadingPlan = false;
    submitted;
    files = [];

    gridOptions: GridOptions = {
        getRowStyle: this.changeRowColor,
        onGridSizeChanged: () => {
            // this.gridOptions.api.sizeColumnsToFit();
        }
    };

    changeRowColor(params) {
        if (params.data.IsProject === true) {
            return { 'background-color': '#2f8be6' };
        }

        if (params.data.IsContract === true) {
            return { 'background-color': '#dbdee5' };
        }
    }

    defaultColDef: ColDef = {
        sortable: true,
        resizable: true,
        width: 120,
        cellRendererSelector: (params) => {
            if (params.node.rowPinned) {
                return {
                    component: CustomPinnedRowRenderer,
                    params: {
                        style: { 'font-weight': 'bold' },
                    },
                };
            } else {
                return undefined;
            }
        }
    };

    columnDefs: (ColDef | ColGroupDef)[];

    rowData = [];

    constructor(private http: HttpClient, private downloadService: DownloadService,
        private commonService: CommonService, private monitoringContractService: MonitoringContractService,
        private toastrService: ToastrService, private httpDownload: InterceptorService, private storageService: StorageService) { }

    ngOnInit(): void {
        this.getListPlan();

        this.columnDefs = [
            {
                field: 'STT',
                headerName: 'TT',
                width: 80,
                pinned: 'left',
                cellClass: ['no-focus', 'text-center'],
                headerClass: ['text-center'],
            },
            {
                field: 'Level',
                headerName: 'Level',
                width: 80,
                cellClass: ['no-focus', 'text-center'],
                pinned: 'left',
                headerClass: 'text-center'
            },
            {
                field: 'Name',
                headerName: 'Nhóm hạng mục',
                width: 500,
                pinned: 'left',
                cellRenderer: NameCategoryMonitoringComponent
            },
            {
                field: 'UnitCalculate',
                headerName: 'Đơn vị tính',
                width: 150,
                cellClass: ['no-focus', 'text-center'],
                // pinned: 'left'
            },
            {
                headerName: 'Tiến độ tổng tiến độ phân khai gói thầu',
                headerClass: ['inline-grid', 'text-center'],
                children: [
                    {
                        field: 'DateStartPlan',
                        headerName: 'Bắt đầu',
                        width: 150,
                        cellClass: ['no-focus', 'text-center'],
                        headerClass: 'text-center',
                        valueFormatter: this.commonService.formatDateArgrid
                    },
                    {
                        field: 'DateEndPlan',
                        headerName: 'Kết thúc',
                        width: 150,
                        cellClass: ['no-focus', 'text-center'],
                        headerClass: 'text-center',
                        valueFormatter: this.commonService.formatDateArgrid
                    }
                ]
            },
            {
                headerName: 'Tiến độ theo hợp đồng, phê duyệt, gia hạn',
                headerClass: ['inline-grid', 'text-center'],
                children: [
                    {
                        field: 'DateStartRead',
                        headerName: 'Bắt đầu',
                        width: 150,
                        cellClass: ['no-focus', 'text-center'],
                        headerClass: 'text-center',
                        valueFormatter: this.commonService.formatDateArgrid
                    },
                    {
                        field: 'DateEndRead',
                        headerName: 'Kết thúc',
                        width: 150,
                        cellClass: ['no-focus', 'text-center'],
                        headerClass: 'text-center',
                        valueFormatter: this.commonService.formatDateArgrid
                    }
                ]
            },
            {
                headerName: 'Khối lượng và giá trị',
                headerClass: ['inline-grid', 'text-center'],
                children: [
                    {
                        headerName: 'Hợp đồng',
                        headerClass: ['inline-grid', 'text-center'],
                        children: [
                            {
                                field: 'Value',
                                headerName: 'Khối lượng',
                                width: 150,
                                cellClass: ['no-focus', 'text-center'],
                                headerClass: 'text-center',
                                valueFormatter: params => this.commonService.roundConstructionVolume(params, 100)
                            },
                            {
                                field: 'TotalValue',
                                headerName: 'Giá trị',
                                width: 150,
                                cellClass: ['no-focus', 'text-right'],
                                headerClass: 'text-right',
                                valueFormatter: this.commonService.formatNumber
                            }
                        ]
                    },
                    {
                        headerName: this.type == 1 ? 'Kế hoạch' : 'Thực hiện',
                        headerClass: ['inline-grid', 'text-center'],
                        children: [
                            {
                                field: this.type == 1 ? 'ValuePlan' : 'ValueReal',
                                headerName: 'Khối lượng',
                                width: 150,
                                cellClass: ['no-focus', 'text-center'],
                                headerClass: 'text-center',
                                valueFormatter: params => this.commonService.roundConstructionVolume(params, 100)
                            },
                            {
                                field: this.type == 1 ? 'TotalPlan' : 'TotalReal',
                                headerName: 'Giá trị',
                                width: 150,
                                cellClass: ['no-focus', 'text-right'],
                                headerClass: 'text-right',
                                valueFormatter: this.commonService.formatNumber
                            },
                            {
                                field: this.type == 1 ? 'ValuePlanOld' : 'ValueRealOld',
                                headerName: 'Lũy kế',
                                width: 150,
                                cellClass: ['no-focus', 'text-center'],
                                headerClass: 'text-center',
                                valueFormatter: params => this.commonService.roundConstructionVolume(params, 100)
                            }
                        ]
                    },
                    {
                        headerName: this.type == 1 ? 'Kỳ này' : 'Nghiệm thu',
                        headerClass: ['inline-grid', 'text-center'],
                        children: [
                            {
                                field: this.type == 1 ? 'ValueReal' : 'ValueAcceptance',
                                headerName: 'Khối lượng',
                                width: 150,
                                cellClass: ['no-focus', 'text-center'],
                                headerClass: 'text-center',
                                valueFormatter: params => this.commonService.roundConstructionVolume(params, 100)
                            },
                            {
                                field: this.type == 1 ? 'TotalReal' : 'TotalAcceptance',
                                headerName: 'Giá trị',
                                width: 150,
                                cellClass: ['no-focus', 'text-right'],
                                headerClass: 'text-right',
                                valueFormatter: this.commonService.formatNumber
                            },
                            {
                                field: this.type == 1 ? 'ValueRealOld' : 'ValueAcceptanceOld',
                                headerName: 'Lũy kế đến kỳ này',
                                width: 200,
                                cellClass: ['no-focus', 'text-center'],
                                headerClass: 'text-center',
                                valueFormatter: params => this.commonService.roundConstructionVolume(params, 100)
                            }
                        ]
                    }
                ]
            }
        ];
    }

    upload() {
        if (this.files.length === 0) {
            this.toastrService.warning("Không có file nào được chọn!");
            return;
        }

        const formData = new FormData();

        for (const file of this.files) {
            formData.append(file.name, file);
        }

        const uploadReq = new HttpRequest('POST', domainApi + 'monitoringHistory/importCategoryCashExcelNLevel/' + this.type, formData, {
            headers: new HttpHeaders({
                'Authorization': 'bearer ' + localStorage.getItem(TokenEnum.ACCESS_TOKEN)
            }),
            reportProgress: true,
        });

        this.importing == true;
        this.http.request(uploadReq).subscribe(event => {
            if (event.type === HttpEventType.UploadProgress) {
            } else if (event.type === HttpEventType.Response) {
                this.rowData = event.body['data'];
                this.rowData = this.rowData.filter(x => x.Name != undefined && x.Name != "" && x.Name != '' && ((x.Value && x.Value > 0) || !x.Value));

                this.rowData.forEach((item) => {
                    if (item.Value) {
                        if (item.ValueRealOld != undefined && item.ValueReal != undefined)
                            item.LuyKeThucTeKyTruoc = item.ValueRealOld - item.ValueReal;
                        else item.LuyKeThucTeKyTruoc = 0;

                        if (item.ValueAcceptanceOld != undefined && item.ValueAcceptance != undefined)
                            item.LuyKeNghiemThuKyTruoc = item.ValueAcceptanceOld - item.ValueAcceptance;
                        else item.LuyKeNghiemThuKyTruoc = 0;
                    }

                    return item;
                });
                this.importing = false;
                this.file.nativeElement.value = "";
                // if(!this.data) this.data = [];
                // event.body['data'].forEach(file => {
                //     let obj = {};
                //     obj["Name"] =  file;
                //     obj["Url"] =  file;

                //     this.data.push(obj);
                // });
            }
        });
    }

    exportRootFile() {
        const url = domainApi + 'monitoringHistory/exportCategoryExcel/' + this.contractId + '/' + this.planId + '/' + this.type;
        const options = new RequestOptions({
            headers: new Headers({
                'Authorization': 'bearer ' + this.storageService.get(TokenEnum.ACCESS_TOKEN)
            }),
            responseType: ResponseContentType.Blob,
        });

        this.exporting = true;
        this.httpDownload.post(url, null, options).subscribe(res => {
            if (res.blob().size == 0) {
                this.toastrService.error("Export file không thành công!");
            }
            else {
                const fileName = `Khối lượng theo đợt.xlsx`;
                saveFile(res.blob(), fileName);
                this.toastrService.success("Thành công!");
            }

            this.exporting = false;
        });
    }

    //Get danh sách kế hoạch
    getListPlan() {
        this.isLoadingPlan = true;
        this.monitoringContractService.getListPlan(this.contractId).subscribe((res) => {
            this.isLoadingPlan = false;
            if (res["meta"]["error_code"] == 200) {
                this.listPlans = res["data"];
            }
            
        }, () => this.isLoadingPlan = false);
    }

    changePlan() {
        //this.gridOptions.api.showLoadingOverlay();
        //this.rowData = [];
        if (!this.planId) return;
        this.gridOptions.api.showLoadingOverlay();
        this.monitoringContractService.getVolumeByPlanId(this.contractId, this.planId).subscribe((res) => {
            if (res["meta"]["error_code"] == 200) {
                if(!res["data"] || !res["data"].length) {
                    this.rowData = [];
                    this.gridOptions.api.showNoRowsOverlay();
                }

                this.rowData = res["data"].filter(x => (x.Value && x.Value > 0) || !x.Value);
                this.rowData.forEach((item) => {
                    if (item.Value) {
                        if (item.ValueRealOld != undefined && item.ValueReal != undefined)
                            item.LuyKeThucTeKyTruoc = item.ValueRealrowDataOld - item.ValueReal;
                        else item.LuyKeThucTeKyTruoc = 0;

                        if (item.ValueAcceptanceOld != undefined && item.ValueAcceptance != undefined)
                            item.LuyKeNghiemThuKyTruoc = item.ValueAcceptanceOld - item.ValueAcceptance;
                        else item.LuyKeNghiemThuKyTruoc = 0;
                    }

                    return item;
                });
            } else {
                this.rowData = [];
                this.gridOptions.api.showNoRowsOverlay();
            }
        });
    }

    saveReal() {
        if (this.rowData == undefined) {
            this.toastrService.error("Không có dữ liệu");
            return;
        }

        if (this.rowData.length == 0) {
            this.toastrService.error("Không có dữ liệu");
            return;
        }

        this.submitted = true;
        this.monitoringContractService.saveVolume(this.contractId, (this.type == 1 ? 4 : 5), this.rowData).subscribe((res) => {
            this.submitted = false;
            if (res["meta"]["error_code"] == 200) {
                this.changePlan();
                this.toastrService.success("Lưu kế hoạch thành công!");
            }
            else this.toastrService.error(res["meta"]["error_message"]);
        });
    }

    //xuất báo cáo
    export() {
        const url = domainApi + 'monitoringHistory/reportCategoryExcel/' + this.contractId + '/' + this.planId + '/' + this.type;
        const options = new RequestOptions({
            headers: new Headers({
                'Authorization': 'bearer ' + this.storageService.get(TokenEnum.ACCESS_TOKEN)
            }),
            responseType: ResponseContentType.Blob,
        });

        this.exporting = true;
        this.httpDownload.post(url, null, options).subscribe(res => {
            if(res.blob().size == 0 ) {
                this.toastrService.error("Export file báo cáo khối lượng " + (this.type == 1 ? "thực hiện" : "nghiệm thu") + " !");
            }
            else {
                const fileName = `Báo cáo thanh toán danh sách hợp đồng.xlsx`;
                saveFile(res.blob(), fileName);
                this.toastrService.success("Thành công!");
            }

            this.exporting = false;
        });
    }
}
