import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConstant } from 'app/shared/common/api.constants';
import { DataService } from 'app/shared/services/data.service';
import { Paging } from 'app/viewModels/base-query';
import { map, catchError } from 'rxjs/operators';
import { DbOneDriveFolder } from './../viewModels/onedrive-folder/db-onedrive-folder';

@Injectable({
  providedIn: 'root'
})
export class OneDriveFolderService {

  public subject = new Subject<any>();

  constructor(
    private dataService: DataService,
    private http: HttpClient
  ) { }

  getByPage(pageOption: Paging) {
    return this.dataService.get(ApiConstant.GetOneDriveFolderByPage
      .concat(`?page=`, '' + pageOption.page)
      .concat(`&page_size=`, '' + pageOption.page_size)
      .concat(`&query=`, pageOption.query)
      .concat(`&select=`, pageOption.select)
      .concat('&order_by=', pageOption.order_by))
      .pipe(map((res: any) => {
        return res;
      }));
  }

  update(id: number, itemProduct: DbOneDriveFolder) {
    return this.dataService.put(ApiConstant.ApiUrlOneDriveFolder.concat('/' + id), itemProduct)
      .pipe(
        map(res => {
          return res;
        }
        ))
  }


  create(itemProduct: DbOneDriveFolder) {
    return this.dataService.post(ApiConstant.ApiUrlOneDriveFolder, itemProduct)
      .pipe(
        map(res => {
          return res;
        }
        ))
  }

  getById(id: number): Observable<any> {
    return this.dataService.get(ApiConstant.ApiUrlOneDriveFolder.concat('/' + id))
      .pipe(
        map(res => {
          return res;
        }
        ))
  }

  delete(id: number): Observable<any> {
    return this.dataService.delete(ApiConstant.ApiUrlOneDriveFolder + '/', id.toString())
      .pipe(
        map(res => {
          return res;
        }
        ))
  }

  deleteFolder(projectId: number, data: any): Observable<any> {
    return this.dataService.put(ApiConstant.DeleteFolderOneDriveFolder + '/' + projectId, data)
      .pipe(
        map(res => {
          return res;
        }
        ))
  }

  getAttactmentByFolderId(onedriverFolderId): Observable<any> {
    return this.dataService.get(ApiConstant.GetAttactmentByOneDriveFolderId + '/' + onedriverFolderId)
      .pipe(
        map(res => {
          return res;
        }
        ))
  }

  createConfig(data: any, projectId: number, contractId: number) {
    return this.dataService.post(ApiConstant.ApiUrlOneDriveFolder + '/createConfig/' + projectId + '/' + contractId, data)
      .pipe(
        map(res => {
          return res;
        }
        ))
  }

  createConfigAdditional(data: any, projectId: number, contractId: number) {
    return this.dataService.post(ApiConstant.ApiUrlOneDriveFolder + '/createConfigPlus/' + projectId + '/' + contractId, data)
      .pipe(
        map(res => {
          return res;
        }
        ))
  }

  getDriverNLevel(contractId: number): Observable<any> {
    return this.dataService.get(ApiConstant.ApiUrlOneDriveFolder.concat('/getDriverNLevel?ContractId=' + contractId))
      .pipe(
        map(res => {
          return res;
        }
        ))
  }

  getFolderTree(data) {
    return this.dataService.post(ApiConstant.ApiUrlOneDriveFolder + '/getFolderTree/', data)
      .pipe(
        map(res => {
          return res;
        }
        ))
  }

  // Đẩy lên one
  uploadOnedriverV2(data) {
    return this.dataService.post(ApiConstant.ApiUrlOneDriveFolder + 'upload/uploadOnedriverV2', data)
      .pipe(
        map(res => {
          return res;
        }
        ))
  }

  downloadOnedriver() {
    return this.dataService.get(ApiConstant.ApiUrlOneDriveFolder.concat('upload/downloadOnedriver/'))
      .pipe(
        map(res => {
          return res;
        }
        ))
  }

  oneDriveFolderShares(data) {
    return this.dataService.post(ApiConstant.oneDriveFolderShares, data)
      .pipe(
        map(res => {
          return res;
        }
        ))
  }
  // getOneDriveFolderShares
  // api/oneDriverV2/oneDriveFolderShares?page=1&query=Id=xxx&order_by=
  getOneDriveFolderShares(pageOption) {
    return this.dataService.get(ApiConstant.getOneDriveFolderShares
      .concat(`?page=`, '' + pageOption.page)
      .concat(`&page_size=`, '' + pageOption.page_size)
      .concat(`&Id=`, '' + pageOption.id)
      .concat(`&order_by=`, '' + pageOption.order_by)
      .concat(`&query=`, pageOption.query))
      .pipe(map((res: any) => {
        return res;
      }));
  }

  // thêm mới
  shareFolder(id, data) {
    console.log('shareFolder', data)
    return this.dataService.post(ApiConstant.ApiUrlOneDriveFolder + '/shareFolder/' + id, data)
      .pipe(
        map(res => {
          return res;
        }
        ))
  }

  download(idP, data) {
    return this.dataService.post(ApiConstant.download.concat('/' + idP), data)
      .pipe(
        map(res => {
          return res;
        }
        ))
  }

  syncFolderTree(data) {
    return this.dataService.post(ApiConstant.ApiUrlOneDriveFolder + '/syncFolderTree', data)
      .pipe(
        map(res => {
          return res;
        }
        ))
  }

  postOneDriveFolder(data) {
    return this.dataService.post(ApiConstant.ApiUrlOneDriveFolder + '/PostOneDriveFolder', data)
      .pipe(
        map(res => {
          return res;
        }
        ))
  }

  putOneDriveFolder(data) {
    return this.dataService.put(ApiConstant.ApiUrlOneDriveFolder + '/PutOneDriveFolder', data)
      .pipe(
        map(res => {
          return res;
        }
        ))
  }

  getOneDriveFolder(id: number): Observable<any> {
    return this.dataService.get(ApiConstant.ApiUrlOneDriveFolder.concat('/GetOneDriveFolder/' + id))
      .pipe(
        map(res => {
          return res;
        }
        ))
  }
}
