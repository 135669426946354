import { Component, OnInit, Input, Output, ViewChild, NgModule } from '@angular/core';
import { NgbDatepickerI18n, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { CustomDatepickerI18n, I18n } from "app/shared/services/date-picker-i18n";

@Component({
    selector: 'app-google-map-common',
    templateUrl: './google-map.component.html',
    styleUrls: [],
    providers: [
        I18n,
        { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
    ]
})

export class GoogleMapCommonComponent implements OnInit {
    @ViewChild('f') public form: NgForm;

    @Input() lat;
    @Input() lng;

    constructor(
        public modal: NgbActiveModal
    ) { }

    ngOnInit(): void {
        
    }
}