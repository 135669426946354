import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DbOneDriveFolder } from 'app/viewModels/onedrive-folder/db-onedrive-folder';
import { OneDriveFolderService } from "app/services/onedrive-folder.service";

@Component({
    selector: 'app-onedrivefolder-shared',
    templateUrl: './onedrivefolder.component.html',
    styleUrls: [],
    providers: []
})
export class OnedrivefolderComponent implements OnInit {
    @ViewChild('f') public form: NgForm;
    @Input() input: any;
    @Input() type: number;
    @Input() id: number;
    @Output() dataResponse: EventEmitter<any> = new EventEmitter();

    model: DbOneDriveFolder;
    Action: any;
    submitted = false;

    constructor(
        public modal: NgbActiveModal,
        private toast: ToastrService,
        private oneDriveFolderService: OneDriveFolderService) { }

    ngOnInit(): void {
        this.model = new DbOneDriveFolder();
        if(!this.id) {
            this.model.FolderParentId = this.type == 2 ? (this.input.Id ? this.input.Id : this.input.id) : 0;
            this.model.ContractId = this.type == 2 ? undefined : this.input.id;
        }
        else {
            this.getOneDriveFolder();
        }
    }

    save() {
        this.submitted = true;
        let data = Object.assign({}, this.model);

        if(data.Id) {
            this.oneDriveFolderService.putOneDriveFolder(data).subscribe((res) => {
                this.submitted = false;
                if (res["meta"]["error_code"] == 200) {
                    this.toast.success(res["meta"]["error_message"]);
                    this.dataResponse.emit(res["data"]);
                    this.modal.close(res["data"]);
                }
                else {
                    this.dataResponse.emit(null);
                    this.toast.error(res["meta"]["error_message"]);
                }
    
            });
        }
        else {
            this.oneDriveFolderService.postOneDriveFolder(data).subscribe((res) => {
                this.submitted = false;
                if (res["meta"]["error_code"] == 200) {
                    this.toast.success(res["meta"]["error_message"]);
                    this.dataResponse.emit(res["data"]);
                    this.modal.close(res["data"]);
                }
                else {
                    this.dataResponse.emit(null);
                    this.toast.error(res["meta"]["error_message"]);
                }
            });
        }
    }

    getOneDriveFolder() {
        this.oneDriveFolderService.getOneDriveFolder(this.id).subscribe((res) => {
            if (res["meta"]["error_code"] == 200) {
                this.model = res["data"];
            }
        });
    }
}
