// Author: T4professor

import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
// import { ICellRendererParams, IAfterGuiAttachedParams } from 'ag-grid';

@Component({
    selector: 'app-button-renderer',
  template: `<div class="wrap-btn-cell"><ng-container *ngFor="let item of params.buttons; let i = index">
  <span (click)="onClick($event, i)" ><i class="{{item.icon}}"></i></span></ng-container></div>`
})

export class ButtonAgGridComponent implements ICellRendererAngularComp {

  params: any;
  items = [];
  label: string = '';
  agInit(params: any): void {
    this.params = params;
    this.items = this.params.buttons;
  }

  refresh(params?: any): boolean {
    return true;
  }

  onClick($event: any, idx: any) {
    if (this.params.buttons[idx].onClick instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data,
        index: this.params.rowIndex
        // ...something
      };
      this.params.buttons[idx].onClick(params);
    }
  }
}
