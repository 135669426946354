import { DbUser } from '../viewModels/user/db-user';
import { UserChangePass } from 'app/viewModels/user/user-change-pass';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiConstant } from 'app/shared/common/api.constants';
import { DataService } from 'app/shared/services/data.service';
import { Paging } from 'app/viewModels/base-query';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public isEdit = false;
  public subject = new Subject<any>();
  private itemProductSource = new BehaviorSubject(this.isEdit);

  constructor(
    private dataService: DataService,
  ) { }

  getByPage(pageOption: Paging) {
    return this.dataService.get(ApiConstant.GetUserByPage
      .concat(`?page=`, '' + pageOption.page)
      .concat(`&page_size=`, '' + pageOption.page_size)
      .concat(`&query=`, pageOption.query)
      .concat(`&select=`, pageOption.select)
      .concat('&order_by=', pageOption.order_by))
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getListUserMonitoring() {
    return this.dataService.get(ApiConstant.GetUserMonitoring)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  update(id: number, itemProduct: DbUser) {
    return this.dataService.put(ApiConstant.ApiUrlUserRole.concat('/' + id), itemProduct)
      .pipe(
        map(res => {
          return res;
        }
        ))
  }

  create(itemProduct: DbUser) {
    return this.dataService.post(ApiConstant.ApiUrlUserRole, itemProduct)
      .pipe(
        map(res => {
          return res;
        }
        ))
  }

  getById(id: number): Observable<any> {
    return this.dataService.get(ApiConstant.ApiUrlUserRole.concat('/' + id))
      .pipe(
        map(res => {
          return res;
        }
        ))
  }

  delete(id: number): Observable<any> {
    return this.dataService.delete(ApiConstant.ApiUrlUserRole + '/', id.toString())
      .pipe(
        map(res => {
          return res;
        }
        ))
  }

  deletes(data: Array<number>): Observable<any> {
    return this.dataService.post(ApiConstant.ApiUrlUserRole, data)
      .pipe(
        map(res => {
          return res;
        }
        ))
  }

  getUserByFunctionCode(pageOption: Paging, code: string, projectId: number, departmentId: number) {
    return this.dataService.get(ApiConstant.ApiUrlUserRole
      .concat(`/GetUserByFunctionCode/` , '' + code)
      .concat(`/` , '' + projectId)
      .concat(`/` , '' + departmentId)
      .concat(`?page=`, '' + pageOption.page)
      .concat(`&page_size=`, '' + pageOption.page_size)
      .concat(`&query=`, pageOption.query)
      .concat(`&select=`, pageOption.select)
      .concat('&order_by=', pageOption.order_by))
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getUserJoinProject(pageOption: Paging) {
    return this.dataService.get(ApiConstant.ApiUrlUserRole
      .concat(`/GetUserJoinProject`)
      .concat(`?page=`, '' + pageOption.page)
      .concat(`&page_size=`, '' + pageOption.page_size)
      .concat(`&query=`, pageOption.query)
      .concat(`&select=`, pageOption.select)
      .concat('&order_by=', pageOption.order_by))
      .pipe(map((res: any) => {
        return res;
      }));
  }

  adminChangePass(itemProduct: UserChangePass) {
    return this.dataService.put(ApiConstant.ApiUrlUser.concat('/adminChangePass', '/' + itemProduct.UserId), itemProduct)
      .pipe(
        map(res => {
          return res;
        }))
  }

  lockUser(id: number, status: number) {
    return this.dataService.put(ApiConstant.ApiUrlUser.concat('/lockUser', '/' + id, '/' + status), null)
      .pipe(
        map(res => {
          return res;
        }))
  }

  getUserMappingByProId(proId: number) {
    return this.dataService.get(ApiConstant.ApiUrlUserRole
      .concat(`/GetUserMappingByProId/${proId}`))
      .pipe(map((res: any) => {
        return res;
      }));
  }
}
