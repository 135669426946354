import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: []
})
export class ConfirmComponent implements OnInit {
  @Input() title: string;
  @Input() item: string;
  @Input() titleItem: string;
  @Input() message: string;
  @Input() messageItem: string;
  @Input() messageError: string;
  @Output() isConfirm: EventEmitter<any> = new EventEmitter();
  isDisabled = false;

  constructor(public modal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  confirm(): void {
    this.isDisabled = true;
    this.isConfirm.emit(true);
  }
 
  decline(): void {
    this.modal.close();
  }
}
