<div class="modal-header">
    <h4 class="modal-title">
        <span><i class="fa fa-eye-circle mr-1" aria-hidden="true"></i>{{ title }}</span>
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="[modal.close(true), f.resetForm()]">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<form name="form" class="form-custom formPopupAddProj dialogPhongBan" (keydown.enter)="$event.preventDefault()" (ngSubmit)="f.form.valid && (targetType == 9 || (targetType != 9 && model.attactments && model.attactments.length > 0)) && save()" #f="ngForm" novalidate>
    <div class="modal-body">
        <div class="infoPackage">
            <div class="inDetailPackage">
                <div class="Detail_Packed AddForm_Package">
                    <div class="ColForm_AddPackage formPopupDialog">
                        <div class="AlliteminfoPk inInfoPackage displayflex"  [ngClass]="targetType == 2 || targetType == 3 || targetType == 4 || targetType == 5 || targetType == 9  ? 'nameOfLegalProc' : 'nameAlLegal'">
                            <div class="iteminfoPk">
                                <div class="labelinfoPk">
                                    <span *ngIf="targetType == 1 || targetType == 6 || targetType == 7 || targetType == 8">Tên thư mục file <span class="required">(*)</span></span>
                                    <span *ngIf="targetType == 2">Tên thủ tục <span class="required">(*)</span></span>
                                    <span *ngIf="targetType == 3">Tên hồ sơ <span class="required">(*)</span></span>
                                    <span *ngIf="targetType == 4">Tên biên bản - hồ sơ <span class="required">(*)</span></span>
                                    <span *ngIf="targetType == 5">Tên chủ trương - chỉ đạo <span class="required">(*)</span></span>
                                    <span *ngIf="targetType == 9">Tên công việc <span class="required">(*)</span></span>
                                </div>
                                <div class="valueInfoPk" *ngIf="targetType != 9">
                                    <input type="text" id="Name" name="Name" [(ngModel)]="model.Name"
                                        [ngClass]="{'border-error' : f.submitted && Name.errors}" #Name="ngModel"
                                        autocomplete="off" spellcheck="false" required [disabled]="isView" [pattern]="textAndNumberPattern">
                                    <span *ngIf="f.submitted && Name.errors" class="spanValidate">
                                        <span *ngIf="Name.errors.required && (targetType == 1 || targetType == 6 || targetType == 7 || targetType == 8)" class="spanValidate">Vui lòng nhập Tên thư mục file</span>
                                        <span *ngIf="Name.errors.required && targetType == 2" class="spanValidate">Vui lòng nhập Tên thủ tục</span>
                                        <span *ngIf="Name.errors.required && targetType == 3" class="spanValidate">Vui lòng nhập Tên hồ sơ</span>
                                        <span *ngIf="Name.errors.required && targetType == 4" class="spanValidate">Vui lòng nhập Tên biên bản - hồ sơ</span>
                                        <span *ngIf="Name.errors.required && targetType == 5" class="spanValidate">Vui lòng nhập Tên chủ trương - chỉ đạo</span>
                                        <span *ngIf="Name.errors.pattern">
                                            <span *ngIf="targetType == 1 || targetType == 6 || targetType == 7 || targetType == 8">Tên thư mục file</span>
                                            <span *ngIf="targetType == 2">Tên thủ tục</span>
                                            <span *ngIf="targetType == 3">Tên hồ sơ</span>
                                            <span *ngIf="targetType == 4">Tên biên bản - hồ sơ</span>
                                            <span *ngIf="targetType == 5">Tên chủ trương - chỉ đạo</span>
                                            không chứa ký tự đặc biệt. (Các ký tự được phép sử dụng: "-" "_" ".")</span>

                                    </span>
                                </div>
                                <div class="valueInfoPk" *ngIf="targetType == 9">
                                    <input type="text" id="Name" name="Name" [(ngModel)]="model.Name"
                                        [ngClass]="{'border-error' : f.submitted && Name.errors}" #Name="ngModel"
                                        autocomplete="off" spellcheck="false" required [disabled]="isView">
                                    <span *ngIf="f.submitted && Name.errors" class="spanValidate">
                                        <span *ngIf="Name.errors.required" class="spanValidate">Vui lòng nhập Tên công việc</span>
                                    </span>
                                </div>
                            </div>
                            <div *ngIf="targetType == 1 || targetType == 6 || targetType == 7 || targetType == 8" class="iteminfoPk">
                                <div class="labelinfoPk">
                                    <span>Ngày cập nhật <span class="required"></span></span>
                                </div>
                                <div class="valueInfoPk datetime">
                                    <div class="input-group mb-0">
                                        <input 
                                        class="form-control"
                                        placeholder="dd/MM/yyyy"
                                        rInputMask="99/99/9999" #CorrectionDateModel="ngModel"
                                        [ngClass]="{'border-error' : f.submitted && CorrectionDateModel.errors}"
                                        name="CorrectionDate" [(ngModel)]="model.CorrectionDate"
                                        ngbDatepicker #CorrectionDate="ngbDatepicker">
                                        <div class="input-group-append">
                                          <button class="btn btn-outline-secondary calendar" (click)="CorrectionDate.toggle()" type="button">
                                            <i class="fa fa-calendar"></i>
                                          </button>
                                        </div>
                                    </div>
                                    <span *ngIf="f.submitted && CorrectionDateModel.errors"
                                        class="err-msg">
                                        <span *ngIf="CorrectionDateModel.errors.ngbDate?.invalid">Ngày cập nhật không hợp lệ</span>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="targetType == 2 || targetType == 3 || targetType == 4" class="AlliteminfoPk inInfoPackage displayflex">
                            <div class="iteminfoPk">
                                <div class="labelinfoPk">
                                    <span *ngIf="targetType == 2 || targetType == 4" >Ngày hiệu lực văn bản</span>
                                    <span *ngIf="targetType == 3" >Ngày hiệu chỉnh</span>
                                </div>
                                <div class="valueInfoPk datetime">
                                    <div class="input-group mb-0">
                                        <input 
                                        class="form-control"
                                        placeholder="dd/MM/yyyy" [maxDate]="checkMinMaxDate(model.EndDateReal)"
                                        name="CorrectionDate" [(ngModel)]="model.CorrectionDate"
                                        rInputMask="99/99/9999" #CorrectionDateModel="ngModel" autocomplete="off" spellcheck="false"
                                        [ngClass]="{'border-error' : f.submitted && CorrectionDateModel.errors}"
                                        ngbDatepicker #CorrectionDate="ngbDatepicker">
                                        <div class="input-group-append">
                                          <button class="btn btn-outline-secondary calendar" (click)="CorrectionDate.toggle()" type="button">
                                            <i class="fa fa-calendar"></i>
                                          </button>
                                        </div>
                                    </div>
                                    <span *ngIf="f.submitted && CorrectionDateModel.errors"
                                        class="err-msg">
                                        <span *ngIf="CorrectionDateModel.errors.ngbDate?.invalid">Ngày
                                            <span *ngIf="targetType == 2 || targetType == 4"> hiệu lực văn bản </span>
                                            <span *ngIf="targetType == 3"> hiệu chỉnh </span>
                                        không hợp lệ</span>
                                        <span *ngIf="CorrectionDateModel.errors.ngbDate?.maxDate" class="spanValidate">Ngày 
                                            <span *ngIf="targetType == 2 || targetType == 4">hiệu lực văn bản</span>
                                            <span *ngIf="targetType == 3">hiệu chỉnh</span>
                                             phải nhỏ hơn ngày 
                                             <span *ngIf="targetType == 2">hoàn thành thực tế</span>
                                             <span *ngIf="targetType == 4">hoàn thành</span>
                                        </span>
                                    </span>
                                </div>
                            </div>

                            <div *ngIf="targetType == 2 || targetType == 4" class="iteminfoPk">
                                <div class="labelinfoPk">
                                    <span>Ngày hoàn thành<span *ngIf="targetType == 2"> thực tế</span> <span class="required"></span></span>
                                </div>
                                <div class="valueInfoPk datetime">
                                    <div class="input-group mb-0">
                                        <input 
                                        class="form-control"
                                        placeholder="dd/MM/yyyy" [minDate]="checkMinMaxDate(model.CorrectionDate)"
                                        rInputMask="99/99/9999" #EndDateRealModel="ngModel"
                                        [ngClass]="{'border-error' : f.submitted && EndDateRealModel.errors}"
                                        name="EndDateReal" [(ngModel)]="model.EndDateReal"
                                        ngbDatepicker #EndDateReal="ngbDatepicker">
                                        <div class="input-group-append">
                                          <button class="btn btn-outline-secondary calendar" (click)="EndDateReal.toggle()" type="button">
                                            <i class="fa fa-calendar"></i>
                                          </button>
                                        </div>
                                    </div>
                                    <span *ngIf="f.submitted && EndDateRealModel.errors"
                                        class="err-msg">
                                        <span *ngIf="EndDateRealModel.errors.ngbDate?.invalid && targetType == 2">Ngày hoàn thành thực tế không hợp lệ</span>
                                        <span *ngIf="EndDateRealModel.errors.ngbDate?.invalid && targetType == 4">Ngày hoàn thành không hợp lệ</span>
                                        <span *ngIf="EndDateRealModel.errors.ngbDate?.minDate" class="spanValidate">
                                            Ngày 
                                            <span *ngIf="targetType == 2">hoàn thành thực tế</span>
                                            <span *ngIf="targetType == 4">hoàn thành</span>
                                             phải lớn hơn 
                                             <span *ngIf="targetType == 2 || targetType == 4">hiệu lực văn bản</span>
                                             <span *ngIf="targetType == 3">hiệu chỉnh</span>
                                        </span>
                                    </span>
                                </div>
                            </div>

                            <div *ngIf="targetType == 3" class="iteminfoPk">
                                <div class="labelinfoPk">
                                    <span>Nhóm hồ sơ thiết kế</span>
                                </div>
                                <div class="valueInfoPk datetime">
                                    <div class="input-group mb-0">
                                        <ng-select [items]="groupDesignFile" class="select ngSelectPopup ngSelectinput" bindLabel="Name"
                                            bindValue="Id" [multiple]="false"
                                            [(ngModel)]="model.GroupDesignFile"
                                            [ngModelOptions]="{standalone: true}"
                                            placeholder="Nhóm hồ sơ thiết kế" [disabled]="isView">
                                        </ng-select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="targetType == 9" class="AlliteminfoPk inInfoPackage displayflex">
                            <div class="iteminfoPk">
                                <div class="labelinfoPk">
                                    <span>Ngày bắt đầu</span>
                                </div>
                                <div class="valueInfoPk datetime">
                                    <div class="input-group mb-0">
                                        <input 
                                        class="form-control"
                                        placeholder="dd/MM/yyyy" [maxDate]="checkMinMaxDate(model.EndDate)"
                                        name="StartDate" [(ngModel)]="model.StartDate"
                                        rInputMask="99/99/9999" #StartDateModel="ngModel" autocomplete="off" spellcheck="false"
                                        [ngClass]="{'border-error' : f.submitted && StartDateModel.errors}"
                                        ngbDatepicker #StartDate="ngbDatepicker">
                                        <div class="input-group-append">
                                          <button class="btn btn-outline-secondary calendar" (click)="StartDate.toggle()" type="button">
                                            <i class="fa fa-calendar"></i>
                                          </button>
                                        </div>
                                    </div>
                                    <span *ngIf="f.submitted && StartDateModel.errors"
                                        class="err-msg">
                                        <span *ngIf="StartDateModel.errors.ngbDate?.invalid">Ngày bắt đầu không hợp lệ</span>
                                        <span *ngIf="StartDateModel.errors.ngbDate?.maxDate" class="spanValidate">Ngày bắt đầu phải nhỏ hơn ngày kết thúc
                                        </span>
                                    </span>
                                </div>
                            </div>

                            <div class="iteminfoPk">
                                <div class="labelinfoPk">
                                    <span>Ngày kết thúc<span class="required"></span></span>
                                </div>
                                <div class="valueInfoPk datetime">
                                    <div class="input-group mb-0">
                                        <input 
                                        class="form-control"
                                        placeholder="dd/MM/yyyy" [minDate]="checkMinMaxDate(model.StartDate)"
                                        rInputMask="99/99/9999" #EndDateModel="ngModel"
                                        [ngClass]="{'border-error' : f.submitted && EndDateModel.errors}"
                                        name="EndDate" [(ngModel)]="model.EndDate"
                                        ngbDatepicker #EndDate="ngbDatepicker">
                                        <div class="input-group-append">
                                          <button class="btn btn-outline-secondary calendar" (click)="EndDate.toggle()" type="button">
                                            <i class="fa fa-calendar"></i>
                                          </button>
                                        </div>
                                    </div>
                                    <span *ngIf="f.submitted && EndDateModel.errors"
                                        class="err-msg">
                                        <span *ngIf="EndDateModel.errors.ngbDate?.invalid">Ngày bắt đầu không hợp lệ</span>
                                        <span *ngIf="EndDateModel.errors.ngbDate?.minDate">Ngày bắt đầu phải lớn hơn ngày kết thúc</span>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="targetType == 9" class="AlliteminfoPk inInfoPackage displayflex">
                            <div class="iteminfoPk">
                                <div class="labelinfoPk">
                                    <span>Người phụ trách</span>
                                </div>
                                <div class="valueInfoPk datetime">
                                    <div class="input-group mb-0">
                                        <ng-select [items]="userMappings" class="select ngSelectPopup ngSelectinput" bindLabel="FullName"
                                            bindValue="UserId" [multiple]="false"
                                            [(ngModel)]="model.RelatedId"
                                            (change)="chooseUserMapping()"
                                            [ngModelOptions]="{standalone: true}"
                                            placeholder="Người phụ trách công việc">
                                        </ng-select>
                                    </div>
                                </div>
                            </div>
                            <div class="iteminfoPk">
                                <div class="labelinfoPk">
                                    <span>Trạng thái</span>
                                </div>
                                <div class="valueInfoPk datetime">
                                    <div class="input-group mb-0">
                                        <ng-select [items]="bidpackageJobStatus" class="select ngSelectPopup ngSelectinput" bindLabel="Name"
                                            bindValue="Id" [multiple]="false"
                                            [(ngModel)]="model.CompleteStatus"
                                            [ngModelOptions]="{standalone: true}"
                                            placeholder="Trạng thái công việc">
                                        </ng-select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="targetType == 2 || targetType == 4" class="AlliteminfoPk inInfoPackage displayflex">
                            <div class="iteminfoPk">
                                <div class="labelinfoPk">
                                    <span>Trạng thái</span>
                                </div>
                                <div class="valueInfoPk datetime">
                                    <div class="input-group mb-0">
                                        <ng-select [items]="completeStatus" class="select ngSelectPopup ngSelectinput" bindLabel="Name"
                                        bindValue="Id" [multiple]="false"
                                        [(ngModel)]="model.CompleteStatus"
                                        [addTag]="addCustomUser"
                                        [ngModelOptions]="{standalone: true}"
                                        placeholder="Trạng thái">
                                        </ng-select>
                                    </div>
                                </div>
                            </div>
                            <div class="iteminfoPk" *ngIf="targetType == 4">
                                <div class="labelinfoPk">
                                    <span>Tỷ lệ</span>
                                </div>
                                <div class="valueInfoPk">
                                    <input type="text" id="Ratio" name="Ratio" [(ngModel)]="model.Ratio"
                                            [ngClass]="{'border-error' : f.submitted && Ratio.errors}" #Ratio="ngModel"
                                            autocomplete="off" spellcheck="false" [disabled]="isView">
                                </div>
                            </div>
                        </div>
                        
                        <div class="areaAdd_Package pl-0 addContentPopup">
                            <div class="labelinfoPk">
                                <span *ngIf="targetType == 1 || targetType == 6 || targetType == 7 || targetType == 8 || targetType == 9">Ghi chú</span>
                                <span *ngIf="targetType == 2 || targetType == 3 || targetType == 4 || targetType == 5">Nội dung</span>
                            </div>
                            <div class="valueInfoPk">
                                <textarea class="areaAdd_Package" id="Note" name="Note"
                                    [(ngModel)]="model.Note" [ngModelOptions]="{standalone: true}"
                                    [ngClass]="{'border-error' : f.submitted && Note.errors}" #Note="ngModel"
                                    autocomplete="off" spellcheck="false" [disabled]="isView"
                                    placeholder=""></textarea>
                            </div>
                        </div>
                        <div class="AlliteminfoPk inInfoPackage displayflex mb-3" *ngIf="targetType == 9">
                            <div class="iteminfoPk">
                                <div class="labelinfoPk w-75">
                                    <span>Cảnh báo trước ngày bắt đầu <span
                                            class="required"></span></span>
                                </div>
                                <div class="valueInfoPk w-25 input-group mb-0">
                                    <input type="number" id="WarningStart"
                                        name="WarningStart"  onkeypress="return (event.charCode >= 48 && event.charCode <=57)"
                                        [(ngModel)]="model.WarningStart"
                                        [ngClass]="{'border-error' : f.submitted && WarningStart.errors}"
                                        #WarningStart="ngModel" autocomplete="off"
                                        spellcheck="false" [disabled]="isView">
                                    <div class="input-group-prepend" style="top: 0px; height: 36px;">
                                        <div class="input-group-text">Ngày</div>
                                    </div>
                                </div>
                            </div>
                            <div class="iteminfoPk">
                                <div class="labelinfoPk w-75">
                                    <span>Cảnh báo trước ngày kết thúc <span
                                            class="required"></span></span>
                                </div>
                                <div class="valueInfoPk w-25 input-group mb-0">
                                    <input type="number" id="WarningEnd" name="WarningEnd"
                                        [(ngModel)]="model.WarningEnd"  onkeypress="return (event.charCode >= 48 && event.charCode <=57)"
                                        [ngClass]="{'border-error' : f.submitted && WarningEnd.errors}"
                                        #WarningEnd="ngModel" autocomplete="off"
                                        spellcheck="false" [disabled]="isView">
                                    <div class="input-group-prepend" style="top: 0px; height: 36px;">
                                        <div class="input-group-text">Ngày</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <app-attactment *ngIf="targetType != 9" #attactment [data]="model.attactments" (ngModel)="model.attactments" (eventEmitter)="model.attactments = $event" [isView]="isView" [isRequired]="true"></app-attactment>
                        <span *ngIf="f.submitted && !model.attactments && targetType != 9" class="spanValidate">Chưa có file đính kèm</span>
                        <span *ngIf="f.submitted && model.attactments && targetType != 9" class="spanValidate">
                            <span *ngIf="f.submitted && model.attactments.length==0">Chưa có file đính kèm</span>
                        </span>

                        <div *ngIf="targetType == 1 || targetType == 2 || targetType == 3 || targetType == 4 || targetType == 7" class="AlliteminfoPk inInfoPackage openSaveLegal displayflex">
                            <div class="iteminfoPk">
                                <div class="labelinfoPk">
                                    <span>Bật lưu trữ <span class="required"></span></span>
                                </div>
                                <div class="valueInfoPk datetime">
                                    <label class="switchdevmd">
                                        <input type="checkbox" [(ngModel)]="model.Checked" [ngModelOptions]="{standalone: true}"
                                        id="Checked" name="Checked" #Checked="ngModel" [disabled]="isView" />
                                        <span class="sliderdevmd rounddevmd"></span>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="btnAction_Project PopupAddsubProject">
                            <button *ngIf="targetType == 9" type="button" class="btn btn-outline-primary float-left" (click)="import()">
                                <i class="ft-upload"></i> Upload excel
                            </button>
                            <button *ngIf="((targetType == 2 && permission.thutuc_phapli.Export) || (targetType == 3 && permission.hoso_thietke.Export) || (targetType == 5 && permission.chutruong_chidao.Export)) && !exporting" type="button" class="btn btn-outline-primary float-left" (click)="export()">
                                <i class="ft-download"></i> Xuất báo cáo
                            </button>
                            <button *ngIf="((targetType == 2 && permission.thutuc_phapli.Export) || (targetType == 3 && permission.hoso_thietke.Export) || (targetType == 5 && permission.chutruong_chidao.Export)) && exporting" type="button" class="btn btn-outline-primary float-left">
                                <i class="fa fa-spin fa-circle-o-notch"></i> Xuất báo cáo
                            </button>
                            <button type="submit" (click)="getValue()" class="btn btn-primary mr-2"
                             *ngIf="!model.Id && !isView && (targetType == 1 || (targetType == 2 && permission.thutuc_phapli.Create) || (targetType == 3 && permission.hoso_thietke.Create) || (targetType == 4 && permission.matbang.Create) || (targetType == 5 && permission.chutruong_chidao.Create) || (targetType == 6 && permission.file_dinhkem_duan.Create) || targetType == 7 || targetType == 8 || targetType == 9)">
                             <i class="ft-check-square mr-1"></i> Thêm mới</button>
                            <button type="submit" (click)="getValue()" class="btn btn-primary mr-2" 
                            *ngIf="model.Id  && !isView && (targetType == 1 || (targetType == 2 && permission.thutuc_phapli.Update) || (targetType == 3 && permission.hoso_thietke.Update) || (targetType == 4 && permission.matbang.Update) || (targetType == 5 && permission.chutruong_chidao.Update) || (targetType == 6 && permission.file_dinhkem_duan.Update) || targetType == 7 || targetType == 8 || targetType == 9)">
                            <i class="ft-check-square mr-1"></i> Lưu thông tin</button>
                            <button type="button" (click)="[f.resetForm(),resetForm()]" class="btn btn-warning mr-2"><i
                                    class="ft-refresh-cw mr-1"></i> Xóa dữ liệu</button>
                        </div>
                        <div class="textFilterProj mt-1" *ngIf="targetType == 2 || targetType == 3 || targetType == 5">
                            <div class="inputTextFilter">
                                <input type="text" placeholder="Tìm kiếm" name="searchText"
                                    [(ngModel)]="txtSearch" (keyup.enter)="onFilter()"
                                    class="inputSearchText" />
                                <button type="button" class="iconbtnSearch"><i class="fa fa-search" aria-hidden="true" (click)="onFilter()"></i></button>
                            </div>
                        </div>
                        <div class="areaAdd_Package pl-0">
                            <table class="table table-striped tableListProj tbl-in-dialog">
                                <thead>
                                    <tr>
                                        <th scope="col" class="stt-role">
                                            Stt
                                        </th>

                                        <th *ngIf="targetType == 1 || targetType == 6 || targetType == 7 || targetType == 8">Tên thư mục</th>
                                        <th *ngIf="targetType == 2">Tên thủ tục</th>
                                        <th *ngIf="targetType == 3">Tên hồ sơ</th>
                                        <th *ngIf="targetType == 4">Tên mặt bằng</th>
                                        <th *ngIf="targetType == 5">Tên chủ trương - chỉ đạo</th>
                                        <th *ngIf="targetType == 9">Tên công việc</th>
                                        <th *ngIf="targetType == 4">Tỷ lệ</th>
                                        <th *ngIf="targetType == 1 || targetType == 6 || targetType == 7 || targetType == 8" class="text-center">Ngày cập nhật</th>

                                        <th *ngIf="targetType == 1 || targetType == 6 || targetType == 7 || targetType == 8 || targetType == 9">Ghi chú</th>
                                        <th *ngIf="targetType == 2 || targetType == 3 || targetType == 4 || targetType == 5">Nội dung</th>

                                        <th class="text-center" *ngIf="targetType != 9">Tải file</th>

                                        <th *ngIf="targetType == 2 || targetType == 4">Trạng thái</th>
                                        <th *ngIf="targetType == 1 || targetType == 2 || targetType == 3 || targetType == 4 || targetType == 7" class="text-center">Lưu trữ</th>

                                        <th *ngIf="targetType == 2" class="text-center">Ngày hiệu lực văn bản</th>
                                        <th *ngIf="targetType == 2" class="text-center">Ngày hoàn thành</th>
                                        <th *ngIf="targetType == 3" class="text-center">Ngày hiệu chỉnh</th>
                                        <th *ngIf="targetType == 9" class="text-center">Ngày bắt đầu</th>
                                        <th *ngIf="targetType == 9" class="text-center">Ngày kết thúc</th>
                                        <th *ngIf="targetType == 9">Trạng thái</th>
                                        <th *ngIf="targetType == 1">SAP</th>
                                        <th scope="col" class="action-column text-center">Thao tác</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of attactmentFolders; let i = index" role="row">
                                        <td style=" text-align: center; font-weight: bold;"
                                            class="stt-role">{{ (paging.page - 1) * paging.page_size + i + 1 }}
                                        </td>
                                        <td>
                                            <a *ngIf="!item?.IsSap" href="{{ domainFileUpload + (item.attactments.length ? item.attactments[0].Name : item.Name)}}" target="_blank">{{ item.Name }}</a>
                                            <button *ngIf="item?.IsSap" type="button" class="btn" (click)="onGetUrlFileFromSap(item, item.attactments[0] || [])">{{ item.Name }}</button>
                                        </td>
                                        <td *ngIf="targetType == 4">{{ item.Ratio }}</td>
                                        <td *ngIf="targetType == 1 || targetType == 6 || targetType == 7 || targetType == 8" class="text-center">{{ item.CorrectionDate | date: 'dd/MM/yyyy'}}</td>
                                        <td>{{ item.Note }}</td>
                                        <td class="text-center" *ngIf="targetType != 9">
                                            <ng-template #popContent>
                                                <div class="list-attactment" style="max-height: 300px; overflow-y: scroll;">
                                                    <span class="d-block mb-1" *ngFor="let attactment of attactments; let idx = index" title="{{ attactment.Name }}">
                                                        <!-- <a href="javascript:void(0)" (click)="downloadFileViaUrl(attactment.Url, attactment.Name)">{{ attactment.Name }}</a> -->
                                                        <a *ngIf="!item?.IsSap" href="{{ domainFileUpload + attactment.Name }}" target="_blank">{{ attactment.Name }}</a>
                                                        <button *ngIf="item?.IsSap" type="button" class="btn" (click)="onGetUrlFileFromSap(item, attactment)">{{ attactment.Name }}</button>
                                                    </span>
                                                </div>
                                            </ng-template>
                                            <ng-template #popTitle>
                                                <span>{{ folderName }}</span>
                                                <span *ngIf="item.attactments.length > 0 && item.IsSap != true" title="tải tất cả tài liệu" style="float: right; cursor: pointer; color: blue;" class="ml-1" (click)="downloadAll(item.attactments)">
                                                    <i class="ft-download" aria-hidden="true"></i>
                                                </span>
                                            </ng-template>
                                            <!-- <i class="ft-download"></i> -->
                                            <i class="fa fa-cloud-download iconDowloadPopup" aria-hidden="true" (click)="showData(item)" [ngbPopover]="popContent" [popoverTitle]="popTitle"></i>
                                        </td>

                                        <td *ngIf="targetType == 2 || targetType == 4" class="statusLegal" >
                                            <div class="input-group mb-0">
                                                {{ returnCompleteStatus(item.CompleteStatus) }}
                                            </div>
                                        </td>

                                        <td *ngIf="targetType == 1 || targetType == 2 || targetType == 3 || targetType == 4 || targetType == 7" class="tdSaveLegal text-center">
                                            <!-- {{item.Checked}} -->
                                            <!-- <label class="switchdevmd">
                                                <input type="checkbox" [checked]="item.Checked" *ngIf="item.Checked == true ? 'checked' : 'notchecked'" />
                                                <span class="sliderdevmd rounddevmd"></span>
                                            </label> -->
                                            <i *ngIf="item.Checked" class="ft-check"></i>
                                        </td>

                                        <td *ngIf="targetType == 2 || targetType == 3" class="dateEffective" class="text-center">
                                            {{ item.CorrectionDate | date: 'dd/MM/yyyy'}}
                                        </td>

                                        <td *ngIf="targetType == 2" class="tdEndDateReal" class="text-center">
                                            {{ item.EndDateReal | date: 'dd/MM/yyyy'}}
                                        </td>
                                        <td *ngIf="targetType == 9" class="tdEndDateReal" class="text-center">
                                            {{ item.StartDate | date: 'dd/MM/yyyy'}}
                                        </td>
                                        <td *ngIf="targetType == 9" class="tdEndDateReal" class="text-center">
                                            {{ item.EndDate | date: 'dd/MM/yyyy'}}
                                        </td>
                                        <td *ngIf="targetType == 9" class="statusLegal" >
                                            <div class="input-group mb-0">
                                                {{ item.CompleteStatus | bidpackageJobStatusPipe }}
                                            </div>
                                        </td>
                                        <td *ngIf="targetType == 1" class="text-center">
                                            <i class="fa fa-check" *ngIf="item.IsSap"></i>
                                        </td>
                                        <td class="tdaction_project actionPopupItem">
                                            <a *ngIf="targetType == 1 && item?.IsSap != 1" href="javascript:void(0)" class="iconEditProject mr-1" 
                                            ngbTooltip="Xem/Sửa thư mục file đính kèm {{ item.Name }}" (click)="edit(item)"><i class="fa fa-pencil-square" aria-hidden="true"></i></a>
                                            <a *ngIf="targetType == 2" href="javascript:void(0)" class="iconEditProject mr-1" 
                                            ngbTooltip="Xem/Sửa thủ tục pháp lý {{ item.Name }}" (click)="edit(item)"><i class="fa fa-pencil-square" aria-hidden="true"></i></a>
                                            <a *ngIf="targetType == 3" href="javascript:void(0)" class="iconEditProject mr-1" 
                                            ngbTooltip="Xem/Sửa hồ sơ thiết kế {{ item.Name }}" (click)="edit(item)"><i class="fa fa-pencil-square" aria-hidden="true"></i></a>
                                            <a *ngIf="targetType == 4" href="javascript:void(0)" class="iconEditProject mr-1" 
                                            ngbTooltip="Xem/Sửa mặt bằng {{ item.Name }}" (click)="edit(item)"><i class="fa fa-pencil-square" aria-hidden="true"></i></a>
                                            <a *ngIf="targetType == 5" href="javascript:void(0)" class="iconEditProject mr-1" 
                                            ngbTooltip="Xem/Sửa chủ trương - chỉ đạo {{ item.Name }}" (click)="edit(item)"><i class="fa fa-pencil-square" aria-hidden="true"></i></a>
                                            <a *ngIf="targetType == 6" href="javascript:void(0)" class="iconEditProject mr-1" 
                                            ngbTooltip="Xem/Sửa thư mục file đính kèm dự án {{ item.Name }}" (click)="edit(item)"><i class="fa fa-pencil-square" aria-hidden="true"></i></a>
                                            <a *ngIf="targetType == 7" href="javascript:void(0)" class="iconEditProject mr-1" 
                                            ngbTooltip="Xem/Sửa thư mục file đính kèm phiếu đề nghị {{ item.Name }}" (click)="edit(item)"><i class="fa fa-pencil-square" aria-hidden="true"></i></a>
                                            <a *ngIf="targetType == 8" href="javascript:void(0)" class="iconEditProject mr-1" 
                                            ngbTooltip="Xem/Sửa thư mục file đính kèm gói thầu {{ item.Name }}" (click)="edit(item)"><i class="fa fa-pencil-square" aria-hidden="true"></i></a>
                                            <a *ngIf="targetType == 9" href="javascript:void(0)" class="iconEditProject mr-1" 
                                            ngbTooltip="Xem/Sửa công việc gói thầu {{ item.Name }}" (click)="edit(item)"><i class="fa fa-pencil-square" aria-hidden="true"></i></a>
                                            
                                            <a *ngIf="targetType == 1 && item?.IsSap != 1" href="javascript:void(0)" class="DeleteProIcon"
                                                ngbTooltip="Xóa thư mục file đính kèm {{ item.Name }}" (click)="delete(item)"><i
                                                    class="fa fa-trash" aria-hidden="true"></i></a>
                                            <a *ngIf="targetType == 2 && permission.thutuc_phapli.Delete" href="javascript:void(0)" class="DeleteProIcon"
                                                ngbTooltip="Xóa thủ tục pháp lý {{ item.Name }}" (click)="delete(item)"><i
                                                    class="fa fa-trash" aria-hidden="true"></i></a>
                                            <a *ngIf="targetType == 3 && permission.hoso_thietke.Delete" href="javascript:void(0)" class="DeleteProIcon"
                                            ngbTooltip="Xóa hồ sơ thiết kế {{ item.Name }}" (click)="delete(item)"><i
                                                class="fa fa-trash" aria-hidden="true"></i></a>
                                            <a *ngIf="targetType == 4 && permission.matbang.Delete" href="javascript:void(0)" class="DeleteProIcon"
                                            ngbTooltip="Xóa mặt bằng {{ item.Name }}" (click)="delete(item)"><i
                                                class="fa fa-trash" aria-hidden="true"></i></a>
                                            <a *ngIf="targetType == 5 && permission.chutruong_chidao.Delete" href="javascript:void(0)" class="DeleteProIcon"
                                            ngbTooltip="Xóa chủ trương - chỉ đạo {{ item.Name }}" (click)="delete(item)"><i
                                                class="fa fa-trash" aria-hidden="true"></i></a>
                                            <a *ngIf="targetType == 6 && permission.file_dinhkem_duan.Delete" href="javascript:void(0)" class="DeleteProIcon"
                                            ngbTooltip="Xóa thư mục file đính kèm dự án {{ item.Name }}" (click)="delete(item)"><i
                                                class="fa fa-trash" aria-hidden="true"></i></a>
                                            <a *ngIf="targetType == 7" href="javascript:void(0)" class="DeleteProIcon"
                                            ngbTooltip="Xóa thư mục file đính kèm phiếu đề nghị {{ item.Name }}" (click)="delete(item)"><i
                                                class="fa fa-trash" aria-hidden="true"></i></a>
                                            <a *ngIf="targetType == 8" href="javascript:void(0)" class="DeleteProIcon"
                                            ngbTooltip="Xóa thư mục file đính kèm gói thầu {{ item.Name }}" (click)="delete(item)"><i
                                                class="fa fa-trash" aria-hidden="true"></i></a>
                                            <a *ngIf="targetType == 9" href="javascript:void(0)" class="DeleteProIcon"
                                            ngbTooltip="Xóa công việc gói thầu {{ item.Name }}" (click)="delete(item)"><i
                                                class="fa fa-trash" aria-hidden="true"></i></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <p *ngIf="!attactmentFolders.length" class="text-sm text-danger text-center">Không có dữ liệu.</p>
                            <div class="pagination_Project">
                                <p *ngIf="paging.item_count > 0" class="Count_paged">Hiển thị <span class="numpaged onepaged">{{ (paging.page - 1) *
                                    paging.page_size + 1 }}</span> <i class="fa fa-long-arrow-right" aria-hidden="true"></i> <span
                                    class="numpaged twopaged">{{ paging.item_count < paging.page * paging.page_size ? paging.item_count :
                                        paging.page * paging.page_size }}</span> <i class="fa fa-ellipsis-v" aria-hidden="true"></i> <span
                                            class="numpaged totalpaged">{{ paging.item_count }}</span> bản ghi</p>
                                <ngb-pagination [(collectionSize)]="paging.item_count" [(page)]="paging.page" [(pageSize)]="paging.page_size" [maxSize]="5" (pageChange)="getByPage()" aria-label="Default pagination"></ngb-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="[modal.close(true), f.resetForm()]">Đóng</button>
    </div>
</form>