import { AccessKey } from './../../../shared/common/app.constants';
import {
  Component, OnInit, ViewChild, OnDestroy,
  ElementRef, AfterViewInit, ChangeDetectorRef, HostListener
} from '@angular/core';
import { ROUTES } from './vertical-menu-routes.config';
import { HROUTES } from '../horizontal-menu/navigation-routes.config';

import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { customAnimations } from '../animations/custom-animations';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ConfigService } from '../../../shared/services/config.service';
import { Subscription } from 'rxjs';
import { LayoutService } from '../../../shared/services/layout.service';
import { AppDomain, StorageOption } from 'app/shared/common/app.constants';
import { StorageService } from 'app/shared/services/storage.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './vertical-menu.component.html',
  styleUrls: ['./vertical-menu.component.scss'],
  animations: customAnimations
})
export class VerticalMenuComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('toggleIcon') toggleIcon: ElementRef;
  public menuItems: any[];
  level = 0;
  logoUrl = 'assets/img/logo.png';
  public config: any = {};
  protected innerWidth: any;
  layoutSub: Subscription;
  configSub: Subscription;
  perfectScrollbarEnable = true;
  collapseSidebar = false;
  resizeTimeout;
  avatar: string;
  fullName: string;
  AppDomain = AppDomain;

  constructor(
    private router: Router,
    private layoutService: LayoutService,
    private configService: ConfigService,
    private cdr: ChangeDetectorRef,
    private deviceService: DeviceDetectorService,
    private activeRoute: ActivatedRoute,
    private readonly storeService: StorageService
  ) {
    this.storeService.initialize(StorageOption.LocalStorage);
    this.config = this.configService.templateConf;
    this.innerWidth = window.innerWidth;
    this.isTouchDevice();
    this.avatar = localStorage.getItem('avatar') != 'null' && localStorage.getItem('avatar') != '' && localStorage.getItem('avatar') != 'undefined' ? localStorage.getItem('avatar') : undefined;

    this.fullName = localStorage.getItem('fullName');

  }


  ngOnInit() {

    const lstMenuConfig = localStorage.getItem('menu');


    this.menuItems = this.getConfigMenu(JSON.parse(lstMenuConfig));
    //this.menuItems = this.checkProjectDetail(ROUTES);
    // this.router.events.subscribe((e) => {
    //   if (e instanceof NavigationEnd) {
    //     const mergedParams = this.mergeRouteParams(this.router);
    //     console.log(mergedParams);
    //   }
    // });

  }

  getConfigMenu(lst) {
    let lstMenu = [];

    for (let i = 0; i < lst.length; i++) {
      if (lst[i]['Code'] != AccessKey.CONTRACT &&
      lst[i]['Code'] != AccessKey.PID_PACKAGE &&
      lst[i]['Code'] != AccessKey.PAYMENT &&
      lst[i]['Code'] != AccessKey.STORAGE &&
      //lst[i]['Code'] != AccessKey.REPORT_LIST &&
      lst[i]['Code'] != AccessKey.CATEGORY_CONTRACT &&
      lst[i]['Code'] != AccessKey.CATEGORY_PLAN &&
      lst[i]['Code'] != AccessKey.MONITORING_LIST &&
      lst[i]['Code'] != AccessKey.SUPERVISION_CONTRACT) {
        const item = new Object();

        item['path'] = lst[i]['listMenus'].length ? '' : lst[i]['Url'];
        item['title'] = lst[i]['Name'];
        item['icon'] = lst[i]['Icon'] ? lst[i]['Icon'] : 'ft-align-left';
        item['class'] = lst[i]['listMenus'].length ? 'dropdown nav-item has-sub' : 'dropdown nav-item';
        item['isExternalLink'] = false;
        item['badge'] = '';
        item['badgeClass'] = '';
        item['submenu'] = lst[i]['listMenus'].length ? this.getSubMenu(lst[i]['listMenus'], null) : [];
        item['code'] = lst[i]['Code'] || '';

        lstMenu.push(item);
      }
    }

    const curentUrl = this.router.url;


    if (curentUrl.indexOf("/project/detail") != -1 || curentUrl.indexOf("/project/add") != -1) {
      const indexParram = curentUrl.lastIndexOf('/');
      const projectId = curentUrl.slice(indexParram + 1);
      // console.log("projectId: " + projectId);
      if (projectId && projectId != "0") {
        localStorage.setItem("ProjectIdDetail", projectId);
      }
    }

    const curentProjectId = Number(localStorage.getItem("ProjectIdDetail"));
    if (curentProjectId != undefined && curentProjectId > 0) {
      const isExistStorage = lst.find(s => s.Code == AccessKey.STORAGE);
      if (isExistStorage) {
        const itemStorage = new Object();

        itemStorage['path'] = isExistStorage['listMenus'].length ? '' : isExistStorage['Url'];
        itemStorage['title'] = isExistStorage['Name'];
        itemStorage['icon'] = isExistStorage['Icon'] ? isExistStorage['Icon'] : 'ft-align-left';
        itemStorage['class'] = isExistStorage['listMenus'].length ? 'dropdown nav-item has-sub' : 'dropdown nav-item';
        itemStorage['isExternalLink'] = false;
        itemStorage['badge'] = '';
        itemStorage['badgeClass'] = '';
        itemStorage['submenu'] = isExistStorage['listMenus'].length ? this.getSubMenu(isExistStorage['listMenus'], curentProjectId) : [];
        itemStorage['code'] = isExistStorage['Code'] || '';

        lstMenu = this.insertItemInArr(lstMenu,2, itemStorage);
      }

      const isExistPay = lst.find(s => s.Code == 'QLTT');
      if (isExistPay) {
        const itemPayment = new Object();

        itemPayment['path'] = isExistPay['listMenus'].length ? '' : isExistPay['Url'];
        itemPayment['title'] = isExistPay['Name'];
        itemPayment['icon'] = isExistPay['Icon'] ? isExistPay['Icon'] : 'ft-align-left';
        itemPayment['class'] = isExistPay['listMenus'].length ? 'dropdown nav-item has-sub' : 'dropdown nav-item';
        itemPayment['isExternalLink'] = false;
        itemPayment['badge'] = '';
        itemPayment['badgeClass'] = '';
        itemPayment['submenu'] = isExistPay['listMenus'].length ? this.getSubMenu(isExistPay['listMenus'], null) : [];
        itemPayment['code'] = isExistPay['Code'] || '';

        lstMenu = this.insertItemInArr(lstMenu,2, itemPayment);
      }

      const isExistMonitoringContract = lst.find(s => s.Code == AccessKey.MONITORING_LIST);
      if (isExistMonitoringContract) {
        const itemMonitoringContract = new Object();

        itemMonitoringContract['path'] = '';
        itemMonitoringContract['title'] = isExistMonitoringContract.Name || 'Giám sát';
        itemMonitoringContract['icon'] = isExistMonitoringContract['Icon'] ? isExistMonitoringContract['Icon'] : 'ft-align-left';
        itemMonitoringContract['class'] = 'dropdown nav-item has-sub';
        itemMonitoringContract['isExternalLink'] = false;
        itemMonitoringContract['badge'] = '';
        itemMonitoringContract['badgeClass'] = '';
        itemMonitoringContract['submenu'] = isExistMonitoringContract['listMenus'].length ? this.getSubMenu(isExistMonitoringContract['listMenus'], curentProjectId) : [];
        itemMonitoringContract['code'] = isExistMonitoringContract['Code'] || AccessKey.MONITORING_LIST;
        lstMenu = this.insertItemInArr(lstMenu, 2, itemMonitoringContract);
      }

      const isExistCategoryPlan = lst.find(s => s.Code == AccessKey.CATEGORY_PLAN);
      if (isExistCategoryPlan) {
        const itemCategoryPlan = new Object();

        itemCategoryPlan['path'] = 'plan/category-plan/' + curentProjectId;
        itemCategoryPlan['title'] = isExistCategoryPlan.Name || 'Danh sách kế hoạch thi công';
        itemCategoryPlan['icon'] = isExistCategoryPlan.Icon || 'ft-align-left';
        itemCategoryPlan['class'] = 'dropdown nav-item';
        itemCategoryPlan['isExternalLink'] = false;
        itemCategoryPlan['badge'] = '';
        itemCategoryPlan['badgeClass'] = '';
        itemCategoryPlan['submenu'] = [];
        itemCategoryPlan['code'] = isExistCategoryPlan['Code'] || AccessKey.CATEGORY_PLAN;

        lstMenu = this.insertItemInArr(lstMenu,2, itemCategoryPlan);
      }

      const isExistCategoryContract = lst.find(s => s.Code == AccessKey.CATEGORY_CONTRACT);
      if (isExistCategoryContract) {
        const itemCategoryContract = new Object();

        itemCategoryContract['path'] = 'contract/category-contract/' + curentProjectId;
        itemCategoryContract['title'] = isExistCategoryContract.Name || 'Danh sách khối lượng công việc';
        itemCategoryContract['icon'] = isExistCategoryContract.Icon || 'ft-align-left';
        itemCategoryContract['class'] = 'dropdown nav-item';
        itemCategoryContract['isExternalLink'] = false;
        itemCategoryContract['badge'] = '';
        itemCategoryContract['badgeClass'] = '';
        itemCategoryContract['submenu'] = [];
        itemCategoryContract['code'] = isExistCategoryContract['Code'] || AccessKey.CATEGORY_CONTRACT;

        lstMenu = this.insertItemInArr(lstMenu,2, itemCategoryContract);
      }

      const isExistSupervision = lst.find(s => s.Code == AccessKey.SUPERVISION_CONTRACT);
      if (isExistSupervision) {
        const itemSupervision = new Object();

        itemSupervision['path'] = 'contract/decentralization-of-supervision/' + curentProjectId;
        itemSupervision['title'] = isExistSupervision.Name || 'Phân quyền giám sát';
        itemSupervision['icon'] = isExistSupervision.Icon || 'ft-align-left';
        itemSupervision['class'] = 'dropdown nav-item';
        itemSupervision['isExternalLink'] = false;
        itemSupervision['badge'] = '';
        itemSupervision['badgeClass'] = '';
        itemSupervision['submenu'] = [];
        itemSupervision['code'] = isExistSupervision['Code'] || AccessKey.SUPERVISION_CONTRACT;

        lstMenu = this.insertItemInArr(lstMenu, 2, itemSupervision);
      }

      const isExistContract = lst.find(s => s.Code == 'QLCNHD');

      if(isExistContract) {
        const itemContract = new Object();

        itemContract['path'] = 'contract/list/' + curentProjectId;
        itemContract['title'] = isExistContract.Name || 'Danh sách hợp đồng';
        itemContract['icon'] = isExistContract.Icon || 'ft-align-left';
        itemContract['class'] = 'dropdown nav-item';
        itemContract['isExternalLink'] = false;
        itemContract['badge'] = '';
        itemContract['badgeClass'] = '';
        itemContract['submenu'] = [];
        itemContract['code'] = isExistContract['Code'] || AccessKey.CONTRACT;

        lstMenu = this.insertItemInArr(lstMenu,2, itemContract);
      }

      const isExistPidPackage = lst.find(s => s.Code == 'QLCNGT');

      if (isExistPidPackage) {
        const itemBidPackage = new Object();

        itemBidPackage['path'] = 'pid-package/list/' + curentProjectId;
        itemBidPackage['title'] = isExistPidPackage.Name || 'Danh sách gói thầu';
        itemBidPackage['icon'] = isExistPidPackage.Icon || 'ft-align-left';
        itemBidPackage['class'] = 'dropdown nav-item';
        itemBidPackage['isExternalLink'] = false;
        itemBidPackage['badge'] = '';
        itemBidPackage['badgeClass'] = '';
        itemBidPackage['submenu'] = [];
        itemBidPackage['code'] = isExistPidPackage['Code'] || AccessKey.PID_PACKAGE;

        lstMenu = this.insertItemInArr(lstMenu,2, itemBidPackage);
      }
    }

    if (curentUrl == "/project/list" || curentUrl == "/") {
      localStorage.removeItem("ProjectIdDetail");

      
      
      
      
      
      
      
      


      const indexTT = lstMenu.findIndex(s => s.code == AccessKey.PAYMENT);
      if (indexTT >= 0) {
        lstMenu.splice(indexTT , 1);
      }

      const indexGS = lstMenu.findIndex(s => s.code == AccessKey.MONITORING_LIST);
      if (indexGS >= 0) {
        lstMenu.splice(indexGS , 1);
      }

      const indexPQGS = lstMenu.findIndex(s => s.code == AccessKey.SUPERVISION_CONTRACT);
      if (indexPQGS >= 0) {
        lstMenu.splice(indexPQGS , 1);
      }

      const indexKHTC = lstMenu.findIndex(s => s.code == AccessKey.CATEGORY_PLAN);
      if (indexKHTC >= 0) {
        lstMenu.splice(indexKHTC , 1);
      }

      const indexKLTC = lstMenu.findIndex(s => s.code == AccessKey.CATEGORY_CONTRACT);
      if (indexKLTC >= 0) {
        lstMenu.splice(indexKLTC , 1);
      }

      const indexHĐ = lstMenu.findIndex(s => s.code == AccessKey.CONTRACT);
      if (indexHĐ >= 0) {
        lstMenu.splice(indexHĐ , 1);
      }

      const indexGT = lstMenu.findIndex(s => s.code == AccessKey.PID_PACKAGE);
      if (indexGT >= 0) {
        lstMenu.splice(indexGT , 1);
      }

      const indexLuuTru = lstMenu.findIndex(s => s.code == AccessKey.STORAGE);
      if (indexLuuTru >= 0) {
        lstMenu.splice(indexLuuTru , 1);
      }

      lstMenu = [...lstMenu];
    }


    return JSON.parse(JSON.stringify(lstMenu));
  }

  checkProjectDetail(lstMenu) {
    const curentUrl = this.router.url;


    if (curentUrl.indexOf("/project/detail") != -1 || curentUrl.indexOf("/project/add") != -1) {
      const indexParram = curentUrl.lastIndexOf('/');
      const projectId = curentUrl.slice(indexParram + 1);
      // console.log("projectId: " + projectId);
      if (projectId && projectId != "0") {
        localStorage.setItem("ProjectIdDetail", projectId);
      }
    }

    const curentProjectId = Number(localStorage.getItem("ProjectIdDetail"));
    // console.log("curentProjectId: " + curentProjectId);
    if (curentProjectId != undefined) {
      // const indexParram = curentUrl.lastIndexOf('/');
      // const projectId = curentUrl.slice(indexParram + 1);
      // localStorage.setItem("ProjectIdDetail", projectId);
      //console.log("ProjectId: " + projectId)
      const itemContract = new Object();

      itemContract['path'] = 'contract/list/' + curentProjectId;
      itemContract['title'] = 'Danh sách hợp đồng';
      itemContract['icon'] = 'ft-align-left';
      itemContract['class'] = 'dropdown nav-item';
      itemContract['isExternalLink'] = false;
      itemContract['badge'] = '';
      itemContract['badgeClass'] = '';
      itemContract['submenu'] = [];

      lstMenu = this.insertItemInArr(lstMenu,2, itemContract);

      const itemBidPackage = new Object();

      itemBidPackage['path'] = 'pid-package/list/' + curentProjectId;
      itemBidPackage['title'] = 'Danh sách gói thầu';
      itemBidPackage['icon'] = 'ft-align-left';
      itemBidPackage['class'] = 'dropdown nav-item';
      itemBidPackage['isExternalLink'] = false;
      itemBidPackage['badge'] = '';
      itemBidPackage['badgeClass'] = '';
      itemBidPackage['submenu'] = [];

      lstMenu = this.insertItemInArr(lstMenu,2, itemBidPackage);
    }

    const departmentTypeStore = localStorage.getItem("departmentType") || null;

    if (departmentTypeStore == "1") {
      const indexHT = lstMenu.findIndex(s => s.title == "Hệ thống");

      if (indexHT >= 0) {
        lstMenu.splice(indexHT , 1);
      }
    }

    if (curentUrl == "/project/list" || curentUrl == "/") {
      localStorage.removeItem("ProjectIdDetail");

      const indexHĐ = lstMenu.findIndex(s => s.title == "Danh sách hợp đồng");
      const indexGT = lstMenu.findIndex(s => s.title == "Danh sách gói thầu");

      if (indexHĐ >= 0) {
        lstMenu.splice(indexHĐ , 1);
        lstMenu.splice(indexGT , 1);
      }

      [...lstMenu];
    }

    return lstMenu;
  }

  mergeRouteParams(router: Router): { [key: string]: string } {
    let params = {};
    let route = router.routerState.snapshot.root;
    do {
      params = { ...params, ...route.params };
      route = route.firstChild;
    } while (route);

    return params;
  }

  insertItemInArr(arr, index, newItem) {
    return [
      // part of the array before the specified index
      ...arr.slice(0, index),
      // inserted item
      newItem,
      // part of the array after the specified index
      ...arr.slice(index)
    ]
  }

  getSubMenu(lst, projectId) {
    const lstMenu = [];

    for (let i = 0; i < lst.length; i++) {
      const item = new Object();

      item['path'] = lst[i]['listMenus'].length ? '' : lst[i]['Url'] + (lst[i]['Url'].substr(lst[i]['Url'].length - 1) == "/" ? '' : "/") + (projectId || '');
      item['title'] = lst[i]['Name'];
      item['icon'] = lst[i]['Icon'] ? lst[i]['Icon'] + ' has-sub' : 'ft-align-left  has-sub';
      item['class'] = lst[i]['listMenus'].length ? 'dropdown nav-item has-sub' : 'dropdown nav-item';
      item['isExternalLink'] = false;
      item['badge'] = '';
      item['badgeClass'] = '';
      item['submenu'] = [];

      lstMenu.push(item);
    }

    return lstMenu;
  }


  ngAfterViewInit() {

    this.configSub = this.configService.templateConf$.subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
      }
      this.loadLayout();
      this.cdr.markForCheck();

    });

    this.layoutSub = this.layoutService.overlaySidebarToggle$.subscribe(
      collapse => {
        if (this.config.layout.menuPosition === 'Side') {
          this.collapseSidebar = collapse;
        }
      });

  }


  @HostListener('window:resize', ['$event'])
  onWindowResize(event) {
      if (this.resizeTimeout) {
          clearTimeout(this.resizeTimeout);
      }
      this.resizeTimeout = setTimeout((() => {
        this.innerWidth = event.target.innerWidth;
          this.loadLayout();
      }).bind(this), 500);
  }

  loadLayout() {

    // if (this.config.layout.menuPosition === 'Top') { // Horizontal Menu
    //   if (this.innerWidth < 1200) { // Screen size < 1200
    //     this.menuItems = HROUTES;
    //   }
    // } else if (this.config.layout.menuPosition === 'Side') { // Vertical Menu{
    //   this.menuItems = ROUTES;
    // }




    if (this.config.layout.sidebar.backgroundColor === 'white') {
      this.logoUrl = 'assets/img/logo.png';
    } else {
      this.logoUrl = 'assets/img/logo.png';
    }

    if (this.config.layout.sidebar.collapsed) {
      this.collapseSidebar = true;
    } else {
      this.collapseSidebar = false;
    }
  }

  toggleSidebar() {
    const conf = this.config;
    conf.layout.sidebar.collapsed = !this.config.layout.sidebar.collapsed;
    this.configService.applyTemplateConfigChange({ layout: conf.layout });

    setTimeout(() => {
      this.fireRefreshEventOnWindow();
    }, 300);
  }

  fireRefreshEventOnWindow = function () {
    const evt = document.createEvent('HTMLEvents');
    evt.initEvent('resize', true, false);
    window.dispatchEvent(evt);
  };

  CloseSidebar() {
    this.layoutService.toggleSidebarSmallScreen(false);
  }

  isTouchDevice() {

    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();

    if (isMobile || isTablet) {
      this.perfectScrollbarEnable = false;
    } else {
      this.perfectScrollbarEnable = true;
    }

  }


  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
    if (this.configSub) {
      this.configSub.unsubscribe();
    }

  }

}
