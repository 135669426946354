import { Component, OnInit, Input, Output, ViewChild, NgModule } from '@angular/core';
import { NgbDatepickerI18n, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { CustomDatepickerI18n, I18n } from "app/shared/services/date-picker-i18n";
import { DbAttactmentFolder } from 'app/viewModels/attactment/db-attactment';
import { ApiConstant } from 'app/shared/common/api.constants';
import { AttactmentFolderService } from "app/services/attactment-folder.service";
import { CommonService } from "app/services/common.service";
import { domainFileUpload, statusProcedure, Pattern, trangThaiHoSoMatBang, TypeAttactmentFolder, domainApi, TokenEnum, BidpackageJobStatus, AccessKey } from "app/shared/common/app.constants";
import { AttactmentCommonComponent } from 'app/shared/components/attactment/attactment.component';
import { Paging, QueryFilter } from "app/viewModels/base-query";
import { ConfirmComponent } from 'app/shared/components/confirm/confirm.component';
// import { SharedModule } from "app/shared/shared.module";
import { LoaihinhService } from "app/services/loaihinh.service";
import { DownloadService } from "app/shared/services/download.service";
import { InterceptorService } from 'ng2-interceptors';
import { RequestOptions, ResponseContentType, Headers } from '@angular/http';
import { StorageService } from 'app/shared/services/storage.service';
import { getFileNameFromResponseContentDisposition, saveFile } from 'app/shared/services/file-download-helper';
import { UploadAttactmentFolderComponent } from 'app/shared/components/upload-attactment-folder/upload-attactment-folder.component';
import { PhongbanService } from "app/services/phongban.service";
import { UserService } from "app/services/user.service";

@Component({
    selector: 'app-attactment-folder-common',
    templateUrl: './attactment-folder.component.html',
    styleUrls: [],
    providers: [
        I18n,
        { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
    ]
})

// @NgModule({
//     imports: [
//         SharedModule,
//     ],
//     providers: []
// })

export class AttactmentFolderCommonComponent implements OnInit {
    @ViewChild('attactment') attactment!: AttactmentCommonComponent;
    @ViewChild('f') public form: NgForm;
    @Input() model: DbAttactmentFolder;
    @Input() isView: boolean;
    @Input() title: string;
    @Input() targetId: number;
    @Input() targetType: number;
    @Input() proId: number;

    completeStatus = [];
    addCustomUser = (term) => ({ id: term, name: term });
    onlyTextPattern = Pattern.ONLY_TEXT;
    textAndNumberPattern = Pattern.KEY_PROJECT;
    phonePattern = Pattern.PHONE;

    Action: any;
    submitted = false;
    paging = new Paging;

    attactmentFolders = [];
    domainFileUpload = domainFileUpload;

    attactments = [];
    folderName = "";
    groupDesignFile = [];
    exporting;

    userMappings = [];
    departments = [];
    bidpackageJobStatus = BidpackageJobStatus;

    txtSearch: string;

    permission = {
        thutuc_phapli: this.commonService.CheckAccessKeyRole(AccessKey.THUTUC_PHAPLI),
        hoso_thietke: this.commonService.CheckAccessKeyRole(AccessKey.HOSO_THIETKE),
        matbang: this.commonService.CheckAccessKeyRole(AccessKey.MATBANG),
        chutruong_chidao: this.commonService.CheckAccessKeyRole(AccessKey.CHUTRUONG_CHIDAO),
        file_dinhkem_duan: this.commonService.CheckAccessKeyRole(AccessKey.THUMUC_FILE_DINHKEM_DUAN)
    };

    constructor(
        public modal: NgbActiveModal,
        private toast: ToastrService,
        private httpClient: HttpClient,
        private commonService: CommonService,
        private modalService: NgbModal,
        private loaihinhService: LoaihinhService,
        private downloadService: DownloadService,
        private httpDownload: InterceptorService,
        private storageService: StorageService,
        private phongbanService: PhongbanService,
        private userService: UserService,
        private attactmentFolderService: AttactmentFolderService) { }

    ngOnInit(): void {
        this.paging.page_size = 5;
        this.getByPage();
        this.getItemByCode(1);

        this.completeStatus = this.targetType == TypeAttactmentFolder.MATBANG ? trangThaiHoSoMatBang : statusProcedure;
        if (this.targetType == 9) {
            this.getUserMappings(this.proId);
        }
    }

    getValue() {
        if (this.targetType != 9) {
            this.model.attactments = this.attactment.getValue();
            this.attactment.clearValue();
        }
    }

    save() {
        this.submitted = true;
        let data = Object.assign({}, this.model);

        if (data.Id) {
            this.attactmentFolderService.update(data.Id, data).subscribe((res) => {
                this.submitted = false;
                if (res["meta"]["error_code"] == 200) {
                    this.toast.success(res["meta"]["error_message"]);
                    this.model = new DbAttactmentFolder();
                    this.form.resetForm();
                    this.getByPage();
                }
                else this.toast.error(res["meta"]["error_message"]);

            });
        }
        else {
            data.TargetId = this.targetId + '';
            data.TargetType = this.targetType;
            this.attactmentFolderService.create(data).subscribe((res) => {
                this.submitted = false;
                if (res["meta"]["error_code"] == 200) {
                    this.toast.success(res["meta"]["error_message"]);
                    this.model = new DbAttactmentFolder();
                    this.form.resetForm();
                    this.getByPage();
                }
                else this.toast.error(res["meta"]["error_message"]);

            });
        }
    }

    onFilter() {
        this.paging.page = 1;
        this.getByPage();
    }

    getByPage() {
        this.attactmentFolderService.getByPageAndTarget(this.paging, this.targetId, this.targetType, this.txtSearch, this.targetType == 1 ? this.targetId : 0).subscribe((res) => {
            if (res["meta"]["error_code"] == 200) {
                this.attactmentFolders = res["data"];
                this.paging.item_count = res["metadata"];
            }
        });
    }

    onGetUrlFileFromSap(itemFolder, itemFile) {
        const options = new RequestOptions({
            headers: new Headers({
                'Authorization': 'bearer ' + this.storageService.get(TokenEnum.ACCESS_TOKEN)
            }),
            responseType: ResponseContentType.Blob,
        });

        this.exporting = true;
        this.httpDownload.post(ApiConstant.GetUrlFileSap, {
            attactmentFolderId: itemFolder.Id,
            attactmentItemId: itemFile.Id
          }, options).subscribe(res => {
            if (res.blob().size == 0) {
                this.toast.error("Export file không thành công!");
            }
            else {
                saveFile(res.blob(), itemFile.Name);
                this.toast.success("Thành công!");
            }
        });
    }

    delete(model: DbAttactmentFolder) {
        let str = "";
        switch (this.targetType) {
            case TypeAttactmentFolder.THUMUC_FILE_DINHKEM_HOPDONG:
                str = "thư mục file đính kèm hợp đồng";
                break;
            case TypeAttactmentFolder.THUTUC_PHAPLI:
                str = "thủ tục pháp lí";
                break;
            case TypeAttactmentFolder.HOSO_THIETKE:
                str = "hồ sơ thiết kế";
                break;
            case TypeAttactmentFolder.MATBANG:
                str = "mặt bằng";
                break;
            case TypeAttactmentFolder.CHUTRUONG_CHIDAO:
                str = "chủ trương chỉ đạo";
                break;
            case TypeAttactmentFolder.THUMUC_FILE_DINHKEM_DUAN:
                str = "thư mục file đính kèm dự án";
                break;
            case TypeAttactmentFolder.THUMUC_FILE_DINHKEM_PHIEUDENGHI:
                str = "thư mục file đính kèm phiếu đề nghị";
                break;
            case TypeAttactmentFolder.THUMUC_FILE_DINHKEM_GOITHAU:
                str = "thư mục file đính kèm của gói thầu";
                break;
            case TypeAttactmentFolder.DANH_SACH_CONGVIEC_VIEC_GOI_THAU:
                str = "công việc gói thầu";
                break;
            default:
                break;

        }

        const dialogConfirm = this.modalService.open(ConfirmComponent, { size: 'md', backdrop: 'static' });
        dialogConfirm.componentInstance.title = "Xác nhận xóa " + str + " " + model.Name + "?";
        dialogConfirm.componentInstance.isConfirm.subscribe((isConfirm) => {
            if (isConfirm) {
                this.attactmentFolderService.delete(model.Id).subscribe((res) => {
                    if (res["meta"]["error_code"] == 200) {
                        this.getByPage();
                        dialogConfirm.dismiss();
                        this.toast.success(res["meta"]["error_message"]);
                    }
                    else this.toast.error(res["meta"]["error_message"]);
                });
            }
        });
    }

    edit(model: DbAttactmentFolder) {
        this.model = JSON.parse(JSON.stringify(model));
    }

    showData(model) {
        this.attactments = model.attactments;
        this.folderName = model.Name;
    }

    returnCompleteStatus(id) {
        let obj = this.completeStatus.find(x => x.Id == id);

        return obj ? obj.Name : "";
    }

    getItemByCode(cs: number) {
        let code: string = "";
        switch (cs) {
            case 1:
                code = "GROUP_DESIGN_FILE";
                break;
            default:
                break;
        }

        this.loaihinhService.getItemByCode(code).subscribe((res) => {
            if (res["meta"]["error_code"] == 200) {
                if (cs == 1) {
                    this.groupDesignFile = res["data"];
                }
            }
        });
    }

    downloadFileViaUrl(url, fileName) {
        this.downloadService.downloadFileViaUrl(url, fileName, domainFileUpload);
    }

    checkMinMaxDate(dateTarget) {
        return this.commonService.checkMinMaxDate(dateTarget);
    }

    export() {
        let paging = new Paging;
        paging.query = `TargetType=${this.targetType} AND TargetId.Equals(\"${this.targetId}\")`;

        const url = domainApi + 'cms/AttactmentFolder/ExportExcel/' + this.targetType;
        const options = new RequestOptions({
            headers: new Headers({
                'Authorization': 'bearer ' + this.storageService.get(TokenEnum.ACCESS_TOKEN)
            }),
            responseType: ResponseContentType.Blob,
        });

        this.exporting = true;
        this.httpDownload.post(url, paging, options).subscribe(res => {
            if (res.blob().size == 0) {
                this.toast.error("Export file không thành công!");
            }
            else {
                let fileName = "";
                if (this.targetType == TypeAttactmentFolder.THUTUC_PHAPLI) {
                    fileName = "Danh sách thủ tục pháp lí.xlsx";
                }
                else if (this.targetType == TypeAttactmentFolder.HOSO_THIETKE) {
                    fileName = "Danh sách hồ sơ thiết kế.xlsx";
                }
                else if (this.targetType == TypeAttactmentFolder.CHUTRUONG_CHIDAO) {
                    fileName = "Danh sách chủ trương chỉ đạo.xlsx";
                }

                saveFile(res.blob(), fileName);
                this.toast.success("Thành công!");
            }

            this.exporting = false;
        });
    }

    import() {
        const dialogRef = this.modalService.open(UploadAttactmentFolderComponent, { size: 'xl', backdrop: 'static' });
        dialogRef.componentInstance.targetId = this.targetId;
        dialogRef.componentInstance.targetType = this.targetType;
        dialogRef.componentInstance.title = this.title;

        dialogRef.result.then((success) => {
            this.getByPage();
        }, (reason) => { });
    }

    getUserMappings(proId: number) {
        this.userService.getUserMappingByProId(proId).subscribe((res) => {
            if (res["meta"]["error_code"] == 200) {
                this.userMappings = res["data"];
            }
        });
    }

    // getDepartments() {
    //     let paging = new Paging();
    //     paging.page_size = 0;
    //     paging.select = "Id,Name";
    //     paging.order_by = "Name Asc";
    //     this.phongbanService.getByPage(paging).subscribe((res) => {
    //         if (res["meta"]["error_code"] == 200) {
    //             this.departments = res["data"];
    //             this.getUserMappings();
    //         }
    //     });
    // }

    downloadAll(attactments) {
        attactments.forEach(attactment => {
            this.downloadService.downloadFileViaUrl(attactment.Name, attactment.Name, domainFileUpload);
        });
    }

    resetForm() {
        this.model = new DbAttactmentFolder();
        this.model.attactments = [];
    }

    chooseUserMapping() {
        this.model.UnitSupport = undefined;
        this.model.Ratio = undefined;

        if(this.model.RelatedId) {
            let user = this.userMappings.find(x => x.UserId == this.model.RelatedId);
            this.model.UnitSupport = user?.Email;
            this.model.Ratio = user?.FullName;
        }
    }
}