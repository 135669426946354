import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { AgEditorComponent } from 'ag-grid-angular';
import * as moment from 'moment';
@Component({
  selector: 'app-picker-cell-editor',
  template: `
  <div class="wrap-edit-cell">
      <input
      class="form-control"
      placeholder="dd/mm/yyyy"
      rInputMask="99/99/9999" datepicker-append-to-body="true"
      [minDate]="checkMinMaxDate(value)"
      name="value" [(ngModel)]="value" ngbDatepicker #valfdsfue="ngbDatepicker">
      <div class="input-group-append">
          <button class="btn btn-outline-secondary calendar" (click)="valfdsfue.toggle()" type="button">
          <i class="fa fa-calendar"></i>
          </button>
      </div>
      </div>
  `,
  styles: [``],
})
export class PrimeCellEditorComponent
  implements AgEditorComponent, AfterViewInit {
  params: any;
  value: any;
  minPickerDate = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate()
};
  @ViewChild('container', { static: true }) public container;

  agInit(params: any): void {
    this.params = params;
    if (this.params.value) {
      this.value = this.params.value;
    }
  }

  // open the calendar when grid enters edit mode, i.e. the datepicker is rendered
  ngAfterViewInit() {
    // this.container.toggle();
  }

  // ensures that once a date is selected, the grid will exit edit mode and take the new date
  // otherwise, to exit edit mode after a selecting a date, click on another cell or press enter
  onSelect(event) {
    // this.params.api.stopEditing(false);
  }

  getValue() {
    console.log('this.value', this.value)
    if (!this.value)
      return null
    //  let parts = this.value.split('-');
    //  console.log('parts', parts)
    // let parts = this.value.replace("-", "/");
     return this.value;
    //  return { year: parts[0], month: parts[1], day: parts[2] }
  }

  checkMinMaxDate(dateTarget) {
      if(!dateTarget) return undefined;
      // let step1 = dateTarget.split("T");
      console.log('dateTarget', dateTarget)
      let convert = dateTarget.split("-");
      let obj = {
        year: Number(convert[0]),
        month: Number(convert[1]),
        day:Number(convert[2])
      };

      return obj;
  }
}
