import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProjectService } from "app/services/project.service";
import { Paging, QueryFilter } from "app/viewModels/base-query";

@Component({
    selector: 'app-choose-project',
    templateUrl: './choose-project.component.html',
    styleUrls: []
})
export class ChooseProjectComponent implements OnInit {
    @Output() target: EventEmitter<any> = new EventEmitter();
    public projects: string;

    constructor(public modal: NgbActiveModal, private projectService: ProjectService) { }

    ngOnInit(): void {
        this.getProjects();
    }

    chooseProject(proId: number): void {
        this.target.emit(proId);
        this.modal.close();
    }

    decline(): void {
    }

    getProjects() {
        let paging = new Paging();
        paging.page_size = 0;
        paging.select = "Id,Name";
        paging.order_by = "Name Asc";

        this.projectService.getByPage(paging).subscribe((res) => {
            if (res["meta"]["error_code"] == 200) {
                this.projects = res["data"];
            }
        });
    }
}
