import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'update-cell-ar-grid',
  template: `
  <a *ngIf="id" href="javascript:void(0)" class="iconEditProject mr-2" (click)="onOpenEDitDialog($event)">
    <i class="fa fa-edit" aria-hidden="true"></i></a>
  <a *ngIf="id" href="javascript:void(0)" class="iconEditProject mr-2" (click)="onOpenAddDialog($event)">
    <i class="fa fa-plus" aria-hidden="true"></i></a>
  <a *ngIf="id && !hasChild" href="javascript:void(0)" class="iconEditProject mr-1" (click)="onOpenImageDialog($event)">
    <i class="fa fa-image" aria-hidden="true"></i></a>
  <a *ngIf="false" href="javascript:void(0)" class="iconEditProject mr-2" (click)="onClick($event)">
    <i class="fa fa-pencil-square" aria-hidden="true"></i></a>
  <a *ngIf="false" href="javascript:void(0)" class="DeleteProIcon" (click)="onDelete($event)">
    <i class="fa fa-trash" aria-hidden="true"></i></a>
  <a *ngIf="id && !hasChild" href="javascript:void(0)" class="iconEditProject mr-2" (click)="onOpenDeleteFolderDialog($event)">
  <i class="fa fa-trash" aria-hidden="true"></i></a>
  `
})

export class UpdateCellRenderer implements ICellRendererAngularComp {
  params?: any;
  id?: number;
  hasChild?: boolean;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.id = params.data.Id;
    this.hasChild = params.data.HasChild;
  }

  refresh(params: ICellRendererParams): boolean {
    return true;
  }

  onClick($event: any) {
    if (this.params?.onUpdateRow instanceof Function) {
      this.params?.onUpdateRow(this.params.node.data);
    }
  }

  onDelete($event: any) {
    if (this.params?.onDeleteRow instanceof Function) {
      this.params?.onDeleteRow(this.params.node.data);
    }
  }

  onOpenImageDialog($event: any) {
    if (this.params?.onOpenImgDialogRow instanceof Function) {
      this.params?.onOpenImgDialogRow(this.params.node.data);
    }
  }

  onOpenAddDialog($event: any) {
    if (this.params?.onOpenAddDialogRow instanceof Function) {
      this.params?.onOpenAddDialogRow(this.params.node.data);
    }
  }

  onOpenEDitDialog($event: any) {
    if (this.params?.onOpenEditDialogRow instanceof Function) {
      this.params?.onOpenEditDialogRow(this.params.node.data);
    }
  }

  onOpenDeleteFolderDialog($event: any) {
    if (this.params?.onOpenDelFolderDialog instanceof Function) {
      this.params?.onOpenDelFolderDialog(this.params.node.data);
    }
  }
}
