<div class="confirm-dialog">
  <div class="modal-body">
    <p class="p-3">
      {{message}}
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-warning" (click)="onSubmit()">Xác nhận</button>
    <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
  </div>
</div>

