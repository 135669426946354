import { environment } from '../../../environments/environment';

const apiUrl = environment.apiUrl;
const apiUrlIdentity = environment.apiUrlIdentity;

export class ApiConstant {
  public static UrlApi = apiUrl;
  //#region Common
  public static UploadImg = apiUrl.concat('/api/upload/uploadImage/2');
  public static UploadFileDocument = apiUrl.concat('/api/upload/uploadFile/9');
  //#endregion
  //#region Auth
  public static LoginUrl = apiUrlIdentity.concat('/api/user/login');
  public static PushToken = apiUrl.concat('/api/deviceUser/pushToken');
  public static RemoveToken = apiUrl.concat('/api/deviceUser/removeToken');
  public static RemoveTokenByUserId = apiUrl.concat('/api/deviceUser/removeTokenByUserId');

  //#endregion

  //#region Product
  public static GetListProduct = apiUrl.concat('/api/Product/GetByPage');
  public static GetProductbyCode = apiUrl.concat('/api/product/getProductByCode/');
  public static DetaiProductlById = apiUrl.concat('/api/product/');
  public static UpdateProductlById = apiUrl.concat('/api/product/');
  public static DetailBondById = apiUrl.concat('/api/bond/GetByPage')
  public static GetIssuanceNew = apiUrl.concat('/api/issuance/GetIssuanceByProductSell');

  public static GetInterestBaseBond = apiUrl.concat('/api/InterestBase/getAllIntestBase/');
  //#endregion

  //#region Contract
  public static GetListContract = apiUrl.concat('/api/cms/contract/GetByPage');
  public static GetContractById = apiUrl.concat('/api/contract/');
  //#endregion

  //#region ContractHistory
  public static GetContractHistorybyId = apiUrl.concat('/api/contracthistory/getHistorybycontract/');
  //#endregion

  //#region ContractTranfer
  public static GetContractTranferbyId = apiUrl.concat('/api/ContractTranfer/getTranferbycontract/');
  //#endregion

  //#region Issuance
  public static GetIssuance = apiUrl.concat('/api/issuance/GetByPage');

  //#endregion

  //#region Issuers
  public static GetIssuers = apiUrl.concat('/api/issuers/GetByPage');

  //#endregion

  //#region Employee
  public static GetEmployee = apiUrl.concat('/api/employee/GetByPage');
  public static GetEmployeeById = apiUrl.concat('/api/employee/');

  //#endregion

  //#region Custommer
  public static GetCustommer = apiUrl.concat('/api/customer/GetByPage');
  public static GetCustommerById = apiUrl.concat('/api/customer/');
  //#endregion

  //#region Bond
  public static GetBond = apiUrl.concat('/api/bond/GetByPage');
  public static GetBondById = apiUrl.concat('/api/bond/');
  //#endregion

  //#region Blockage
  public static GetBlockageByContractId = apiUrl.concat('/api/blockagerelieve/getBlockbycontract/');

  //#endregion

  //#region DateOff
  public static CheckDateOff = apiUrl.concat('/api/InterestReceived/CheckDayOff');

  //#endregion

  //#region Branch
  public static GetBranch = apiUrl.concat('/api/branch/GetByPage');

  //#endregion

  //#region Province
  public static GetProvince = apiUrl.concat('/api/province/GetByPage');

  //#endregion

  //#region Country
  public static GetCountry = apiUrl.concat('/api/country/GetByPage');

  //#endregion

  //#region District
  public static GetDistrict = apiUrl.concat('/api/district/GetByPage');

  //#endregion

  //#region Interest
  public static GetInterest = apiUrl.concat('/api/interestbase/GetByPage');

  //#endregion

  // <----------------------------------------------------------------QLDA------------------------------------------------------------------------>

  //#region Nhà thầu
  public static ApiUrlContractor = apiUrl.concat('/api/cms/Contractor');
  public static GetContractorByPage = ApiConstant.ApiUrlContractor.concat('/GetByPage');
  //#endregion

  //#region Đơn vị chủ quản
  public static ApiUrlOwner = apiUrl.concat('/api/cms/Owner');
  public static GetOwnerByPage = ApiConstant.ApiUrlOwner.concat('/GetByPage');
  //#endregion

  //#region Loại hình
  public static ApiUrlTypeAttribute = apiUrl.concat('/api/cms/TypeAttribute');
  public static ApiUrlTypeAttributeItem = apiUrl.concat('/api/cms/TypeAttributeItem');
  public static GetTypeAttributeByPage = ApiConstant.ApiUrlTypeAttribute.concat('/GetByPage');
  public static GetTypeProject = ApiConstant.ApiUrlTypeAttributeItem.concat('/GetTypeProject');
  public static GetTypeContract = ApiConstant.ApiUrlTypeAttributeItem.concat('/GetTypeContract');
  public static GetTypeReportPidPackage = ApiConstant.ApiUrlTypeAttributeItem.concat('/GetTypeReportPidPackage');
  public static GetAreaProject = ApiConstant.ApiUrlTypeAttributeItem.concat('/GetAreaProject');
  public static GetStatusProcess = ApiConstant.ApiUrlTypeAttributeItem.concat('/GetStatusProject');
  public static GetStatusPidPackageContract = ApiConstant.ApiUrlTypeAttributeItem.concat('/GetStatusPidPackage');
  public static GetTypePidPackage = ApiConstant.ApiUrlTypeAttributeItem.concat('/GetTypePidpackage');
  //#endregion

  //#region Đơn vị tư vấn giám sát
  public static ApiUrlSupervising = apiUrl.concat('/api/cms/SupervisingConsultant');
  public static GetSupervisingByPage = ApiConstant.ApiUrlSupervising.concat('/GetByPage');
  //#endregion

  //#region Chức năng
  public static ApiUrlFunction = apiUrlIdentity.concat('/api/Function');
  public static GetFunctionByPage = ApiConstant.ApiUrlFunction.concat('/GetByPage');
  //#endregion

  //#region Quyền
  public static ApiUrlRole = apiUrlIdentity.concat('/api/FunctionRole');
  public static GetRoleByPage = ApiConstant.ApiUrlRole.concat('/GetByPage');
  //#endregion

  //#region Người dùng
  public static ApiUrlUser = apiUrlIdentity.concat('/api/User');
  public static ApiUrlUserRole = apiUrlIdentity.concat('/api/Userrole');
  public static GetUserByPage = ApiConstant.ApiUrlUserRole.concat('/GetByPage');
  public static GetUserMonitoring = apiUrl.concat('/api/userRole/ListUserMonitor');
  //#endregion

  //#region Dự án
  public static ApiUrlProject = apiUrl.concat('/api/cms/Project');
  public static GetProjectByPage = ApiConstant.ApiUrlProject.concat('/GetByPage');
  //#endregion

  //#region Phòng ban
  public static ApiUrlDepartment = apiUrl.concat('/api/cms/Department');
  public static GetDepartmentByPage = ApiConstant.ApiUrlDepartment.concat('/GetByPage');
  public static GetSelectDepartment = apiUrl.concat('/api/user/selectDepartment/');
  //#endregion

  //#region Phòng ban
  public static ApiUrlPosition = apiUrl.concat('/api/cms/Position');
  public static GetPositionByPage = ApiConstant.ApiUrlPosition.concat('/GetByPage');
  //#endregion

  //#region Hợp đồng
  public static ApiUrlContract = apiUrl.concat('/api/cms/Contract');
  public static GetContractByPage = ApiConstant.ApiUrlContract.concat('/GetByPage');
  public static GetContractByPageThanhTra =ApiConstant.ApiUrlContract.concat('/GetByPageThanhTra');
  public static GetContractAll = ApiConstant.ApiUrlContract.concat('/GetAll');
  public static GetContractByPageVer2 = ApiConstant.ApiUrlContract.concat('/GetByPageVer2');
  public static GetContractByPageVer2ThanhTra =  ApiConstant.ApiUrlContract.concat('/GetByPageVer2ThanhTra');
  public static GetChildContractsByPage = ApiConstant.ApiUrlContract.concat('/GetChildContractsByPage');
  public static GetContractByPageSelect = ApiConstant.ApiUrlContract.concat('/GetByPageSelect');
  public static GetContractByPageSelectAll = ApiConstant.ApiUrlContract.concat('/GetByPageSelectAll');
  public static GetContractByPageAllSelect = ApiConstant.ApiUrlContract.concat('/GetByPageAllSelect');
  public static GetContractsFollowMonitor = ApiConstant.ApiUrlContract.concat('/GetContractsFollowMonitor');
  public static GetContractByPageMonitoring = ApiConstant.ApiUrlContract.concat('/GetByPageMonitoringUser');
  public static GetListWithMonitoring = ApiConstant.ApiUrlContract.concat('/GetListWithMonitoring');
  public static GetContractByPageDecentralization = ApiConstant.ApiUrlContract.concat('/GetByPageDecentralization');
  public static ApiUrlCreateContractChild = apiUrl.concat('/api/categoryPlan/EditNLevel');
  public static GetLogChangeCategoryContractSap = apiUrl.concat('/api/categoryContract/getLogChangeCategoryContract?contractId=');
  public static IntegratedCategoryContractSap = apiUrl.concat('/api/categoryContract/integratedLogChangeInSubContract');
  //#endregion

  //#region Gói thầu
  public static ApiUrlBidPackage = apiUrl.concat('/api/cms/BidPackage');
  public static GetBidPackageByPage = ApiConstant.ApiUrlBidPackage.concat('/GetByPage');
  public static DetailBidPackageById = ApiConstant.ApiUrlBidPackage.concat('/');
  public static CreateBidPackage = ApiConstant.ApiUrlBidPackage;
  public static UpdateBidPackageById = ApiConstant.ApiUrlBidPackage.concat('/');;
  //#endregion

  //#region Tỉnh/Tp
  public static ApiUrlProvince = apiUrl.concat('/api/cms/Province');
  public static GetProvinceByPage = ApiConstant.ApiUrlProvince.concat('/GetByPage');
  //#endregion

  //#region Thư mục file đính kèm
  public static ApiUrlAttactmentFolder = apiUrl.concat('/api/cms/AttactmentFolder');
  public static GetAttactmentFolderByPage = ApiConstant.ApiUrlAttactmentFolder.concat('/GetByPage');
  public static GetUrlFileSap = ApiConstant.ApiUrlAttactmentFolder.concat('/DowloadFileFromSap');
  //#endregion

  //#region Tổng tiến độ dự án
  public static ApiUrlTaskPlan = apiUrl.concat('/api/TaskPlan');
  public static GetTaskPlanByPage = ApiConstant.ApiUrlTaskPlan.concat('/GetByPage');
  //#endregion

  //#region Phiếu đề nghị thanh toán
  public static ApiUrlCash = apiUrl.concat('/api/cms/Cash');
  public static GetCashByPage = ApiConstant.ApiUrlCash.concat('/GetByPage');
  //#endregion

  //#region Các đợt đi tiền của Phiếu đề nghị
  public static ApiUrlCashItem = apiUrl.concat('/api/cms/CashItem');
  public static GetCashItemByPage = ApiConstant.ApiUrlCashItem.concat('/GetByPage');
  //#endregion

  //#region Lịch sử duyệt của Phiếu đề nghị
  public static ApiUrlCashApprove = apiUrl.concat('/api/cms/CashApproval');
  public static GetCashApproveByPage = ApiConstant.ApiUrlCashApprove.concat('/GetByPage');
  //#region Report
  public static ApiUrlReport = apiUrlIdentity.concat('/api/report');
  public static GetDetailContract = ApiConstant.ApiUrlReport.concat('/contractDetailView');//1
  public static GetTotalContractAnnex = ApiConstant.ApiUrlReport.concat('/ContractAllView');//2
  public static GetDetaiProject = ApiConstant.ApiUrlReport.concat('/projectDetailView');//3
  public static GetTotalListProject = ApiConstant.ApiUrlReport.concat('/projectAllView');//4
  public static GetTotalContractContactor = ApiConstant.ApiUrlReport.concat('/bidderContractView');//5
  public static GetDetaiProgressContract = ApiConstant.ApiUrlReport.concat('/contractDetailView');//6
  public static GetTotalProgressContactor = ApiConstant.ApiUrlReport.concat('/categoryAllView');//7
  public static GetTotalContractProject = ApiConstant.ApiUrlReport.concat('/contractProjectView');//8
  public static GetProgressProjects = ApiConstant.ApiUrlReport.concat('/projectUnitView');//9
  public static GetProgressContractors = ApiConstant.ApiUrlReport.concat('/projectBidderView');//10
  public static GetProjectHard = ApiConstant.ApiUrlReport.concat('/projectHardView');//11
  public static GetCategoryDetailView = ApiConstant.ApiUrlReport.concat('/categoryDetailView');//11
  public static GetCategoryAllView = ApiConstant.ApiUrlReport.concat('/categoryAllView');//11
  public static GetContractProjectView = ApiConstant.ApiUrlReport.concat('/contractProjectView');//11
  public static GetProjectUnitView = ApiConstant.ApiUrlReport.concat('/projectUnitView');//11
  public static GetProjectBidderView = ApiConstant.ApiUrlReport.concat('/projectBidderView');//11
  public static GetDifficultyView = ApiConstant.ApiUrlReport.concat('/projectHardView/');//11
  public static GetTotalProjectAllView = ApiConstant.ApiUrlReport.concat('/ReportProjecAllView/');//11
  public static GetTotalProjectAll = ApiConstant.ApiUrlReport.concat('/ReportProjecAll/');//11
  public static GetWarningContractView = ApiConstant.ApiUrlReport.concat('/reportWarningContractView/');//11
  public static ReportWarningContract = ApiConstant.ApiUrlReport.concat('/reportWarningContract/');//11

  //#report week
  public static ApiUrlReportWeek = apiUrlIdentity.concat('/api/reportWeek');
  public static getReportWeekPage = ApiConstant.ApiUrlReportWeek.concat('/GetByPage');
  //#endregion

  //#endregion

  //#region Dự án
  public static ApiUrlPlan = apiUrlIdentity.concat('/api/TaskPlan');
  public static GetPlanByPage = ApiConstant.ApiUrlPlan.concat('/GetByPage');
  //#endregion

  //#region Api hội thoại
  public static ApiUrlConversation = apiUrl.concat('/api/cms/Conversation');
  public static GetConversationByPage = ApiConstant.ApiUrlConversation.concat('/GetByPage');
  //#endregion

  //#region Khối lượng công việc
  public static ApiUrlCategoryContract = apiUrl.concat('/api/categoryContract');
  public static GetCategoryContractByPage = ApiConstant.ApiUrlCategoryContract.concat('/GetByContractIdNLevel');
  public static CreateCategoryContract = ApiConstant.ApiUrlCategoryContract;
  public static GetCountCategoryContract = ApiConstant.ApiUrlCategoryContract.concat('/checkUploadNLevelByContractId');
  public static ExportCategoryContract = ApiConstant.ApiUrlCategoryContract.concat('/exportCategoryExcelNLevel');
  public static ImportCategoryContract = ApiConstant.ApiUrlCategoryContract.concat('/importCategoryExcelNLevel');
  //#endregion

  //#region Giám sát
  public static ApiUrlMonitoringHistory = apiUrl.concat('/api/monitoringHistory');
  //#endregion

    //#region "Giám sát thi công"
    public static ApiUrlMonitoringContract =  apiUrl.concat('/api/cms/monitoringContract');
    public static CreateMonitoringContract = ApiConstant.ApiUrlMonitoringContract;
    public static GetMonitoringContractByPage = ApiConstant.ApiUrlMonitoringContract.concat('/GetByPage');
    public static ExportExcelMonitoringContract = ApiConstant.ApiUrlMonitoringContract.concat('/exportExcel');
    //#endregion

  //#region "Kế hoạch thi công"
  //#region "Kế hoạch thi công"
  public static ApiUrlCategoryPlan = apiUrl.concat('/api/categoryPlan');
  public static GetCategoryPlanExcel = ApiConstant.ApiUrlCategoryPlan.concat('/GetByPlanAllNLevel');
  public static ImportCategoryPlan = ApiConstant.ApiUrlCategoryPlan.concat('/importCategoryPlamExcelNLevel');
  public static CreateCategoryPlan = ApiConstant.ApiUrlCategoryPlan.concat('/NLevel');
  //#endregion
  //#endregion

  //#region "Phân quyền giám sát"
  public static ApiUrlMonitoringUser = apiUrl.concat('/api/monitoringUser');
  public static GetContractByPageWithMonitoringUser = apiUrl.concat('/api/cms/contract/GetByPageWithMonitoring');
  public static AddMultiMonitoringUser = ApiConstant.ApiUrlMonitoringUser.concat('/postMulti');
  //#endregion

  //#region OneDriveFolder
  public static ApiUrlOneDriveFolder = apiUrl.concat('/api/OneDriverV2'); 
  public static DeleteFolderOneDriveFolder = ApiConstant.ApiUrlOneDriveFolder.concat('/deleteFolder');
  public static GetAttactmentByOneDriveFolderId = ApiConstant.ApiUrlOneDriveFolder.concat('/getAttactmentByOneDriverFolderId');
  public static GetOneDriveFolderByPage = ApiConstant.ApiUrlOneDriveFolder.concat('/GetByPage');
  //#endregion

  //#region OneDriveFile
  public static ApiUrlOneDriveFile = apiUrl.concat('/api/cms/OneDriveFile');
  public static GetOneDriveFileByPage = ApiConstant.ApiUrlOneDriveFile.concat('/GetByPage');
  public static GetOneDriveFilePost = ApiConstant.ApiUrlOneDriveFile.concat('/PostVer2');
  public static oneDriveFolderShares = apiUrl.concat('/api/oneDriverV2/oneDriveFolderShares');
  public static download = apiUrl.concat('/api/oneDriverV2/download');
  public static getOneDriveFolderShares = apiUrl.concat('/api/oneDriverV2/oneDriveFolderShares');
  public static getFolderProject = apiUrl.concat('/api/oneDriverV2/getFolderProject');
  public static getFolderContract = apiUrl.concat('/api/oneDriverV2/getFolderContract');
  public static getProjectMerge = apiUrl.concat('/api/oneDriverV2/getProjectMerge');
  public static getContractMerge = apiUrl.concat('/api/oneDriverV2/getContractMerge');
  public static UpdateUserMappingDepart = apiUrl.concat('/api/cms/project/UpdateUserMappingDepart');
  //#endregion

    //#region DashBoard
    public static DashBoard = apiUrl.concat('/api/dashboard');
    public static GetFirstRow = ApiConstant.DashBoard.concat('/GetFirstRow');
    public static GetProjectStatusChart = ApiConstant.DashBoard.concat('/GetProjectStatusChart');
    public static GetContractStatusChart = ApiConstant.DashBoard.concat('/GetContractStatusChart');
    public static GetProjectRegionChart = ApiConstant.DashBoard.concat('/GetProjectRegionChart');
    public static GetProjectUnitMangerChart = ApiConstant.DashBoard.concat('/GetProjectUnitMangerChart');
    public static GetWarningContract = ApiConstant.DashBoard.concat('/GetWarningContract');
    public static GetDifficultyContract = ApiConstant.DashBoard.concat('/GetDifficultyContract');
    //#endregion 

    //#region khó khăn vướng mắc
    public static ApiMonitoringContract = apiUrl.concat('/api/cms/monitoringContract');

    //#region khó khăn vướng mắc
    public static ApiSettingCommonSystem = apiUrl.concat('/api/SettingCommonSystem');

    //Logsystem
    public static ApiLogSystem = apiUrl.concat('/api/action/GetLogSystemByPage');

}
