import { TypeAttributeService } from './../services/type-attribute.service';
import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OverlayModule } from '@angular/cdk/overlay';

import { NgbDateAdapter, NgbDateParserFormatter, NgbDropdown, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ClickOutsideModule } from 'ng-click-outside';

import { AutocompleteModule } from './components/autocomplete/autocomplete.module';
import { PipeModule } from 'app/shared/pipes/pipe.module';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';

// COMPONENTS
import { FooterComponent } from '../components/shared/footer/footer.component';
import { NavbarComponent } from '../components/shared/navbar/navbar.component';
import { HorizontalMenuComponent } from '../components/shared/horizontal-menu/horizontal-menu.component';
import { VerticalMenuComponent } from '../components/shared/vertical-menu/vertical-menu.component';
import { CustomizerComponent } from '../components/shared/customizer/customizer.component';
import { NotificationSidebarComponent } from '../components/shared/notification-sidebar/notification-sidebar.component';

// DIRECTIVES
import { ToggleFullscreenDirective } from './directives/toggle-fullscreen.directive';
import { SidebarLinkDirective } from './directives/sidebar-link.directive';
import { SidebarDropdownDirective } from './directives/sidebar-dropdown.directive';
import { SidebarAnchorToggleDirective } from './directives/sidebar-anchor-toggle.directive';
import { SidebarDirective } from './directives/sidebar.directive';
import { TopMenuDirective } from './directives/topmenu.directive';
import { TopMenuLinkDirective } from './directives/topmenu-link.directive';
import { TopMenuDropdownDirective } from './directives/topmenu-dropdown.directive';
import { TopMenuAnchorToggleDirective } from './directives/topmenu-anchor-toggle.directive';
import { ToastrModule } from 'ngx-toastr';
import { NgSelectModule } from '@ng-select/ng-select';
import { CommonService } from 'app/services/common.service';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ModalDialogModule } from 'ngx-modal-dialog';
import { DownloadService } from './services/download.service';
import { NgxSpinnerModule } from 'ngx-spinner';
import { LayoutService } from './services/layout.service';
import { ConfigService } from './services/config.service';

import { ConfirmComponent } from 'app/shared/components/confirm/confirm.component';
import { ConfirmDialogComponent } from 'app/components/shared/confirm-dialog/confirm-dialog.component';
import { CustomAdapterService } from './services/custom-adapter.service';
import { CustomDateParserFormatterService } from './services/custom-date-parser-formatter.service';
import { ChooseProjectComponent } from 'app/shared/components/choose-project/choose-project.component';
import { AttactmentCommonComponent } from 'app/shared/components/attactment/attactment.component';
import { AttactmentFolderCommonComponent } from 'app/shared/components/attactment-folder/attactment-folder.component';
import { OnedrivefolderComponent } from 'app/shared/components/onedrivefolder/onedrivefolder.component';

import { InputMaskModule } from 'racoon-mask-raw';
import { AgGridModule } from 'ag-grid-angular';
import { ButtonAgGridComponent } from 'app/shared//components/button-renderer/button-renderermutibuttons.component';
import { PrimeCellEditorComponent } from 'app/shared//components/button-renderer/prime-cell-editor.component';
import { ConversationComponent } from './components/conversation/conversation.component';
import { CustomPinnedRowRenderer } from './components/custom-pined-row/custom-pined-row-renderer.component';
import { NgAutoCompleteCustomComponent } from './components/ng-autocomplete-custom/ng-autocomplete-custom.component';

import { UploadAttactmentFolderComponent } from './components/upload-attactment-folder/upload-attactment-folder.component';
import { NameCategoryMonitoringComponent } from 'app/shared/components/name-category-monitoring/name-category-monitoring.component';
import { ConstructionVolumeComponent } from 'app/shared/components/construction-volume/construction-volume.component';
import { NumericEditor } from './components/ag-grid/numeric-editor.component';
//import { MonitoringContractConfigComponent } from './components/monitoring-contract-config/monitoring-contract-config.component';
import { UiSwitchModule } from 'ngx-ui-switch';
import { MessagingService } from './services/messaging.service';
import { DeleteCellRenderer } from './components/ag-grid/delete-cell-renderer';
import { UpdateCellRenderer } from './components/ag-grid/update-cell-renderer';
import { IsSapCellIconComponent } from './components/ag-grid/is-sap-cell-icon.component';
import { StatusImportFileTemplateCellRender } from 'app/shared/components/ag-grid/status-import-file-template-cell-render';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { GoogleMapCommonComponent } from 'app/shared/components/google-map/google-map.component';
import { AgmCoreModule } from '@agm/core';
//import { CurrencyMaskModule } from "ng2-currency-mask";
import { AuthService } from 'app/services/auth.service';
import { DateModifiedCategoryMonitoringComponent } from './components/date-modified-image-monitoring/date-modified-image-monitoring';
import { DateUpdatedCategoryMonitoringComponent } from './components/date-updated-image-monitoring/date-updated-image-monitoring';
import { NgAutocompleteCustomsComponent } from './components/ng-autocomplete-customs/ng-autocomplete-customs.component';


@NgModule({
    exports: [
        CommonModule,
        FooterComponent,
        NavbarComponent,
        VerticalMenuComponent,
        HorizontalMenuComponent,
        CustomizerComponent,
        NotificationSidebarComponent,
        ToggleFullscreenDirective,
        SidebarDirective,
        TopMenuDirective,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        NgSelectModule,
        NgxSpinnerModule,
        ToastrModule,
        PaginationModule,
        ModalModule,
        ModalDialogModule,
        InputMaskModule,
        AutocompleteLibModule,
        AttactmentCommonComponent,
        AttactmentFolderCommonComponent,
        InputMaskModule,
        InfiniteScrollModule,
        //CurrencyMaskModule,
        ConversationComponent,
        UploadAttactmentFolderComponent,
        NgAutoCompleteCustomComponent,
        ButtonAgGridComponent,
        PrimeCellEditorComponent,
        ConversationComponent,
        NgAutocompleteCustomsComponent,
        UploadAttactmentFolderComponent,
        ConstructionVolumeComponent,
        GoogleMapCommonComponent,
        OnedrivefolderComponent,
        DateModifiedCategoryMonitoringComponent,
        DateUpdatedCategoryMonitoringComponent
    ],
    imports: [
        RouterModule,
        CommonModule,
        NgbModule,
        FormsModule,
        OverlayModule,
        ReactiveFormsModule ,
        PerfectScrollbarModule,
        ClickOutsideModule,
        AutocompleteModule,
        PipeModule,
        NgSelectModule,
        NgxSpinnerModule,
        //UiSwitchModule,
        ToastrModule.forRoot(),
        PaginationModule.forRoot(),
        ModalModule.forRoot(),
        ModalDialogModule.forRoot(),
        InputMaskModule,
        //CurrencyMaskModule,
        AutocompleteLibModule,
        AgGridModule.withComponents([
          ButtonAgGridComponent,
          PrimeCellEditorComponent
        ]),
        AutocompleteLibModule,
        InfiniteScrollModule,
        AgmCoreModule.forRoot({
          // apiKey: 'AIzaSyB9uspDoqvt9GUsGYI6brObMEhMUbwc_O8'
          apiKey: 'AIzaSyBYYS-33Bp4bxihXuEDpFrsQBRCVoLZcBI'
        })
    ],
    declarations: [
        FooterComponent,
        NavbarComponent,
        VerticalMenuComponent,
        HorizontalMenuComponent,
        CustomizerComponent,
        NotificationSidebarComponent,
        ToggleFullscreenDirective,
        SidebarLinkDirective,
        SidebarDropdownDirective,
        SidebarAnchorToggleDirective,
        SidebarDirective,
        TopMenuLinkDirective,
        TopMenuDropdownDirective,
        ButtonAgGridComponent,
        TopMenuAnchorToggleDirective,
        TopMenuDirective,
        ConfirmComponent,
        ConfirmDialogComponent,
        ChooseProjectComponent,
        AttactmentCommonComponent,
        AttactmentFolderCommonComponent,
        PrimeCellEditorComponent,
        CustomPinnedRowRenderer,
        ConversationComponent,
        UploadAttactmentFolderComponent,
        NgAutoCompleteCustomComponent,
        ConstructionVolumeComponent,
        NameCategoryMonitoringComponent,
        NumericEditor,
        DeleteCellRenderer,
        UpdateCellRenderer,
        IsSapCellIconComponent,
        StatusImportFileTemplateCellRender,
        GoogleMapCommonComponent,
        OnedrivefolderComponent,
        DateModifiedCategoryMonitoringComponent,
        DateUpdatedCategoryMonitoringComponent,
        NgAutocompleteCustomsComponent

        // MonitoringContractConfigComponent
    ],
    providers: [
      CommonService,
      DownloadService,
      LayoutService,
      ConfigService,
      TypeAttributeService,
      DatePipe,
      CurrencyPipe,
      {provide: NgbDateAdapter, useClass: CustomAdapterService},
      {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatterService},
      MessagingService
    ]
})
export class SharedModule { }
