<div class="modal-header">
    <h4 class="modal-title">
        <span *ngIf="!id"><i class="fa fa-plus-circle mr-1" aria-hidden="true"></i>Thêm mới thư mục con của thư mục {{ type == 2 ? input.Name : input.text }}</span>
        <span *ngIf="id"><i class="fa fa-edit mr-1" aria-hidden="true"></i>Sửa thư mục {{ model?.Name }}</span>
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="[modal.close(), f.resetForm()]">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<form name="form" class="form-custom dialogPhongBan" (ngSubmit)="f.form.valid && save()" #f="ngForm"
    novalidate>
    <div class="modal-body">
        <div class="infoPackage">
            <div class="inDetailPackage">
                <div class="Detail_Packed AddForm_Package">
                    <div class="ColForm_AddPackage formPopupDialog">
                        <div class="AlliteminfoPk inInfoPackage displayflex">
                            <div class="iteminfoPk">
                                <div class="labelinfoPk">
                                    <span>STT <span class="required">(*)</span></span>
                                </div>
                                <div class="valueInfoPk">
                                    <input type="text" id="STT" name="STT" [(ngModel)]="model.STT"
                                        [ngClass]="{'border-error' : f.submitted && STT.errors}" #STT="ngModel"
                                        autocomplete="off" spellcheck="false" required>
                                    <span *ngIf="f.submitted && STT.errors" class="spanValidate">
                                        <span *ngIf="STT.errors.required">Vui lòng nhập STT</span>
                                    </span>
                                </div>
                            </div>
                            <div class="iteminfoPk">
                                <div class="labelinfoPk">
                                    <span>Tên công việc <span class="required">(*)</span></span>
                                </div>
                                <div class="valueInfoPk">
                                    <input type="text" id="Name" name="Name" [(ngModel)]="model.Name"
                                        [ngClass]="{'border-error' : f.submitted && Name.errors}" #Name="ngModel"
                                        autocomplete="off" spellcheck="false" required>
                                    <span *ngIf="f.submitted && Name.errors" class="spanValidate">
                                        <span *ngIf="Name.errors.required">Vui lòng nhập tên công việc</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="AlliteminfoPk inInfoPackage displayflex">
                            <div class="iteminfoPk">
                                <div class="labelinfoPk">
                                    <span>Đơn vị <span class="required"></span></span>
                                </div>
                                <div class="valueInfoPk">
                                    <input type="text" id="UnitCalculate" name="UnitCalculate" [(ngModel)]="model.UnitCalculate"
                                        [ngClass]="{'border-error' : f.submitted && UnitCalculate.errors}" #UnitCalculate="ngModel"
                                        autocomplete="off" spellcheck="false">
                                </div>
                            </div>
                            <div class="iteminfoPk">
                                <div class="labelinfoPk">
                                    <span>Tần suất thực hiện <span class="required"></span></span>
                                </div>
                                <div class="valueInfoPk">
                                    <input type="text" id="FrequencyPerform" name="FrequencyPerform" [(ngModel)]="model.FrequencyPerform"
                                        [ngClass]="{'border-error' : f.submitted && FrequencyPerform.errors}" #FrequencyPerform="ngModel"
                                        autocomplete="off" spellcheck="false">
                                </div>
                            </div>
                        </div>
                        <div class="AlliteminfoPk inInfoPackage displayflex">
                            <div class="iteminfoPk">
                                <div class="labelinfoPk">
                                    <span>Khối lượng video dự kiến <span class="required"></span></span>
                                </div>
                                <div class="valueInfoPk">
                                    <input type="number" id="VideoPlan" name="VideoPlan" [(ngModel)]="model.VideoPlan"
                                        [ngClass]="{'border-error' : f.submitted && VideoPlan.errors}"
                                        #VideoPlan="ngModel" autocomplete="off" spellcheck="false" >
                                </div>
                            </div>
                            <div class="iteminfoPk">
                                <div class="labelinfoPk">
                                    <span>Khối lượng hình ảnh dự kiến <span class="required"></span></span>
                                </div>
                                <div class="valueInfoPk">
                                    <input type="number" id="ImagePlan" name="ImagePlan" [(ngModel)]="model.ImagePlan"
                                        [ngClass]="{'border-error' : f.submitted && ImagePlan.errors}"
                                        #ImagePlan="ngModel" autocomplete="off" spellcheck="false" >
                                </div>
                            </div>
                        </div>
                        <div class="AlliteminfoPk inInfoPackage displayflex">
                            <div class="iteminfoPk">
                                <div class="labelinfoPk">
                                    <span>Số vị trí/Số đợt <span class="required"></span></span>
                                </div>
                                <div class="valueInfoPk">
                                    <input type="number" id="NumberLocation" name="NumberLocation" [(ngModel)]="model.NumberLocation"
                                        [ngClass]="{'border-error' : f.submitted && NumberLocation.errors}"
                                        #NumberLocation="ngModel" autocomplete="off" spellcheck="false" >
                                </div>
                            </div>
                            <div class="iteminfoPk">
                                <div class="labelinfoPk">
                                    <span>Số tầng <span class="required"></span></span>
                                </div>
                                <div class="valueInfoPk">
                                    <input type="number" id="NumberFloor" name="NumberFloor" [(ngModel)]="model.NumberFloor"
                                        [ngClass]="{'border-error' : f.submitted && NumberFloor.errors}"
                                        #NumberFloor="ngModel" autocomplete="off" spellcheck="false" >
                                </div>
                            </div>
                        </div>
                        <div class="AlliteminfoPk inInfoPackage displayflex">
                            <div class="iteminfoPk">
                                <div class="labelinfoPk">
                                    <span>Số video tối thiếu <span class="required"></span></span>
                                </div>
                                <div class="valueInfoPk">
                                    <input type="number" id="VideoStart" name="VideoStart" [(ngModel)]="model.VideoStart"
                                        [ngClass]="{'border-error' : f.submitted && VideoStart.errors}"
                                        #VideoStart="ngModel" autocomplete="off" spellcheck="false" >
                                </div>
                            </div>
                            <div class="iteminfoPk">
                                <div class="labelinfoPk">
                                    <span>Số video tối đa <span class="required"></span></span>
                                </div>
                                <div class="valueInfoPk">
                                    <input type="number" id="VideoEnd" name="VideoEnd" [(ngModel)]="model.VideoEnd"
                                        [ngClass]="{'border-error' : f.submitted && VideoEnd.errors}"
                                        #VideoEnd="ngModel" autocomplete="off" spellcheck="false" >
                                </div>
                            </div>
                        </div>
                        <div class="AlliteminfoPk inInfoPackage displayflex">
                            <div class="iteminfoPk">
                                <div class="labelinfoPk">
                                    <span>Số hình ảnh tối thiếu <span class="required"></span></span>
                                </div>
                                <div class="valueInfoPk">
                                    <input type="number" id="ImageStart" name="ImageStart" [(ngModel)]="model.ImageStart"
                                        [ngClass]="{'border-error' : f.submitted && ImageStart.errors}"
                                        #ImageStart="ngModel" autocomplete="off" spellcheck="false" >
                                </div>
                            </div>
                            <div class="iteminfoPk">
                                <div class="labelinfoPk">
                                    <span>Số hình ảnh tối đa <span class="required"></span></span>
                                </div>
                                <div class="valueInfoPk">
                                    <input type="number" id="ImageEnd" name="ImageEnd" [(ngModel)]="model.ImageEnd"
                                        [ngClass]="{'border-error' : f.submitted && ImageEnd.errors}"
                                        #ImageEnd="ngModel" autocomplete="off" spellcheck="false" >
                                </div>
                            </div>
                        </div>
                        <div class="areaAdd_Package pl-0">
                            <div class="labelinfoPk">
                                <span>Ghi chú <span class="required"></span></span>
                            </div>
                            <div class="valueInfoPk">
                                <textarea class="areaAdd_Package" id="Note" name="Note"
                                    [(ngModel)]="model.Note"
                                    [ngClass]="{'border-error' : f.submitted && Note.errors}" #Note="ngModel"
                                    autocomplete="off" spellcheck="false"
                                    placeholder=""></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="modal-footer">
        <button type="submit" class="btn btn-primary mr-2" [disabled]="submitted"><i
                class="ft-check-square mr-1"></i>Lưu thông tin</button>
        <button type="button" class="btn btn-secondary" (click)="[modal.close(), f.resetForm()]">Đóng</button>
    </div>
</form>