// Author: T4professor

import { Component, OnInit } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
// import { ICellRendererParams, IAfterGuiAttachedParams } from 'ag-grid';

@Component({
  selector: "app-name-category-monitoring",
  template: `
  <span *ngFor="let number of [].constructor(data.Level); let i = index">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
  <span>{{ data.Name }}</span>
  `,
})
export class NameCategoryMonitoringComponent
  implements ICellRendererAngularComp
{
  data: any;
  agInit(params: any): void {
    this.data = params.data;
  }

  refresh(params?: any): boolean {
    return true;
  }
}
