<app-navbar (seachTextEmpty)="checkNavbarSeachTextEmpty($event)"></app-navbar>

<div class="wapper_devmd" [ngClass]="{'custom-project-detail' : isAddNewProjectPage == true}">
  <div class="wrapper" [ngClass]="{'show-overlay': !isNavbarSeachTextEmpty}" (click)="onWrapperClick();" (window:resize)="onResize($event)">
    <div appSidebar *ngIf="menuPosition === 'Side' || displayOverlayMenu"
      [ngClass]="{'main-menu': menuPosition === 'Side' || displayOverlayMenu, 'menu-fixed': menuPosition === 'Side' || displayOverlayMenu, 'menu-native-scroll': !perfectScrollbarEnable }" class="app-sidebar"
      (mouseenter)="sidebarMouseenter($event)" (mouseleave)="sidebarMouseleave($event)" data-active-color="white"
      [attr.data-background-color]="config?.layout.variant === 'Transparent' ? 'black': bgColor"
      [attr.data-image]="bgImage">
      <app-sidebar></app-sidebar>
      <div class="sidebar-background" [ngStyle]="{'background-image': 'url(' + bgImage + ')'}"
        *ngIf="config?.layout.sidebar.backgroundImage"></div>
    </div>
    <div class="main-panel">
      <div class="main-content">
        <div class="content-overlay"></div>
        <div class="content-wrapper">
          <router-outlet></router-outlet>
        </div>
      </div>
      <app-footer></app-footer>
      <button class="btn btn-primary scroll-top" type="button" *ngIf="isScrollTopVisible" (click)="scrollToTop()"><i class="ft-arrow-up"></i></button>
    </div>
    <app-notification-sidebar></app-notification-sidebar>
    <div class="sidenav-overlay" style="z-index: auto;" (click)="onOutsideClick($event);"
      [ngClass]="{'d-none': displayOverlayMenu && hideSidebar && !overlayContent , 'd-block': displayOverlayMenu && !hideSidebar && overlayContent && innerWidth < 1200}"></div>
    <div class="drag-target"></div>
  </div>
</div>