<div class="ng-autocomplete">
    <ng-autocomplete 
        [data]="data"
        [searchKeyword]="'Name'"
        [placeholder]="title"
        (selected)='selectEvent($event)'
        (inputChanged)='inputChanged($event)'
        (keyup.enter)='searchEvtEmitter.emit(true)'
        [itemTemplate]="itemTemplate"
        [notFoundTemplate]="notFoundTemplate"
        [customFilter]="customFilter"
        (inputCleared)="clearTxtSearch($event)"
        [isLoading]="isLoading">                                 
    </ng-autocomplete>
    
    <ng-template #itemTemplate let-item>
        <a [innerHTML]="item.Name"></a>
    </ng-template>
    
    <ng-template #notFoundTemplate let-notFound>
        <div style="font-size: 12px; font-style: italic;">Không có dữ liệu</div>
    </ng-template>
</div>