import { AppStatusCode, TokenEnum } from '../common/app.constants';
import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthService } from 'app/services/auth.service';
import { Router, RouterStateSnapshot } from '@angular/router';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class AppInterceptor implements HttpInterceptor {

  constructor(private injector: Injector, private authService: AuthService, private router : Router) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authService = this.injector.get(AuthService);
        const router = this.injector.get(Router);
        const token = authService.getToken(TokenEnum.ACCESS_TOKEN) ? authService.getToken(TokenEnum.ACCESS_TOKEN) : '';
        //const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Im5hbW1jdEBnbWFpbC5jb20iLCJqdGkiOiJmMjQ3Yjc3My02MTc2LTQyMTctOWYxMi0wZTVhNzI0M2JmZDAiLCJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1laWRlbnRpZmllciI6IjEiLCJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiQWRtaW4gVG50ZWNoIiwiVXNlcklkIjoiMSIsIlVuaXRJZCI6IjIiLCJOYW1lIjoiQWRtaW4gVG50ZWNoIiwiUm9sZU1heCI6IjEiLCJSb2xlTGV2ZWwiOiIxIiwiQWNjZXNzS2V5IjoiQ0hJVElFVERVQU46MTExMTExMTExLVFMQ046MTExMTExMTExLU5IQVRIQVU6MTExMTExMTExLVFMSEQ6MTExMTExMTExLVBBWUFEOjExMTExMTExMS1RTEFUVEFDSE1FTlQ6MTExMTAwMDAwLUdTSFNUSzoxMTExMTExMTAtQkNBT0NUSE9QRE9ORzoxMTExMTExMTEtVE9OR0hPUEhET05HUEw6MTExMTExMTExLUdTTktUQzoxMTExMTExMTAtUUxHVDoxMTExMTExMTEtUEFZUE9ERVI6MTExMTExMTExLVFMUToxMTExMTExMTEtREFOSE1VQzoxMTExMTExMTEtVEFTSzoxMTExMTExMTEtQkFPQ0FPVE9ORzoxMTExMTExMTEtUUxUQjoxMTExMTExMTEtRFVBTjoxMTExMTExMTEtUFFHUzoxMTExMTExMTEtUUxIRE9ORzoxMTExMTExMTEtR1NIQVRDOjExMTExMTExMC1QQVlTRVQ6MTExMTExMTExLUNISVRJVEVUMURVQU46MTExMTExMTExLUhNSEQ6MTExMTExMTExLVFMRE9OVkk6MTExMTExMTExLVBRR1NBVDoxMTExMTExMTEtUEFZUkVGVU5EOjExMTExMTExMS1UT05HSERVQU46MTExMTExMTExLUdTUEFSRU5UOjExMTExMTExMS1CQ0FPVEhBTkhUT0FOMU5IQVQ6MTExMTExMTExLVRIQU5IVE9BTjoxMTExMTExMTEtUUxEVkdTOjExMTExMTExMS1HU0tMVENOVDoxMTExMTExMTAtUExBTjoxMTExMTExMTEtSERPTkdDVUFOSEFUSEFVOjExMTExMTExMS1HU0tMVENUVDoxMTExMTExMTAtS0tWTToxMTExMTExMTAtQkFPQ0FPOjExMTExMTExMS1QSE9OR0JBTjoxMTExMTExMTEtUUxORDoxMTExMTExMTEtSE9TT0xVVFJVOjExMTExMTExMS1CQ1Q6MTExMTExMTExLVFMQ05UOjExMTExMTExMS1QRVJJT0RSRVBPUlQ6MTExMTExMTExLVRFTVBMQVRFOjEwMDAwMDAwMS1RTENWVToxMTExMTExMTEtUUxIVDoxMTExMTExMTEtR1NZS0NEVDoxMTExMTExMTAtR1NIU0hDOjExMTExMTExMC1RTExIOjExMTExMTExMS1DT05WRVJTQVRJT046MTExMTAwMDAwLUNIRUNLQklEUEFDS0FHRToxMTEwMDAwMDAtQUNDRVBUQ0FTSDowMDAxMDAwMDAiLCJleHAiOjE2NTc4NTAxMzYsImlzcyI6Imh0dHBzOi8vbG9jYWxob3N0OjQ0Mzc2LyIsImF1ZCI6Imh0dHBzOi8vbG9jYWxob3N0OjQ0Mzc2LyJ9.nOVNp3qqsPvsKSpDbjmWTV_-aTNEsW8Qe1Xflv_gpXo';

        const reqHeader = request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`
            }
        });

        return next.handle(reqHeader).pipe(
          map((res: any) => {
            return res;
          }),
          catchError((err: HttpErrorResponse) => {
            if (err.status === 401) {

              if (this.router.url != '/auth/login') {
                this.authService.logout();
                this.router.navigate(['/auth/login'], {queryParams: {returnUrl: this.router.url}});
              }
            }

            const error = err.message || err.statusText;

            return throwError(error);
          }),
        );
  }
}
