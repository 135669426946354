// Author: T4professor

import { Component, OnInit } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import * as moment from 'moment';
// import { ICellRendererParams, IAfterGuiAttachedParams } from 'ag-grid';

@Component({
  selector: "date-updated-image-monitoring",
  template: `
  <span>{{ data }}</span>
  `,
})
export class DateUpdatedCategoryMonitoringComponent
  implements ICellRendererAngularComp
{
  data: any;
  agInit(params: any): void {
    this.data = (params.data && params.data.HasChild == false) ? moment(params.data.DateEnd).format('DD/MM/yyyy') : '';
  }

  refresh(params?: any): boolean {
    return true;
  }
}
