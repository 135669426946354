import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Injectable()
export class PreviousRouteService {

  private url: string;

  constructor(private router: Router) {
  }

  public get() {
    return this.url;
  }
  
  public set(url) {
    return this.url = url;
  } 
}