import { TypeAttribute, TypeAttributeItem } from './../viewModels/loaihinh/type-attribute';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiConstant } from 'app/shared/common/api.constants';
import { DataService } from 'app/shared/services/data.service';
import { Paging } from 'app/viewModels/base-query';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoaihinhService {

  public isEdit = false;
  public subject = new Subject<any>();
  private itemProductSource = new BehaviorSubject(this.isEdit);

  constructor(
    private dataService: DataService,
  ) { }

  getByPage(pageOption: Paging) {
    return this.dataService.get(ApiConstant.GetTypeAttributeByPage
      .concat(`?page=`, '' + pageOption.page)
      .concat(`&page_size=`, '' +  pageOption.page_size)
      .concat(`&query=`, pageOption.query)
      .concat(`&select=`, pageOption.select)
      .concat('&order_by=', pageOption.order_by))
      .pipe(map((res: any) => {
        return res;
      }));
  }

  update(id: number, itemProduct: TypeAttribute) {
    return this.dataService.put(ApiConstant.ApiUrlTypeAttribute.concat('/' + id), itemProduct)
      .pipe(
        map(res => {
          return res;
        }
      ))
  }

  create(itemProduct: TypeAttribute) {
    return this.dataService.post(ApiConstant.ApiUrlTypeAttribute, itemProduct)
      .pipe(
        map(res => {
          return res;
        }
      ))
  }

  getById(id: number): Observable<any> {
    return this.dataService.get(ApiConstant.ApiUrlTypeAttribute.concat('/' + id))
    .pipe(
        map(res => {
          return res;
        }
      ))
  }

  delete(id: number): Observable<any> {
    return this.dataService.delete(ApiConstant.ApiUrlTypeAttribute + '/', id.toString())
    .pipe(
        map(res => {
          return res;
        }
    ))
  }
 
  deletes(data: Array<number>): Observable<any> {
    return this.dataService.post(ApiConstant.ApiUrlTypeAttribute, data)
    .pipe(
        map(res => {
          return res;
        }
    ))
  }
  
  getItemByCode(code: string): Observable<any> {
    return this.dataService.get(ApiConstant.ApiUrlTypeAttribute.concat('/GetItemByCode/' + code))
    .pipe(
        map(res => {
          return res;
        }
      ))
  }
}
