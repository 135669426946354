import { DbAttactmentFolder } from './../viewModels/attactment/db-attactment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiConstant } from 'app/shared/common/api.constants';
import { DataService } from 'app/shared/services/data.service';
import { Paging } from 'app/viewModels/base-query';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class AttactmentFolderService {

  public isEdit = false;
  public subject = new Subject<any>();
  private itemProductSource = new BehaviorSubject(this.isEdit);

  constructor(
    private dataService: DataService,
  ) { }

  getByPage(pageOption: Paging) {
    return this.dataService.get(ApiConstant.GetAttactmentFolderByPage
      .concat(`?page=`, '' + pageOption.page)
      .concat(`&page_size=`, '' + pageOption.page_size)
      .concat(`&query=`, pageOption.query)
      .concat(`&select=`, pageOption.select)
      .concat('&order_by=', pageOption.order_by)
      .concat(`&contractId=0`))
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getByPageAndTarget(pageOption: Paging, targetId: any, targetType: number, txtSearch: string, contractId: number) {
    return this.dataService.get(ApiConstant.GetAttactmentFolderByPage
      .concat(`?page=`, '' + pageOption.page)
      .concat(`&page_size=`, '' + pageOption.page_size)
      .concat(`&query=`, txtSearch != undefined && txtSearch != "" ? `TargetType=${targetType} AND (Name.Contains("${txtSearch}") OR Note.Contains("${txtSearch}")) AND TargetId.Equals(\"${targetId}\")` : `TargetType=${targetType} AND TargetId.Equals(\"${targetId}\")`)
      .concat(`&select=`, pageOption.select)
      .concat('&order_by=', pageOption.order_by)
      .concat('&contractId=', '' + contractId || '0'))
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getUrlFileSap(idFolder: number, idFile: number) {
    return this.dataService.post(ApiConstant.GetUrlFileSap, {
      attactmentFolderId: idFolder,
      attactmentItemId: idFile
    })
      .pipe(
        map(res => {
          return res;
        }
        ))
  }

  update(id: number, itemProduct: DbAttactmentFolder) {
    return this.dataService.put(ApiConstant.ApiUrlAttactmentFolder.concat('/' + id), itemProduct)
      .pipe(
        map(res => {
          return res;
        }
        ))
  }

  create(itemProduct: DbAttactmentFolder) {
    return this.dataService.post(ApiConstant.ApiUrlAttactmentFolder, itemProduct)
      .pipe(
        map(res => {
          return res;
        }
        ))
  }

  getById(id: number): Observable<any> {
    return this.dataService.get(ApiConstant.ApiUrlAttactmentFolder.concat('/' + id))
      .pipe(
        map(res => {
          return res;
        }
        ))
  }

  delete(id: number): Observable<any> {
    return this.dataService.delete(ApiConstant.ApiUrlAttactmentFolder + '/', id.toString())
      .pipe(
        map(res => {
          return res;
        }
        ))
  }

  deletes(data: Array<number>): Observable<any> {
    return this.dataService.post(ApiConstant.ApiUrlAttactmentFolder, data)
      .pipe(
        map(res => {
          return res;
        }
        ))
  }

  createList(input: any) {
    return this.dataService.post(ApiConstant.ApiUrlAttactmentFolder + "/PostListAttactmentFolder", input)
      .pipe(
        map(res => {
          return res;
        }
        ))
  }

  getArChivalRecord(proId: number, cls, input: any, contractChannel: number, txtSearch: string): Observable<any> {
    return this.dataService.post(ApiConstant.ApiUrlAttactmentFolder.concat('/getArChivalRecord/' + proId+ '/' + cls + '/' + contractChannel + (txtSearch ? '?txtSearch=' + txtSearch : '')), input)
      .pipe(
        map(res => {
          return res;
        }
        ))
    // return this.dataService.get('https://localhost:44370/api/cms/AttactmentFolder/getArChivalRecord/' + proId + '/' + cls)
    //   .pipe(
    //     map(res => {
    //       return res;
    //     }
    //     ))
  }
}
