import { StorageOption } from 'app/shared/common/app.constants';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ReqLogin } from 'app/viewModels/auth/req-login';
import { BehaviorSubject, Observable, Subject, Subscriber, Subscription, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ApiConstant } from '../shared/common/api.constants';
import { AppStatusCode, TokenEnum, KeyDataUser } from '../shared/common/app.constants';
import { CookieProvider } from 'app/shared/providers/cookie.provider';
import { StorageService } from 'app/shared/services/storage.service';
import { DataService } from 'app/shared/services/data.service';
import { SessionStorageProvider } from 'app/shared/providers/session-store.provider';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { MessagingService } from 'app/shared/services/messaging.service';

@Injectable()
export class AuthService {
  private urlState = new BehaviorSubject<any>({});
  public redirectUrl = this.urlState.asObservable();
  

  constructor(private router: Router,
    private dataService: DataService,
    private storeService: StorageService,
    private session_storeService: SessionStorageProvider,
    private cookieService: CookieProvider,
    private messagingService: MessagingService,
    private angularFireMessaging: AngularFireMessaging,
    ) {
      this.storeService.initialize(StorageOption.LocalStorage);
      //this.session_storeService.initialize(StorageOption.Cookie);

      this.angularFireMessaging.messaging.subscribe(
        (_messaging) => {
          _messaging.onMessage = _messaging.onMessage.bind(_messaging);
          _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
        }
      )
  }

  setUrlRedirect (url: string) {
    this.urlState.next(url);
  }

  getUrlRedirect () : string {
    let resUrl;

    this.redirectUrl.subscribe((res) => { resUrl = res });

    return resUrl;
  }

  public getToken(token: TokenEnum) {
    return this.cookieService.get(token);
  }

  public signIn(request: ReqLogin, returnUrl: string): Observable<any> {
    return this.dataService.post(ApiConstant.LoginUrl, request).pipe(
      map((res: any) => {
        if (res.meta.error_code === AppStatusCode.StatusCode200) {
          this.setStoreToken(res['data']['access_token']);
          this.storeUserInfor(res['data']);

          this.angularFireMessaging.requestToken.subscribe(
            (token) => {
              console.log(token);
              this.messagingService.updateToken(token);

              const dataPayload = {
                typeDevice: 1,
                deviceId: "",
                tokenFCM: token
              }

              this.dataService.post(ApiConstant.PushToken, dataPayload).subscribe(
                (res) => {
                  if (res["meta"]["error_code"] == 200) {
                    localStorage.setItem('token_notification', token);
                  }
                  else {
                  }
                },
                (err) => {
                }
              );
            },
            (err) => {
              console.error('Unable to get permission to notify.', err);
            }
          );

          // const urlReturn = this.getUrlRedirect();
          // console.log(urlReturn);
          //this.redirectUrl.subscribe(value => urlReturn = value);

          if (returnUrl) {
            this.router.navigateByUrl(returnUrl);
          } else {
            this.router.navigate(['/']);
          }
        }

        return res;
      }),
      catchError((error: HttpErrorResponse) => {
        console.log(error);

        return throwError(`Error Code: ${error.status}\nMessage: ${error.message}`);
      }),
    )
  }

  // public refreshToken(): Observable<any> {
    // const token: Token = {
    //   accessToken: this.getToken(TokenEnum.ACCESS_TOKEN),
    //   refreshToken: this.getToken(TokenEnum.REFRESH_TOKEN)
    // };
    // return this.http.post<Token>(refreshTokenApiUrl, token).pipe(
    //   tap((data: Token) => {
    //     this.storeToken(data);
    //     return data;
    //   }),
    //   catchError(err => {
    //     return throwError(err);
    //   })
    // );
  // }

  public setStoreToken(accessToken: string, refreshToken?: string): void {
    this.cookieService.set(TokenEnum.ACCESS_TOKEN, accessToken);
    //this.session_storeService.set(TokenEnum.ACCESS_TOKEN, accessToken);
    this.storeService.set(TokenEnum.ACCESS_TOKEN, accessToken);

    if (refreshToken) {
      this.cookieService.set(TokenEnum.REFRESH_TOKEN, refreshToken);
    }
  }

  // public decodeJwtToken(token): any {
  //   return jwt_decode(token);
  // }

  public storeUserInfor(data): void {
    localStorage.removeItem("ProjectIdDetail");
    this.storeService.set(KeyDataUser.FULLNAME, data.fullName);
    this.storeService.set('userName', data.userName);
    this.storeService.set(KeyDataUser.AVATAR, data.avata);
    this.storeService.set(KeyDataUser.USERID, data.userId);
    this.storeService.set('companyId', data.companyId);
    this.storeService.set('languageId', data.languageId);
    this.storeService.set('address', data.address);
    this.storeService.set('websiteId', data.websiteId);
    this.storeService.set('phone', data.phone);
    this.storeService.set('email', data.email);
    this.storeService.set('status', data.status);
    this.storeService.set('access_key', data.access_key);
    this.storeService.set('menu', JSON.stringify(data['listMenus']));
    this.storeService.set(KeyDataUser.DEPARTMENT_ID, data.departmentId);
    this.storeService.set(KeyDataUser.DEPARTMENT_TYPE, data.departmentType);
    this.storeService.set(KeyDataUser.ROLE_MAX, data.roleMax);
  }

  public logout(): void {
    const userName = this.storeService.get('userName');
    const userId = this.storeService.get('userId');

    if (userId) {
      const dataPayload = {
        typeDevice: 1,
        deviceId: "WEB",
        userId: parseInt(userId),
        userName: userName
      }
  
      this.dataService.put(ApiConstant.RemoveTokenByUserId, dataPayload).subscribe(
        (res) => {
          this.cookieService.delete(TokenEnum.ACCESS_TOKEN);
          this.storeService.clear();
        },
        () => {
          this.cookieService.delete(TokenEnum.ACCESS_TOKEN);
          this.storeService.clear();
        },
        () => {
          this.cookieService.delete(TokenEnum.ACCESS_TOKEN);
          this.storeService.clear();
        }
      );
    }
    else {
      this.cookieService.delete(TokenEnum.ACCESS_TOKEN);
      this.storeService.clear();
    }
  }

  public clearStore(): void {

    this.cookieService.delete(TokenEnum.ACCESS_TOKEN);
    this.storeService.clear();
  }
}
