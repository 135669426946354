// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyDb6UgJRAV3lXVfPOdl-xzzbaHwR1Bk-p8",
    authDomain: "qlcd-dev.firebaseapp.com",
    databaseURL: "https://qlcd-dev-default-rtdb.firebaseio.com",
    projectId: "qlcd-dev",
    storageBucket: "qlcd-dev.appspot.com",
    messagingSenderId: "274033514343",
    appId: "1:274033514343:web:260b307135e9d4fc0481c2",
    measurementId: "G-WHPYBMGD6Q"
  },
  apiUrl: 'https://apigw-qldamerge.cnttvietnam.com.vn',
  apiUrlIdentity: 'https://apigw-qldamerge.cnttvietnam.com.vn'
};
