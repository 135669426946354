import { Component, OnInit, Input, Output, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgbDatepickerConfig, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { DbTaskPlan, DbTaskPlanConfig, DbUserInfo } from 'app/viewModels/task-plan/db-taskplan';
import { ApiConstant } from 'app/shared/common/api.constants';
import { TaskPlanService } from "app/services/task-plan.service";
import { CommonService } from "app/services/common.service";
import { Pattern, KeyDataUser, domainFileTemplate, domainApi, TokenEnum } from "app/shared/common/app.constants";
import { Paging, QueryFilter } from "app/viewModels/base-query";
import { UserService } from "app/services/user.service";
import { PhongbanService } from "app/services/phongban.service";
import { HttpClient, HttpEventType, HttpRequest, HttpHeaders } from '@angular/common/http';
import { AttactmentFolderService } from "app/services/attactment-folder.service";

@Component({
    selector: 'app-upload-attactment-folder',
    templateUrl: './upload-attactment-folder.component.html',
    styleUrls: [],
    providers: []
})
export class UploadAttactmentFolderComponent implements OnInit {
    @ViewChild('f') public form: NgForm;
    @Input() targetId: number;
    @Input() targetType: number;
    @Input() title: string;

    Action: any;
    submitted = false;

    attactmentFolders = [];
    domainFileTemplate = domainFileTemplate;
    userId = Number(localStorage.getItem(KeyDataUser.USERID));

    constructor(
        public modal: NgbActiveModal,
        private toast: ToastrService,
        private http: HttpClient,
        private userService: UserService,
        private attactmentFolderService: AttactmentFolderService,
        private taskPlanService: TaskPlanService) { }

    ngOnInit(): void {
    }

   

    save() {
        this.submitted = true;
        let input = this.attactmentFolders.map((data) => {
            data.TargetId = this.targetId;
            data.TargetType = this.targetType;

            return data;
        });

        this.attactmentFolderService.createList(input).subscribe((res) => {
            this.submitted = false;
            if (res["meta"]["error_code"] == 200) {
                this.toast.success(res["meta"]["error_message"]);
                this.modal.close(true);
            }
            else this.toast.error(res["meta"]["error_message"]);
        });
    }

    upload(files) {
        if (files.length === 0) {
            return;
        }

        const formData = new FormData();

        for (const file of files) {
            formData.append(file.name, file);
        }

        const uploadReq = new HttpRequest('POST', domainApi + 'cms/AttactmentFolder/ImportDataExcel', formData, {
            headers: new HttpHeaders({
                'Authorization': 'bearer ' + localStorage.getItem(TokenEnum.ACCESS_TOKEN)
            }),
            reportProgress: true,
        });

        this.http.request(uploadReq).subscribe(event => {
            if (event.type === HttpEventType.UploadProgress) {
            } else if (event.type === HttpEventType.Response) {
                if(event.body['meta'].error_code == 200) {
                    this.attactmentFolders = event.body['data'];
                }
                else {
                    this.toast.error(event.body['meta'].error_message);
                }
            }
        });
    }
}
