<div class="chat-application overflow-hidden">
    <div class="chat-name p-2">
      <div class="media p-1 align-items-center">
        <span class="chat-app-sidebar-toggle ft-menu font-medium-4 mr-3 d-block d-md-none"></span>
        <div class="media-body">
          <span>{{ title }}</span>
        </div>
        <div class="position-relative float-right" (click)="[modal.close()]" style="cursor: pointer;">
          <div display="dynamic" class="d-inline-block">
            <i class="ft-x-circle" id="chatOptions"></i>
          </div>
        </div>
      </div>
    </div>
    <section class="chat-app-window position-relative" [perfectScrollbar] #scrollMe [scrollTop]="scrollMe.scrollHeight">
      <div class="chats">
        <div class="chat" [ngClass]="{'chat-left': chat.UserId != curUserId}" *ngFor="let chat of chats">
            <span class="chat-fullname">{{ chat.FullName }}</span>
          <div class="chat-avatar">
            <a class="avatar avatar-md" data-toggle="tooltip" title=""
              data-original-title="">
              <img [src]="chat.Avatar ? domainImage + chat.Avatar : 'assets/img/avatar.png'" alt="">
            </a>
          </div>
          <div class="chat-body">
            <div class="chat-content" *ngFor="let message of chat.messages">
              <p>{{ message.Contents }}&nbsp;&nbsp;&nbsp;<span style="font-size: 12px; color: white; font-style: italic;">{{ message.CreatedAt | date: "dd/MM/yyyy HH:mm:ss" }}</span></p>

            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="chat-app-form px-3 py-2">
      <div class="d-flex align-items-center" style="margin-bottom: 10px; display: block !important;" *ngIf="targetType == 1">
        <ng-select [items]="userMappings" class="select ngSelectinput auto-grow" bindLabel="FullName" bindValue="Id"
          [multiple]="true" [(ngModel)]="receiveNotiUser" [closeOnSelect]="false"
          dropdownPosition="top"
          [ngModelOptions]="{standalone: true}" placeholder="Ds người nhận thông báo">
        </ng-select>
      </div>
      <div class="d-flex align-items-center" *ngIf="submitted">
        <input type="text" name="iconLeft4" class="form-control chat-message-send mr-2" id="iconLeft4"
          placeholder="Gửi tin..."
          [(ngModel)]="newMessage" #addMsg>
        <button type="button" class="btn btn-primary d-lg-flex align-items-center">
          <i class="fa fa-spin fa-circle-o-notch"></i>
          <span class="d-none d-lg-block ml-1">&nbsp;</span>
        </button>
      </div>
      <div class="d-flex align-items-center" *ngIf="!submitted">
        <input type="text" name="iconLeft4" class="form-control chat-message-send mr-2" id="iconLeft4"
          placeholder="Gửi tin..." (keydown.enter)="onAddMessage();$event.preventDefault()"
          [(ngModel)]="newMessage" #addMsg>
        <button type="button" class="btn btn-primary d-lg-flex align-items-center" (click)="onAddMessage()">
          <i class="ft-send"></i>
          <span class="d-none d-lg-block ml-1">&nbsp;</span>
        </button>
      </div>
    </section>
</div>
