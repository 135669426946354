export class Paging {
  page: number = 1;
  page_size: number = 10;
  query: string = "1=1";
  order_by: string = "";
  item_count: number = 0;
  select: string = "";
  dateStart: any;
  dateEnd: any;
  status?: number;
  keyword: string;
}

export class QueryFilter {
  txtSearch: string;
  type: number;
  title: number;
  typeAttributeId: number;
  typeFormId: number;
  userId: number;
  status: number;
  dateStart: any;
  dateEnd: any;
  targetId_1: number;
  targetId_2: number;
  targetId_3: number;
  targetId_4: number;
  targetId_5: number;
  targetId_6: number;
  targetId_7: number;
  targetId_8: number;
  targetId_9: number;
  projectId: number;
  contractId: number;
  year: number;
}

export class CheckRole {
  View: boolean = false;
  Create: boolean = false;
  Update: boolean = false;
  Delete: boolean = false;
  Import: boolean = false;
  Export: boolean = false;
  Print: boolean = false;
  Other: boolean = false;
  Menu: boolean = false;
}

