
import { BaseModel } from '../base-model';

export class DbConversation extends BaseModel {
    Id: number;
    Contents: string;
    TargetId: number;
    TypeConversation: number;
    TargetTaskPlanId: string;
    MonitoringHistoryId: number;
    FullName: string;
    Avatar: string;
    receivers: any;
}