
import { BaseModel } from '../base-model';

export class DbOneDriveFolder extends BaseModel {
    Id: number;
    ProjectId: number;
    ContractId: number;
    STT: string;
    Name: string;
    UnitCalculate: string;
    VideoPlan: number;
    ImagePlan: number;
    FrequencyPerform: string;
    NumberLocation: number;
    NumberFloor: number;
    VideoStart: number;
    VideoEnd: number;
    ImageStart: number;
    ImageEnd: number;
    Note: string;
    FolderParentId: number;
    Location: number;
    Level: number;
    IsOneDrive: boolean;
 }