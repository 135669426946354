import { Pipe, PipeTransform } from '@angular/core';
import { TypeCash } from '../common/app.constants';
import { BidpackageJobStatus } from '../common/app.constants';
import { ProgressReportType } from '../common/app.constants';
import { TypeAcceptCash } from '../common/app.constants';
import { TypeCashAdvance } from '../common/app.constants';
import { TypePaymentMethod } from '../common/app.constants';
import { TypePaymentStatus, TypeContractChild } from '../common/app.constants';

@Pipe({
    name: 'typeCashPipe'
})
export class TypeCashPipe implements PipeTransform {
    transform(Id?: number): any {
        if (!Id) return "";
        else {
            let type = TypeCash.find(x => x.Id == Id);
            return type ? type.Name : "";
        }
    }
}

@Pipe({
    name: 'bidpackageJobStatusPipe'
})
export class BidpackageJobStatusPipe implements PipeTransform {
    transform(Id?: number): any {
        if (!Id) return "";
        else {
            let type = BidpackageJobStatus.find(x => x.Id == Id);
            return type ? type.Name : "";
        }
    }
}

@Pipe({
    name: 'progressReportTypePipe'
})
export class ProgressReportTypePipe implements PipeTransform {
    transform(Id?: number): any {
        if (!Id) return "";
        else {
            let type = ProgressReportType.find(x => x.Id == Id);
            return type ? type.Name : "";
        }
    }
}


@Pipe({
    name: 'typeAcceptCashPipe'
})
export class TypeAcceptCashPipe implements PipeTransform {
    transform(Id?: number): any {
        if (!Id) return "";
        else {
            let type = TypeAcceptCash.find(x => x.Id == Id);
            return type ? type.Name : "";
        }
    }
}

@Pipe({
    name: 'typeCashAdvancePipe'
})
export class TypeCashAdvancePipe implements PipeTransform {
    transform(Id?: number): any {
        if (!Id) return "";
        else {
            let type = TypeCashAdvance.find(x => x.Id == Id);
            return type ? type.Name : "";
        }
    }
}

@Pipe({
    name: 'typePaymentMethodPipe'
})
export class TypePaymentMethodPipe implements PipeTransform {
    transform(Id?: number): any {
        if (!Id) return "";
        else {
            let type = TypePaymentMethod.find(x => x.Id == Id);
            return type ? type.Name : "";
        }
    }
}

@Pipe({
    name: 'typePaymentStatusPipe'
})
export class TypePaymentStatusPipe implements PipeTransform {
    transform(Id?: number): any {
        if (!Id) return "";
        else {
            let type = TypePaymentStatus.find(x => x.Id == Id);
            return type ? type.Name : "";
        }
    }
}

@Pipe({
    name: 'typeContractChildPipe'
})
export class TypeContractChildPipe implements PipeTransform {
    transform(Id?: number): any {
        if (!Id) return "";
        else {
            let type = TypeContractChild.find(x => x.Id == Id);
            return type ? type.Name : "";
        }
    }
}
