import { DbTaskPlanHistory, DbTaskPlan } from 'app/viewModels/task-plan/db-taskplan';
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiConstant } from 'app/shared/common/api.constants';
import { DataService } from 'app/shared/services/data.service';
import { Paging } from 'app/viewModels/base-query';
import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpRequest, HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class TaskPlanService {

    public isEdit = false;
    public subject = new Subject<any>();

    constructor(
        private dataService: DataService,
        private http: HttpClient
    ) { }

    getByPage(pageOption: Paging) {
        return this.dataService.get(ApiConstant.GetTaskPlanByPage
            .concat(`?page=`, '' + pageOption.page)
            .concat(`&page_size=`, '' + pageOption.page_size)
            .concat(`&query=`, pageOption.query)
            .concat(`&select=`, pageOption.select)
            .concat('&order_by=', pageOption.order_by))
            .pipe(map((res: any) => {
                return res;
            }));
    }

    getTaskPlanHistory(idp: number, type: number, idc: number) {
        return this.dataService.get(ApiConstant.ApiUrlTaskPlan.concat('/GetTaskPlanHistory/' + idp + '/' + type + '/' + idc))
            .pipe(
                map(res => {
                    return res;
                }))
    }

    getTaskPlan(id: string) {
        return this.dataService.get(ApiConstant.ApiUrlTaskPlan.concat('/GetTaskPlan/' + id))
            .pipe(
                map(res => {
                    return res;
                }))
    }

    deleteTaskPlanHistory(id: string) {
        return this.dataService.delete(ApiConstant.ApiUrlTaskPlan + '/DeleteTaskPlanHistory/', id.toString())
            .pipe(
                map(res => {
                    return res;
                }
                ))
    }

    updateTaskPlan(id: string, itemProduct: DbTaskPlan) {
        return this.dataService.put(ApiConstant.ApiUrlTaskPlan.concat('/UpdateTaskPlan/' + id), itemProduct)
            .pipe(
                map(res => {
                    return res;
                }
                ))
    }

    create(itemProduct: DbTaskPlanHistory) {
        return this.dataService.post(ApiConstant.ApiUrlTaskPlan.concat('/CreateTaskPlan'), itemProduct)
            .pipe(
                map(res => {
                    return res;
                }
                ))
    }

    createTaskPlanConfig(data: Array<DbTaskPlanHistory>, idp: number) {
        return this.dataService.post(ApiConstant.ApiUrlTaskPlan.concat('/CreateTaskPlanConfig/' + idp), data)
            .pipe(
                map(res => {
                    return res;
                }
                ))
    }

    getTaskPlanConfig(taskPlanId: string) {
        return this.dataService.get(ApiConstant.ApiUrlTaskPlan.concat('/GetTaskPlanConfig/' + taskPlanId))
            .pipe(
                map(res => {
                    return res;
                }))
    }

    //   getById(id: number): Observable<any> {
    //     return this.dataService.get(ApiConstant.ApiUrlRole.concat('/' + id))
    //     .pipe(
    //         map(res => {
    //           return res;
    //         }
    //       ))
    //   }

    //   delete(id: number): Observable<any> {
    //     return this.dataService.delete(ApiConstant.ApiUrlRole + '/', id.toString())
    //     .pipe(
    //         map(res => {
    //           return res;
    //         }
    //     ))
    //   }

    //   deletes(data: Array<number>): Observable<any> {
    //     return this.dataService.post(ApiConstant.ApiUrlRole, data)
    //     .pipe(
    //         map(res => {
    //           return res;
    //         }
    //     ))
    //   }

    uploadFileMpp(formData: any) {
        const reqUpload = new HttpRequest('POST', ApiConstant.ApiUrlTaskPlan.concat('/UploadFileMpp/1'), formData, { reportProgress: true });

        return this.http.request(reqUpload);
    }

    copyTaskPlan(taskPlanHistoryId) {
        return this.dataService.post(ApiConstant.ApiUrlTaskPlan.concat('/CopyTaskPlan/' + taskPlanHistoryId), undefined)
            .pipe(
                map(res => {
                    return res;
                }
                ))
    }
}
