import { Injectable } from '@angular/core';
import { domainApi, domainFileTemplate } from '../common/app.constants';
import { getFileNameFromResponseContentDisposition, saveFile } from '../helpers/file-download-helper';
import { DataService } from './data.service';
import { InterceptorService } from 'ng2-interceptors';
import { RequestOptions, ResponseContentType, Headers } from '@angular/http';

@Injectable()
export class DownloadService {

  constructor(
    private dataService: DataService,
    private httpDownload: InterceptorService
  ) { }

  // download file từ bảng Attachment
  downloadFile(id: any) {
    const url = domainApi + '/download/downloadAttachment/' + id;

    // Process the file downloaded
    this.dataService.get(url).subscribe(res => {
      const fileName = getFileNameFromResponseContentDisposition(res);
      console.log(fileName);
      saveFile(res, fileName);
    });
  }

  // download file tử bảng CustomerFiles
  downloadCustomerFiles(id: any) {
    const url = domainApi + '/download/downloadCustomerFiles/' + id;


    // Process the file downloaded
    this.dataService.get(url).subscribe(res => {
      const fileName = getFileNameFromResponseContentDisposition(res);
      saveFile(res, fileName);
    });
  }

  // download file đính kèm
  downloadFileOther(str: string) {
    const url = domainApi + '/download/downloadFileOther';

    const obj = { Url: str };

    this.dataService.post(url, obj).subscribe(res => {
      const fileName = getFileNameFromResponseContentDisposition(res);
      saveFile(res, fileName);
    });
  }

  downloadAttachmentItems(id: any) {
    const url = domainApi + '/download/downloadAttachmentItem/' + id;

    // Process the file downloaded
    this.dataService.get(url).subscribe(res => {
      const fileName = getFileNameFromResponseContentDisposition(res);
      saveFile(res, fileName);
    });
  }

  downloadFileViaUrl(url: string, fileName: string, domainFile: string) {
    //  url = domainFile + url;

    // this.dataService.get(url).subscribe(res => {
    //   saveFile(res, fileName);
    // });

    const options = new RequestOptions({
      responseType: ResponseContentType.Blob,
    });

    this.httpDownload.get(domainFile + url, options).subscribe(res => {
      saveFile(res.blob(), fileName);
    });
  }
}



